import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import jwt_decode, { JwtPayload } from "jwt-decode";

const onRequest = async (config: any): Promise<any> => {
  let accessToken = localStorage.getItem("access_token");
  if (!accessToken) return config;
  let decodedToken: JwtPayload;

  try {
    accessToken = JSON.parse(accessToken);
    decodedToken = jwt_decode<JwtPayload>(accessToken);
  } catch {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    window.location.href = "/login";
    return;
  }
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    //  This means that the access token has expired. Should redirect user to login and make sure user is properly logged out
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    const request_to_retry = config;
    request_to_retry.headers!["Authorization"] = undefined;
    window.location.href = "/login";
    return request_to_retry;
  }

  if (config.headers) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    config.headers["Platform"] =
      localStorage.getItem("platform") || "Playstation";
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

export const setupInterceptorsTo = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse);
  return axiosInstance;
};
