import axios from "../../apiFc24";

export const playerSearchWithMetaRatings = (
  name: string,
  currentPage: number,
  platform: string,
  archetypeId?: string,
  evolutions?: boolean
) =>
  axios.post(
    "/players/search",
    {
      searchText: name,
      page: currentPage,
      platform,
      archetypeId,
      evolutions,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
