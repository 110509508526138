export const ensureSnackLoaderIsLoaded = (site_id) => {
  return new Promise((resolve, reject) => {
    const doc = document;
    const scriptId = "snack_loader_" + site_id;
    const existingScript = doc.getElementById(scriptId);
    const isExistingScriptLoaded =
      (existingScript && existingScript.getAttribute("snack-loaded")) ===
      "true";

    const resolveScript = () => {
      let existingScript = doc.getElementById(scriptId);
      existingScript.setAttribute("snack-loaded", "true");
      resolve();
    };

    if (isExistingScriptLoaded) {
      // Script has been loaded before, promise can be resolved.
      resolveScript();
      return;
    }

    if (existingScript && !isExistingScriptLoaded) {
      // Script has been added to DOM before, but it's not fully loaded yet.
      existingScript.addEventListener("load", function () {
        resolveScript();
      });
      return;
    }

    // Script is not added to the DOM.
    var scriptElm = doc.createElement("script");
    scriptElm.id = scriptId;
    //https://header-bidding.snack-dev.co.uk/assets/js/snack-loader
    //https://cdn-header-bidding.snack-media.com/assets/js/snack-loader/
    scriptElm.src =
      "https://cdn-header-bidding.snack-media.com/assets/js/snack-loader/" +
      site_id +
      "?v2";
    scriptElm.async = true;
    scriptElm.setAttribute("snack-loaded", "false");
    scriptElm.addEventListener("load", function () {
      resolveScript();
    });
    doc.body.appendChild(scriptElm);
  });
};
