import { Manager } from "../redux/squadBuilder/squadBuilderSlice";

export const HERO_CLUB_ID = 114605;
export const ICON_CLUB_ID = 112658;
export const ICON_LEAGUE_ID = 2118;

const PREMIUM_SHAPESHIFTERS_VERSION_ID = 166;
const PREMIUM_LEVEL_UP_VERSION_ID = 165;
const PREMIUM_FUTTIES = 164;
const RADIOACTIVE_ID = 37;
const RADIOACTIVE_EVO_ID = 23;
const FIRE_ID = 116;
const ICE_ID = 108;
const EVO_FUTURE_STARS = 66;
const TOTS_PLUS = 104;
const TOTS_CHAMPS_PLUS = 93;
const UEFA_PATH_TO_GLORY = 40;
const COPA_AMERICA_PATH_TO_GLORY = 41;
const UEFA_MAKE_YOUR_MARK = 73;
const COPA_AMERICA_MAKE_YOUR_MARK = 75;
const UEFA_MAKE_YOUR_MARK_PLUS = 74;
const COPA_MAKE_YOUR_MARK_PLUS = 76;
const FUTTIES_PREMIUM = 16;
const FUTTIES = 9;

export type ChemPlayer = {
  positionIndex: number;
  countryId: number;
  clubId: number;
  leagueId: number;
  possiblePositions: string[];
  version: string;
  versionId: number;
};

type Props = {
  players: ChemPlayer[];
  formation: string[];
  manager?: Manager;
};

type Chemistry = 0 | 1 | 2 | 3;

export type PlayersChemistry = {
  positionIndex: number;
  chemistry: Chemistry;
};
export type ChemistryObject = {
  playersChemistry: PlayersChemistry;
  countryCounter: { [key: string]: number };
  leagueCounter: { [key: string]: number };
  clubCounter: { [key: string]: number };
  playersOutOfPosition: ChemPlayer[];
};

export const countryCountToChem = (countryCount: number): Chemistry => {
  if (countryCount < 2) {
    return 0;
  } else if (countryCount < 5) {
    return 1;
  } else if (countryCount < 8) {
    return 2;
  } else {
    return 3;
  }
};

export const leagueCountToChem = (leagueCount: number): Chemistry => {
  if (leagueCount < 3) {
    return 0;
  } else if (leagueCount < 5) {
    return 1;
  } else if (leagueCount < 8) {
    return 2;
  } else {
    return 3;
  }
};

export const clubCountToChem = (clubCount: number): Chemistry => {
  if (clubCount < 2) {
    return 0;
  } else if (clubCount < 4) {
    return 1;
  } else if (clubCount < 7) {
    return 2;
  } else {
    return 3;
  }
};

const CLUB_LINKS = {
  1: 116009,
  2: 116015,
  5: 116010,
  7: 116016,
  9: 116343,
  10: 116017,
  11: 116012,
  18: 116011,
  19: 116014,
  21: 115995,
  25: 116002,
  31: 116003,
  32: 115996,
  38: 116004,
  45: 116280,
  59: 116038,
  62: 116042,
  65: 131219,
  66: 116033,
  70: 116037,
  73: 116034,
  95: 116020,
  171: 131386,
  175: 115998,
  234: 131358,
  240: 116327,
  241: 116325,
  243: 116326,
  245: 131359,
  266: 131360,
  379: 116040,
  448: 116328,
  449: 116330,
  457: 116336,
  461: 116339,
  467: 116331,
  481: 116337,
  483: 116340,
  698: 116306,
  894: 131361,
  1738: 116416,
  1808: 116013,
  1819: 116044,
  1824: 115997,
  1825: 131106,
  1853: 116333,
  1919: 131283,
  10029: 115999,
  110832: 131391,
  111140: 116302,
  111817: 116035,
  112606: 116307,
  116021: 112172,
};

export const calculateChemistry = ({
  players,
  formation,
  manager,
}: Props): ChemistryObject => {
  let iconCounter = 0;
  let countryCounter = {};
  let leagueCounter = {};
  let clubCounter = {};
  let playersOutOfPosition = [];

  players.forEach((player) => {
    if (!player.possiblePositions.includes(formation[player.positionIndex])) {
      playersOutOfPosition.push(player);
      return;
    }
    if (player.clubId === ICON_CLUB_ID) {
      iconCounter += 1;
    }
    const countryBonus =
      player.clubId === ICON_CLUB_ID ||
      player.versionId === RADIOACTIVE_ID ||
      player.versionId === RADIOACTIVE_EVO_ID ||
      player.versionId === FUTTIES
        ? 2
        : player.versionId === FIRE_ID ||
          player.versionId === UEFA_PATH_TO_GLORY ||
          player.versionId === COPA_AMERICA_PATH_TO_GLORY ||
          player.versionId === UEFA_MAKE_YOUR_MARK ||
          player.versionId === COPA_AMERICA_MAKE_YOUR_MARK ||
          player.versionId === UEFA_MAKE_YOUR_MARK_PLUS ||
          player.versionId === COPA_MAKE_YOUR_MARK_PLUS
        ? 5
        : 1;
    if (player.countryId in countryCounter) {
      countryCounter[player.countryId] += countryBonus;
    } else {
      countryCounter[player.countryId] = countryBonus;
    }
    const leagueBonus =
      player.clubId === HERO_CLUB_ID ||
      player.versionId === PREMIUM_SHAPESHIFTERS_VERSION_ID ||
      player.versionId === PREMIUM_LEVEL_UP_VERSION_ID ||
      player.versionId === PREMIUM_FUTTIES ||
      player.versionId === RADIOACTIVE_ID ||
      player.versionId === RADIOACTIVE_EVO_ID ||
      player.versionId === FUTTIES_PREMIUM ||
      player.versionId === FUTTIES
        ? 2
        : player.versionId === TOTS_PLUS ||
          player.versionId === TOTS_CHAMPS_PLUS
        ? 5
        : 1;
    if (player.leagueId in leagueCounter) {
      leagueCounter[player.leagueId] += leagueBonus;
    } else {
      leagueCounter[player.leagueId] = leagueBonus;
    }
    let clubBonus: number;
    if (player.clubId === HERO_CLUB_ID || player.clubId === ICON_CLUB_ID) {
      clubBonus = 0;
    } else if (
      player.versionId === RADIOACTIVE_ID ||
      player.versionId === RADIOACTIVE_EVO_ID ||
      player.versionId === FUTTIES
    ) {
      clubBonus = 2;
    } else if (player.versionId === ICE_ID) {
      clubBonus = 5;
    } else {
      clubBonus = 1;
    }

    const clubId = CLUB_LINKS[player.clubId] || player.clubId;
    if (clubId in clubCounter) {
      clubCounter[clubId] += clubBonus;
    } else {
      clubCounter[clubId] = clubBonus;
    }
  });

  if (manager) {
    if (manager.country) {
      if (countryCounter[manager.country]) {
        countryCounter[manager.country] += 1;
      } else {
        countryCounter[manager.country] = 1;
      }
    }
    if (manager.league) {
      if (leagueCounter[manager.league]) {
        leagueCounter[manager.league] += 1;
      } else {
        leagueCounter[manager.league] = 1;
      }
    }
  }
  Object.entries(leagueCounter).forEach((element) => {
    leagueCounter[element[0]] += iconCounter;
  });

  const playersChemistry = {};

  players.forEach((player) => {
    if (!player.possiblePositions.includes(formation[player.positionIndex])) {
      playersChemistry[player.positionIndex] = 0;
      return;
    }
    if (
      player.clubId === ICON_CLUB_ID ||
      player.clubId === HERO_CLUB_ID ||
      player.versionId === PREMIUM_SHAPESHIFTERS_VERSION_ID ||
      player.versionId === PREMIUM_LEVEL_UP_VERSION_ID ||
      player.versionId === PREMIUM_FUTTIES ||
      player.versionId === EVO_FUTURE_STARS ||
      player.versionId === COPA_MAKE_YOUR_MARK_PLUS ||
      player.versionId === UEFA_MAKE_YOUR_MARK_PLUS ||
      player.versionId === FUTTIES_PREMIUM
    ) {
      playersChemistry[player.positionIndex] = 3;
      return;
    }

    const clubId = CLUB_LINKS[player.clubId] || player.clubId;
    let playerChemistry: Chemistry = 0;
    playerChemistry += countryCountToChem(countryCounter[player.countryId]);
    playerChemistry += leagueCountToChem(leagueCounter[player.leagueId]);
    playerChemistry += clubCountToChem(clubCounter[clubId]);

    playersChemistry[player.positionIndex] =
      playerChemistry <= 3 ? (playerChemistry as Chemistry) : 3;
  });

  return {
    playersChemistry,
    countryCounter,
    leagueCounter,
    clubCounter,
    playersOutOfPosition,
  } as ChemistryObject;
};
