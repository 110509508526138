import clsx from "clsx";
import { useState } from "react";

type Props = {
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: React.HTMLInputTypeAttribute;
  autoComplete?: string;
  name?: string;
  value?: string;
  autofocus?: boolean;
  icon?: JSX.Element;
  fancyBorder?: boolean;
  disabled?: boolean;
};

const InputField = ({
  placeholder,
  onChange,
  type,
  autoComplete,
  name,
  value,
  autofocus,
}: Props) => (
  <div className="w-full rounded-xl flex flex-row border-[2px] border-[#0396FF]">
    <input
      className="outline-none rounded-xl text-base w-full h-[40px] bg-primary-dark pl-[10px] caret-[#0396FF] text-white placeholder:text-sm placeholder-gray pb-[2px]"
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      autoComplete={autoComplete}
      name={name}
      autoFocus={autofocus}
      value={value}
    />
  </div>
);

const SecondaryInputField = ({
  placeholder,
  onChange,
  type,
  autoComplete,
  name,
  value,
  icon,
  fancyBorder,
  disabled,
}: Props) => {
  const [isInputFocused, setInputFocused] = useState(false);

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    setInputFocused(false);
  };

  return (
    <div
      style={{ boxShadow: fancyBorder && "0px 0px 20px #F90" }}
      className={clsx(
        "w-full rounded-xl flex flex-row relative",
        fancyBorder
          ? "border-brand-meta-orange border-[2px]"
          : "border-gray-600 border-[1px]"
      )}
    >
      <input
        disabled={disabled}
        className={clsx(
          "outline-none rounded-xl w-full h-[40px] text-white bg-transparent pl-3 caret-primary-dark-blue placeholder:text-sm",
          isInputFocused ? "placeholder-white" : "placeholder-gray-300"
        )}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        autoComplete={autoComplete}
        name={name}
        autoFocus={isInputFocused}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {icon && (
        <div
          className={clsx(
            "absolute right-2 top-[50%] transform -translate-y-1/2",
            !isInputFocused && value === "" && "opacity-50"
          )}
        >
          {icon}
        </div>
      )}
    </div>
  );
};

export { InputField, SecondaryInputField };
