import { useEffect } from "react";
import { useDispatch } from "react-redux";

import useWindowDimensions from "../../../hooks/useWindowSize";
import { addAlert } from "../../../redux/message/messageSlice";
import { AppDispatch } from "../../../redux/store";
import { updateUser } from "../../../redux/user/userSlice";
import { MAX_TABLET_WINDOW } from "../../../settings";
import { Button } from "../../atoms/Buttons/Button";

export const AllowTrackingPopup = () => {
  const dispatch = useDispatch<AppDispatch>();
  const windowDimensions = useWindowDimensions();
  const isSmall = windowDimensions.width < MAX_TABLET_WINDOW;
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    return () => {
      body.style.overflow = "auto";
    };
  });
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-black/60 z-[20] backdrop-blur-sm flex">
      <div className="flex flex-col p-4 py-6 m-auto mx-2 tablet:mx-auto tablet:p-8 rounde-lg bg-gray-875 gap-y-4 tablet:gap-y-5 shadow-modal">
        <img
          className="-translate-y-8 scale-110 tablet:scale-100 tablet:-translate-y-16 translate-x-12 tablet:-translate-x-16 w-[250px] tablet:h-[376px] tablet:w-[670px]"
          src={
            process.env.REACT_APP_ASSETS_BASE_URL +
            "/fc24/misc/pack_notification_introduced.png"
          }
          alt={"Pack notification introduced"}
        />
        <h2 className="tablet:text-2xl  font-semibold text-white max-w-[650px] -mt-6 tablet:-mt-12">
          Your Club, Auto-Updated!
        </h2>
        <h3 className="text-sm tablet:text-xl text-white max-w-[650px]">
          Our Chrome Extension can now track every player you pack, buy, sell,
          discard and more. Gone are the days where you manually have to import.
        </h3>
        <div className="flex flex-row mx-auto text-gray-200 gap-x-8">
          <div
            className="my-auto underline cursor-pointer"
            onClick={() => {
              dispatch(updateUser({ allowTracking: false }));
              dispatch(
                addAlert({
                  title: "Changed your mind?",
                  description:
                    "You can always change your tracking settings under the profile tab",
                  type: "info",
                })
              );
            }}
          >
            No thanks
          </div>
          <Button
            size={isSmall ? "medium" : "large"}
            onClick={() => {
              dispatch(updateUser({ allowTracking: true }));
              dispatch(
                addAlert({
                  title: "Changed your mind?",
                  description:
                    "You can always change your tracking settings under the profile tab",
                  type: "info",
                })
              );
            }}
          >
            Turn updates on
          </Button>
        </div>
      </div>
    </div>
  );
};
