import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";

import { IRootState } from "../store";

type State = {
  isNavbarClosed: boolean;
  isSearching: boolean;
  snackIsReady: boolean;
};

const initialState: State = {
  isNavbarClosed: isMobile,
  isSearching: false,
  snackIsReady: false,
};

const globalStateSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    toggleSearching(state, action: PayloadAction<boolean>) {
      state.isSearching = action.payload;
    },
    toggleNavbarOpen(state, action: PayloadAction<boolean>) {
      state.isNavbarClosed = action.payload;
    },
    setSnackIsReady(state, action: PayloadAction<boolean>) {
      state.snackIsReady = action.payload;
    },
  },
});

export const globalStateSelector = (state: IRootState) =>
  state.globalStateReducer;
export const { toggleNavbarOpen, toggleSearching, setSnackIsReady } =
  globalStateSlice.actions;
export default globalStateSlice.reducer;
