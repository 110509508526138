import clsx from "clsx";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { cross as crossIcon } from "../../../icons";
import { clearMessage } from "../../../redux/message/messageSlice";
import { AppDispatch } from "../../../redux/store";
import { PopupMessage as PopupMessageType } from "./PopupMessage.type";

const PopupMessage = (message: PopupMessageType) => {
  const dispatch = useDispatch<AppDispatch>();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Generate a unique key for each message
  const uniqueKey = `${message.title}-${Date.now()}`;

  useEffect(() => {
    if (!message.fixed) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        dispatch(clearMessage());
      }, 4000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [dispatch, message.fixed, message]); // dependencies

  let positionWrapper =
    "fixed top-16 desktop:right-16 md:w-full md:px-6 z-50 pop-up-animation";
  if (message.fixed) {
    positionWrapper =
      "z-50 fixed min-h-screen w-full top-0 backdrop-brightness-50";
  }

  return (
    <div key={uniqueKey} className={positionWrapper}>
      <div
        className={clsx(
          "bg-gray-800 text-center w-fit mx-auto text-white flex flex-col gap-y-2 p-4 rounded-lg",
          message.fixed &&
            "absolute mx-auto top-1/3 left-1/2 transform -translate-x-1/2"
        )}
      >
        <div className="flex flex-row m-auto text-base gap-x-16">
          <span>{message.title}</span>
          {message.fixed && (
            <button
              className="w-4 h-4 m-auto text-white"
              onClick={() => {
                dispatch(clearMessage());
              }}
            >
              {crossIcon}
            </button>
          )}
        </div>
        {message.content && <div className="text-sm">{message.content}</div>}
      </div>
    </div>
  );
};

export default PopupMessage;
