import { useEffect, useRef } from "react";

export const useOutsideClick = (callback: (event: MouseEvent) => void) => {
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    function handleClick(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    }
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, callback]);

  return ref;
};
