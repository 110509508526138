import { SbcSet } from "../../../redux/sbcs/sbcSetsSlice";
import axios from "../../privateApi";

export const getSbcSet = async (setId: number): Promise<SbcSet> => {
  const { data } = await axios.get(`/sbc-sets/${setId}`);

  const sbcSet: SbcSet = {
    ...data,
    reward: data.rewards[0],
    img: data.imageURL,
    upVotes: 0,
    downVotes: 0,
  };
  return sbcSet;
};
