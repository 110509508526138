import axios from "../../privateApi";

type VoteSBCSet = {
  setId: string;
  voteType: string;
};
export const voteSBCSet = (voteSBCSet: VoteSBCSet) =>
  axios.post("/vote-sbc-set", voteSBCSet);

export const undoVoteSBCSet = (voteSBCSet: VoteSBCSet) => {
  axios.post("/undo-vote-sbc-set", voteSBCSet);
};
