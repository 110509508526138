import axios from "../../privateApi";

export const createCheckoutSession = (priceId, discountId, freeTrial) => {
  const body = {
    priceId,
    discountId,
    freeTrial,
    refId: window.Rewardful?.referral,
  };
  return axios
    .post("/payments/create-checkout-session", body)
    .then((response) => {
      if (response.data.status === 303) {
        window.location.href = response.data.url;
      }
    });
};
