import TagManager from "react-gtm-module";

// Function to send an event to GTM
export const fireGTMEvent = (eventName: string, eventProperties?: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventProperties,
    },
  });
};
