import { PlayersSortByType } from "../../../components/organisms/PlayersToolbar/PlayersToolbar";
import axios from "../../privateApi";

type Props = {
  sort: PlayersSortByType;
  playerRole: string;
};

export const fetchMyEvolutionPlayers = async ({ sort, playerRole }: Props) => {
  let url = "/user-players/evolved";
  if (sort) {
    url += `?sort-${sort}`;
  }
  if (playerRole) {
    url += `&player-role=${playerRole}`;
  }

  const res = await axios.get(url);
  return res.data;
};
