import { SortBy } from "../../../components/organisms/MyClubPlayerTable/SortBy.type";
import { Filter } from "../../../components/organisms/MyClubSidebars/Filters/Filters.type";
import axios from "../../privateApi";

type GetUserPlayers = {
  page: number;
  limit: number;
  filters: Set<Filter>;
  ascending?: boolean;
  sort?: SortBy;
};

const buildUrl = (data: GetUserPlayers) => {
  let url = "user-players/";
  if (data.sort === "price") {
    url += "?sort-price";
  } else if (data.sort === "profit") {
    url += "?sort-profit";
  } else {
    url += "?sort-rating";
  }
  if (data.ascending) {
    url += "=asc";
  } else {
    url += "=desc";
  }
  if (data.filters.size > 0) {
    url += "&filters=";
    data.filters.forEach((filter) => {
      url += filter + ",";
    });
    url = url.substring(0, url.length - 1);
  }
  url += "&page=" + data.page;
  url += "&limit=" + data.limit;
  return url;
};
export const getUserPlayers = async (data: GetUserPlayers) => {
  const url = buildUrl(data);

  const res = await axios.get(url);
  return res.data;
};

export default getUserPlayers;
