export const defaultPositions = [
  { category: "Attackers", label: "LW", id: 0 },
  { category: "Attackers", label: "ST", id: 1 },
  { category: "Attackers", label: "RW", id: 2 },
  { category: "Attackers", label: "CF", id: 3 },
  { category: "Midfielders", label: "LM", id: 4 },
  { category: "Midfielders", label: "CAM", id: 5 },
  { category: "Midfielders", label: "RM", id: 6 },
  { category: "Midfielders", label: "CDM", id: 7 },
  { category: "Midfielders", label: "CM", id: 8 },
  { category: "Defenders", label: "LWB", id: 9 },
  { category: "Defenders", label: "CB", id: 10 },
  { category: "Defenders", label: "RWB", id: 11 },
  { category: "Defenders", label: "LB", id: 12 },
  { category: "Defenders", label: "GK", id: 13 },
  { category: "Defenders", label: "RB", id: 14 },
];
