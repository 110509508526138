import { User } from "../../../redux/user/userSlice";
import axios from "../../privateApi";

type GetUserResponse = {
  user: User;
  players: {
    playerCount: number;
    lastImportedAt: number;
  };
};

export const getUser = async () => {
  const res = await axios.get<GetUserResponse>("users/profile");
  const { playerCount, lastImportedAt } = res.data.players;

  return {
    ...res.data.user,
    playerCount,
    lastImportedAt,
  };
};
