import { useEffect } from "react";

export const usePageVisibility = (
  onHandleVisiblity: (visible: boolean) => void
) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      onHandleVisiblity(true);
    };

    // document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleVisibilityChange);
    // Cleanup function to remove the event listener
    return () => {
      // document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleVisibilityChange);
    };
  }, [onHandleVisiblity]); // Empty array ensures this effect runs only once after the initial render
};
