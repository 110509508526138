import clsx from "clsx";
import { useEffect, useState } from "react";

import {
  closingCross as closingCrossIcon,
  error as errorIcon,
  info as infoIcon,
  success as successIcon,
  warning as warningIcon,
} from "../../../../designSystemIcons";
import { Alert as AlertType } from "../../../../redux/message/messageSlice";

type Props = {
  alert: AlertType;
  onHandleClose: () => void;
};

export const Alert = ({ alert, onHandleClose }: Props) => {
  const { title, description, type } = alert;
  const [fadeIn, setFadeIn] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    let automaticCloseTimeoutId: NodeJS.Timeout;
    if (fadeOut) {
      setFadeIn(false);
      timeoutId = setTimeout(() => onHandleClose(), 400);
    } else {
      setFadeIn(true);
    }
    automaticCloseTimeoutId = setTimeout(() => setFadeOut(true), 4600);

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(automaticCloseTimeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fadeOut]);

  const onClose = () => {
    setFadeOut(true);
  };

  let icon: JSX.Element;

  switch (type) {
    case "success":
      icon = successIcon;
      break;
    case "error":
      icon = errorIcon;
      break;
    case "warning":
      icon = warningIcon;
      break;
    case "info":
      icon = infoIcon;
      break;
    default:
      icon = infoIcon;
  }

  return (
    <article
      className={clsx(
        "flex flex-row p-3 text-white rounded-xl w-full tablet:w-[444px] shadow-alert backdrop-blur-sm transition-opacity duration-400",
        type === "success" && "bg-secondary-green-90",
        type === "error" && "bg-primary-red-90",
        type === "warning" && "bg-primary-yellow-90",
        type === "info" && "bg-primary-blue-90",
        fadeIn ? "opacity-100" : "opacity-0"
      )}
    >
      <span className="shrink-0">{icon}</span>
      <div className="flex flex-col w-full ml-2">
        <p className="text-xl font-semibold">{title}</p>
        <p className="my-auto">{description}</p>
      </div>
      <button className="mb-auto" onClick={onClose}>
        {closingCrossIcon}
      </button>
    </article>
  );
};
