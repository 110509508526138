import { ComponentType, Suspense, lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";

import AppWrapper from "./components/molecules/AppWrapper/AppWrapper";
import NavigationBar from "./components/molecules/NavigationBar/NavigationBar";
import { Subscription as SubscriptionType } from "./redux/user/userSlice";

export function retry(
  fn,
  retriesLeft = 3,
  interval = 1000
): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

const BotPrivacy = lazy(() =>
  retry(() => import("./pages/DiscordBot/Privacy"))
);
const BotTos = lazy(() => retry(() => import("./pages/DiscordBot/Tos")));
const SquadBuilder = lazy(() =>
  retry(() => import("./pages/SquadBuilder/SquadBuilder"))
);
const PlayersPage = lazy(() =>
  retry(() => import("./pages/Players/PlayersPage"))
);
const DetailedPlayerView = lazy(() =>
  retry(
    () => import("./components/organisms/DetailedPlayerView/DetailedPlayerView")
  )
);
const SbcSetPage = lazy(() => retry(() => import("./pages/Sbcs/SbcSetPage")));
const SbcSolutionPage = lazy(() =>
  retry(() => import("./pages/Sbcs/SbcSolutionPage"))
);
const WhoAreWe = lazy(() => retry(() => import("./pages/WhoAreWe/WhoAreWe")));
const Profile = lazy(() => retry(() => import("./pages/Profile/Profile")));
const Contact = lazy(() => retry(() => import("./pages/Footer/Contact")));
const Tos = lazy(() => retry(() => import("./pages/Footer/Tos")));
const Faq = lazy(() => retry(() => import("./pages/Footer/Faq")));
const Privacy = lazy(() => retry(() => import("./pages/Footer/Privacy")));
const Login = lazy(() => retry(() => import("./pages/Login/Login")));
const PaymentSuccess = lazy(() =>
  retry(() => import("./pages/Payments/PaymentSuccess"))
);
const PaymentCancelled = lazy(() =>
  retry(() => import("./pages/Payments/PaymentCancelled"))
);
const Subscription = lazy(() =>
  retry(() => import("./pages/Subscription/Subscription"))
);
const ImportPlayers = lazy(() =>
  retry(() => import("./pages/ImportPlayers/ImportPlayers"))
);
const MyClub = lazy(() => retry(() => import("./pages/MyClub/MyClub")));
const MetaRatingLandingPage = lazy(() =>
  retry(() => import("./pages/MetaRatingLandingPage/MetaRatingLandingPage"))
);
const SbcSetsPage = lazy(() => retry(() => import("./pages/Sbcs/SbcSetsPage")));
const LandingPageBasic = lazy(() =>
  retry(() => import("./pages/LandingPages/LandingPageBasic"))
);
const LandingPageTOTS = lazy(() =>
  retry(() => import("./pages/LandingPages/LandingPageTOTS"))
);
const LandingPageTOTY = lazy(() =>
  retry(() => import("./pages/LandingPages/LandingPageTOTY"))
);
const ConfirmLogin = lazy(() =>
  retry(() => import("./pages/Login/ConfirmLogin"))
);
const EmailUnsubscribe = lazy(() =>
  retry(() => import("./pages/EmailUnsubscribe/EmailUnsubscribe"))
);
const Components = lazy(() =>
  retry(() => import("./pages/Components/Components"))
);
const EvolutionsPage = lazy(() =>
  retry(() => import("./pages/EvolutionsPage/EvolutionsPage"))
);

const NewsPage = lazy(() =>
  retry(() => import("./components/features/News/NewsPage/NewsPage"))
);

const Releases = lazy(() => retry(() => import("./pages/Releases/Releases")));

const News = lazy(() => retry(() => import("./pages/News/News")));

type Props = {
  isLoggedIn: boolean;
  subscription: SubscriptionType;
};

const AppRoutes = ({ isLoggedIn, subscription }: Props) => {
  const location = useLocation();
  const subPage = location.pathname.split("/")[1];
  const popup = location.pathname.split("/")[3];

  const pageTitle =
    subPage === "players"
      ? {
          title: "EA FC 24 Players and Meta Ratings Database",
          isHeader: !popup,
        }
      : subPage === "evolutions"
      ? {
          title: "EA FC 24 Evolutions and Evolution Players",
          isHeader: !popup,
        }
      : subPage === "squad-builder"
      ? { title: "FC 24 Squad Builder" }
      : subPage === "meta-rating"
      ? { title: "EA FC 24 Meta Ratings" }
      : subPage === "who-are-we"
      ? { title: "Who Are We" }
      : subPage === "profile"
      ? { title: "Profile" }
      : subPage === "login"
      ? { title: "Login" }
      : subPage === "my-club"
      ? { title: "EasySBC" }
      : subPage === "subscription"
      ? { title: "Subscription" }
      : subPage === "releases"
      ? { title: "Latest Releases" }
      : { title: "EasySBC", isHeader: false };

  return (
    <AppWrapper isLoggedIn={isLoggedIn}>
      <NavigationBar
        isLoggedIn={isLoggedIn}
        pageTitle={pageTitle.title}
        isHeader={pageTitle.isHeader !== false}
      />
      <Suspense fallback={<></>}>
        <Routes>
          <Route path={"/squad-builder"} element={<SquadBuilder />} />
          <Route path={"/players"} element={<PlayersPage />}>
            <Route
              path={":name/:resourceId"}
              element={<DetailedPlayerView />}
            />
          </Route>
          <Route path={"/evolutions"} element={<EvolutionsPage />}>
            <Route
              path={":name/:resourceId"}
              element={<DetailedPlayerView />}
            />
          </Route>
          <Route
            path={"/evolutions/my-evolutions"}
            element={<EvolutionsPage />}
          >
            <Route
              path={":name/:resourceId"}
              element={<DetailedPlayerView />}
            />
          </Route>
          <Route path={"/sbc-solution/:name/:id"} element={<SbcSetPage />} />
          <Route
            path={"/sbc-solution/:setName/:setId/:sbcName/:sbcId"}
            element={<SbcSolutionPage />}
          />
          <Route path={"/eafcassistant/tos"} element={<BotTos />} />
          <Route path={"/eafcassistant/privacy"} element={<BotPrivacy />} />
          <Route path={"/who-are-we"} element={<WhoAreWe />} />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/contact"} element={<Contact />} />
          <Route path={"/tos"} element={<Tos />} />
          <Route path={"/faq"} element={<Faq />} />
          <Route path={"/privacy"} element={<Privacy />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/payment-success"} element={<PaymentSuccess />} />
          <Route path={"/payment-cancelled"} element={<PaymentCancelled />} />
          <Route
            path={"/subscription"}
            element={
              <Subscription
                subscription={subscription}
                isLoggedIn={isLoggedIn}
              />
            }
          />
          <Route path={"/import"} element={<ImportPlayers />} />
          <Route path={"/my-club"} element={<MyClub />} />
          <Route path={"meta-rating"} element={<MetaRatingLandingPage />} />
          <Route path={"/releases/*"} element={<Releases />} />
          <Route path={"/news"} element={<News />} />
          <Route path={"/news/:newsLink"} element={<NewsPage />} />
          <Route path={"/"} element={<SbcSetsPage />} />
          <Route
            path={"*"}
            element={
              <main className="z-10 mt-12 text-2xl text-center text-white md:pt-4">
                The requested page was not found
              </main>
            }
          />
          {/* Used for tracking website conversions */}
          <Route
            path={"tots24"}
            element={
              <LandingPageTOTS
                discountText={"Get 50% off first month*"}
                discountId={process.env.REACT_APP_TOTS_WEBSITE_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"totyfc24"}
            element={
              <LandingPageTOTY
                discountText={"Get 50% off first month*"}
                discountId={process.env.REACT_APP_TOTY_WEBSITE_DISCOUNT_CODE}
              />
            }
          />
          {/* Used for tracking ad conversions */}
          <Route
            path={"toty2024"}
            element={
              <LandingPageTOTY
                discountText={"Get 50% off first month*"}
                discountId={process.env.REACT_APP_TOTY_ADS_DISCOUNT_CODE}
              />
            }
          />
          {/* Used for tracking email conversions */}
          <Route
            path={"toty24"}
            element={
              <LandingPageTOTY
                discountText={"Get 50% off first month*"}
                discountId={process.env.REACT_APP_TOTY_EMAIL_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"promo-10"}
            element={
              <LandingPageBasic
                discountText={"Get 10% off first month*"}
                discountId={process.env.REACT_APP_PROMO_10_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"promo-50"}
            element={
              <LandingPageBasic
                discountText={"Get 50% off first month*"}
                discountId={process.env.REACT_APP_PROMO_50_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"andy1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with AndyJFC*"
                discountId={process.env.REACT_APP_ANDYJFC_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"andy"}
            element={
              <Navigate
                to="/andy1?utm_source=andy&utm_medium=social&utm_campaign=affiliate"
                replace
              />
            }
          />
          <Route
            path={"futmentor1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with FUT Mentor*"
                discountId={process.env.REACT_APP_FUT_MENTOR_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"futmentor"}
            element={
              <Navigate
                to="/futmentor1?utm_source=futmentor&utm_medium=social&utm_campaign=affiliate"
                replace
              />
            }
          />
          <Route
            path={"futpepi1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with FUT Pepi Trading*"
                discountId={process.env.REACT_APP_FUT_PEPI_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"futpepi"}
            element={
              <Navigate
                to="/futpepi1?utm_source=futpepi&utm_medium=social&utm_campaign=affiliate&via=futpepitrading"
                replace
              />
            }
          />
          <Route
            path={"futlovers1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with Futlovers*"
                discountId={process.env.REACT_APP_FUTLOVERS_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"futlovers"}
            element={
              <Navigate
                to="/futlovers1?utm_source=futlovers&utm_medium=social&utm_campaign=affiliate"
                replace
              />
            }
          />
          <Route
            path={"fbeafc24"}
            element={
              <Navigate
                to="/fbeafc241?utm_source=fbeafc24&utm_medium=social&utm_campaign=affiliate&via=fbeafc24"
                replace
              />
            }
          />
          <Route
            path={"fbeafc241"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month*"
                discountId={process.env.REACT_APP_FBEAFC24_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"icon"}
            element={
              <Navigate
                to="/icon1?utm_source=iconsquadgaming&utm_medium=social&utm_campaign=affiliate&via=icon"
                replace
              />
            }
          />
          <Route
            path={"icon1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with IconSquadGaming*"
                discountId={process.env.REACT_APP_ICON_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"hyxer"}
            element={
              <Navigate
                to="/hyxer1?utm_source=hyxer&utm_medium=social&utm_campaign=affiliate&via=hyxer"
                replace
              />
            }
          />
          <Route
            path={"hyxer1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with Hyxer*"
                discountId={process.env.REACT_APP_HYXER_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"futworkshop"}
            element={
              <Navigate
                to="/futworkshop1?utm_source=futworkshop&utm_medium=social&utm_campaign=affiliate&via=futworkshop"
                replace
              />
            }
          />
          <Route
            path={"futworkshop1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with FutWorkShop*"
                discountId={process.env.REACT_APP_FUTWORKSHOP_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"pablo"}
            element={
              <Navigate
                to="/pablo1?utm_source=pablo&utm_medium=social&utm_campaign=affiliate"
                replace
              />
            }
          />
          <Route
            path={"pablo1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with Pablo*"
                discountId={process.env.REACT_APP_PABLO_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"chem241"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with Chem Expert*"
                discountId={process.env.REACT_APP_CHEM24_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"chem24"}
            element={
              <Navigate
                to="/chem241?utm_source=chem24&utm_medium=social&utm_campaign=affiliate&via=chem24"
                replace
              />
            }
          />
          <Route
            path={"junior1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with SkillingJunior*"
                discountId={process.env.REACT_APP_JUNIOR_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"junior"}
            element={
              <Navigate
                to="/junior1?utm_source=junior&utm_medium=social&utm_campaign=affiliate&via=junior"
                replace
              />
            }
          />
          <Route
            path={"david1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with David|FIFAllstars*"
                discountId={process.env.REACT_APP_DAVID_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"david"}
            element={
              <Navigate
                to="/david1?utm_source=david&utm_medium=social&utm_campaign=affiliate&via=david"
                replace
              />
            }
          />
          <Route
            path={"einsfut1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with EinsFUT*"
                discountId={process.env.REACT_APP_EINSFUT_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"einsfut"}
            element={
              <Navigate
                to="/einsfut1?utm_source=einsfut&utm_medium=social&utm_campaign=affiliate&via=einsfut"
                replace
              />
            }
          />
          <Route
            path={"fccentralen1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with FC Centralen*"
                discountId={process.env.REACT_APP_FCCENTRALEN_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"fccentralen"}
            element={
              <Navigate
                to="/fccentralen1?utm_source=fccentralen&utm_medium=social&utm_campaign=affiliate&via=fccentralen"
                replace
              />
            }
          />
          <Route
            path={"rambler"}
            element={
              <Navigate
                to="/rambler1?utm_source=rambler&utm_medium=social&utm_campaign=affiliate&via=rambler"
                replace
              />
            }
          />
          <Route
            path={"rambler1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with FUT Rambler*"
                discountId={process.env.REACT_APP_RAMBLER_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"tuga810"}
            element={
              <Navigate
                to="/tuga8101?utm_source=tuga810&utm_medium=social&utm_campaign=affiliate&via=tuga810"
                replace
              />
            }
          />
          <Route
            path={"tuga8101"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with Tuga810*"
                discountId={process.env.REACT_APP_TUGA810_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"bullardiniho1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with Bullardiniho*"
                discountId={process.env.REACT_APP_BULLARDINIHO_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"bullardiniho"}
            element={
              <Navigate
                to="/bullardiniho1?utm_source=bullardiniho&utm_medium=social&utm_campaign=affiliate&via=bullardiniho"
                replace
              />
            }
          />
          <Route
            path={"mentor1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with Mentor*"
                discountId={process.env.REACT_APP_MENTOR_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"mentor"}
            element={
              <Navigate
                to="/mentor1?utm_source=mentor&utm_medium=social&utm_campaign=affiliate&via=mentor"
                replace
              />
            }
          />
          <Route
            path={"alexion"}
            element={
              <Navigate
                to="/alexion1?utm_source=alexion&utm_medium=social&utm_campaign=affiliate&via=alexion"
                replace
              />
            }
          />
          <Route
            path={"alexion1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with ALEXION*"
                discountId={process.env.REACT_APP_ALEXION_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"gdisplay"}
            element={
              <Navigate
                to="/gdisplay1?utm_source=googledisplay&utm_medium=cpc&utm_campaign=googledisplay&via=googledisplay"
                replace
              />
            }
          />
          <Route
            path={"gdisplay1"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month*"
                discountId={process.env.REACT_APP_GOOGLE_DISPLAY_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"SAF"}
            element={
              <Navigate
                to="/?utm_source=saf&utm_medium=social&utm_campaign=saf"
                replace
              />
            }
          />
          <Route path={"confirm-login"} element={<ConfirmLogin />} />
          <Route path={"/email-unsubscribe"} element={<EmailUnsubscribe />} />
          <Route
            path={"tekkz"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month with Tekkz*"
                discountId={process.env.REACT_APP_TEKKZ_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"promo-tekkz"}
            element={
              <Navigate
                to="/tekkz?utm_source=tekkz&utm_medium=social&utm_campaign=sponsor&via=tekkz"
                replace
              />
            }
          />
          <Route
            path={"promo-meta-50-a"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month*"
                discountId={process.env.REACT_APP_META_A_DISCOUNT_CODE}
              />
            }
          />
          <Route
            path={"promo-meta-50-b"}
            element={
              <LandingPageBasic
                discountText="Get 50% off first month*"
                discountId={process.env.REACT_APP_META_B_DISCOUNT_CODE}
              />
            }
          />
          {/* For showcasing components*/}
          {process.env.REACT_APP_SHOW_COMPONENTS && (
            <Route path={"/components"} element={<Components />} />
          )}
        </Routes>
      </Suspense>
    </AppWrapper>
  );
};

export default AppRoutes;
