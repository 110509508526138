export const menu = (
  <svg
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="30px"
    height="30px"
  >
    <path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z" />
  </svg>
);

export const open_link = (
  <svg
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 25"
    width="20px"
    height="20px"
  >
    <path d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z" />
  </svg>
);

export const remove = (
  <svg
    fill="#ffffff"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="22px"
    height="22px"
  >
    <path d="M 20.5 4 A 1.50015 1.50015 0 0 0 19.066406 6 L 14.640625 6 C 12.803372 6 11.082924 6.9194511 10.064453 8.4492188 L 7.6972656 12 L 7.5 12 A 1.50015 1.50015 0 1 0 7.5 15 L 8.2636719 15 A 1.50015 1.50015 0 0 0 8.6523438 15.007812 L 11.125 38.085938 C 11.423352 40.868277 13.795836 43 16.59375 43 L 31.404297 43 C 34.202211 43 36.574695 40.868277 36.873047 38.085938 L 39.347656 15.007812 A 1.50015 1.50015 0 0 0 39.728516 15 L 40.5 15 A 1.50015 1.50015 0 1 0 40.5 12 L 40.302734 12 L 37.935547 8.4492188 C 36.916254 6.9202798 35.196001 6 33.359375 6 L 28.933594 6 A 1.50015 1.50015 0 0 0 27.5 4 L 20.5 4 z M 14.640625 9 L 33.359375 9 C 34.196749 9 34.974746 9.4162203 35.439453 10.113281 L 36.697266 12 L 11.302734 12 L 12.560547 10.113281 A 1.50015 1.50015 0 0 0 12.5625 10.111328 C 13.025982 9.4151428 13.801878 9 14.640625 9 z M 11.669922 15 L 36.330078 15 L 33.890625 37.765625 C 33.752977 39.049286 32.694383 40 31.404297 40 L 16.59375 40 C 15.303664 40 14.247023 39.049286 14.109375 37.765625 L 11.669922 15 z" />
  </svg>
);

export const copy = (
  <svg fill="white" className="w-6 h-6" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 6V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h4zm2 0h4a2 2 0 0 1 2 2v4h4V2H8v4zM2 8v10h10V8H2z" />
  </svg>
);

export const copied = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-6 h-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="#0094FF"
    strokeWidth="2"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
  </svg>
);

export const info = (
  <svg
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    ></path>
  </svg>
);

export const simpleInfoIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="5.58" stroke="currentColor" strokeWidth="0.84" />
    <path
      d="M5.48991 9.5V4.26364H6.50923V9.5H5.48991ZM6.00469 3.45568C5.82741 3.45568 5.67514 3.39659 5.54787 3.27841C5.42287 3.15795 5.36037 3.01477 5.36037 2.84886C5.36037 2.68068 5.42287 2.5375 5.54787 2.41932C5.67514 2.29886 5.82741 2.23864 6.00469 2.23864C6.18196 2.23864 6.3331 2.29886 6.4581 2.41932C6.58537 2.5375 6.64901 2.68068 6.64901 2.84886C6.64901 3.01477 6.58537 3.15795 6.4581 3.27841C6.3331 3.39659 6.18196 3.45568 6.00469 3.45568Z"
      fill="currentColor"
    />
  </svg>
);

export const checkmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-4 desktop:h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.5 12.75l6 6 9-13.5"
    />
  </svg>
);

export const back = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 52 52"
    enableBackground="new 0 0 52 52"
    fill="white"
  >
    <path
      d="M48.6,23H15.4c-0.9,0-1.3-1.1-0.7-1.7l9.6-9.6c0.6-0.6,0.6-1.5,0-2.1l-2.2-2.2c-0.6-0.6-1.5-0.6-2.1,0
L2.5,25c-0.6,0.6-0.6,1.5,0,2.1L20,44.6c0.6,0.6,1.5,0.6,2.1,0l2.1-2.1c0.6-0.6,0.6-1.5,0-2.1l-9.6-9.6C14,30.1,14.4,29,15.3,29
h33.2c0.8,0,1.5-0.6,1.5-1.4v-3C50,23.8,49.4,23,48.6,23z"
    />
  </svg>
);

export const upVoteArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 16 10"
    fill="none"
  >
    <path
      d="M7.29377 0.354386C7.48857 0.158664 7.8044 0.158664 7.9992 0.354386L14.9398 7.32776C15.1346 7.52348 15.1346 7.84081 14.9398 8.03653L13.7337 9.2483C13.5389 9.44402 13.2231 9.44402 13.0283 9.2483L7.9992 4.19546C7.8044 3.99974 7.48857 3.99974 7.29377 4.19546L2.2647 9.2483C2.0699 9.44402 1.75406 9.44402 1.55926 9.2483L0.353199 8.03653C0.158398 7.84081 0.158399 7.52348 0.3532 7.32776L7.29377 0.354386Z"
      fill="var(--fill1)"
    />
  </svg>
);

export const downVoteArrow = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.35275 10.0089C8.15793 10.2046 7.8421 10.2046 7.64732 10.0089L0.70746 3.03478C0.512679 2.83904 0.512711 2.52171 0.707533 2.32601L1.91372 1.11436C2.10854 0.918661 2.42438 0.918694 2.61916 1.11444L7.64771 6.16779C7.84249 6.36353 8.15832 6.36356 8.35315 6.16786L13.3827 1.11553C13.5775 0.919828 13.8934 0.91986 14.0882 1.1156L15.2941 2.32749C15.4889 2.52323 15.4889 2.84056 15.294 3.03626L8.35275 10.0089Z"
      fill="var(--fill2)"
    />
  </svg>
);

export const retry = (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="white"
      d="M14.9547098,7.98576084 L15.0711,7.99552 C15.6179,8.07328 15.9981,8.57957 15.9204,9.12636 C15.6826,10.7983 14.9218,12.3522 13.747,13.5654 C12.5721,14.7785 11.0435,15.5888 9.37999,15.8801 C7.7165,16.1714 6.00349,15.9288 4.48631,15.187 C3.77335,14.8385 3.12082,14.3881 2.5472,13.8537 L1.70711,14.6938 C1.07714,15.3238 3.55271368e-15,14.8776 3.55271368e-15,13.9867 L3.55271368e-15,9.99998 L3.98673,9.99998 C4.87763,9.99998 5.3238,11.0771 4.69383,11.7071 L3.9626,12.4383 C4.38006,12.8181 4.85153,13.1394 5.36475,13.3903 C6.50264,13.9466 7.78739,14.1285 9.03501,13.9101 C10.2826,13.6916 11.4291,13.0839 12.3102,12.174 C13.1914,11.2641 13.762,10.0988 13.9403,8.84476 C14.0181,8.29798 14.5244,7.91776 15.0711,7.99552 L14.9547098,7.98576084 Z M11.5137,0.812976 C12.2279,1.16215 12.8814,1.61349 13.4558,2.14905 L14.2929,1.31193 C14.9229,0.681961 16,1.12813 16,2.01904 L16,6.00001 L12.019,6.00001 C11.1281,6.00001 10.6819,4.92287 11.3119,4.29291 L12.0404,3.56441 C11.6222,3.18346 11.1497,2.86125 10.6353,2.60973 C9.49736,2.05342 8.21261,1.87146 6.96499,2.08994 C5.71737,2.30841 4.57089,2.91611 3.68976,3.82599 C2.80862,4.73586 2.23802,5.90125 2.05969,7.15524 C1.98193,7.70202 1.47564,8.08224 0.928858,8.00448 C0.382075,7.92672 0.00185585,7.42043 0.0796146,6.87364 C0.31739,5.20166 1.07818,3.64782 2.25303,2.43465 C3.42788,1.22148 4.95652,0.411217 6.62001,0.119916 C8.2835,-0.171384 9.99651,0.0712178 11.5137,0.812976 Z"
    />
  </svg>
);

export const activeSquad = (
  <svg
    height="7px"
    viewBox="0 0 19 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.47469 11.1532H1.01356C1.01356 11.1532 0.791818 7.72899 2.1417 6.35866C2.52817 5.96633 3.26984 5.51255 3.26984 5.51255C3.26984 5.51255 2.53721 4.14873 2.70586 3.25628C2.95212 1.95306 3.91789 1 5.24417 1C6.57045 1 7.53624 1.95306 7.78248 3.25628C7.95111 4.14874 9.33196 5.51255 9.33196 5.51255C9.33196 5.51255 7.95999 5.96633 8.34646 6.35866C9.69635 7.72899 9.47469 11.1532 9.47469 11.1532Z"
      fill="#e2e8f0"
      stroke="#282C39"
      strokeWidth="1"
    />
    <path
      d="M18.5157 11.1532H10.0546C10.0546 11.1532 9.83283 7.72899 11.1827 6.35866C11.5692 5.96633 11.4994 5.51255 11.4994 5.51255C11.4994 5.51255 11.5782 4.14873 11.7469 3.25628C11.9931 1.95306 12.9589 1 14.2852 1C15.6115 1 16.5773 1.95306 16.8235 3.25628C16.9921 4.14874 16.2593 5.51255 16.2593 5.51255C16.2593 5.51255 17.001 5.96633 17.3875 6.35866C18.7374 7.72899 18.5157 11.1532 18.5157 11.1532Z"
      fill="#e2e8f0"
      stroke="#282C39"
      strokeWidth="1"
    />
    <path
      d="M13.9962 11.9999H5.53504C5.53504 11.9999 5.3133 8.57567 6.66318 7.20534C7.04965 6.81301 7.79132 6.35923 7.79132 6.35923C7.79132 6.35923 7.0587 4.99541 7.22734 4.10296C7.47361 2.79974 8.43938 1.84668 9.76565 1.84668C11.0919 1.84668 12.0577 2.79974 12.304 4.10296C12.4726 4.99542 11.7398 6.35923 11.7398 6.35923C11.7398 6.35923 12.4815 6.81301 12.8679 7.20534C14.2178 8.57567 13.9962 11.9999 13.9962 11.9999Z"
      fill="#e2e8f0"
      stroke="#282C39"
      strokeWidth="1"
    />
  </svg>
);

export const expire = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 22 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M22 14.2663C22 20.1944 17.0751 25 11 25C4.92487 25 0 20.1944 0 14.2663C0 8.75173 4.2618 4.20846 9.74684 3.6015V2.71739H7.24051V0H9.74684H12.2532H14.7595V2.71739H12.2532V3.6015C17.7382 4.20846 22 8.75173 22 14.2663ZM16.5992 9.22184L15.3382 8.15217L9.85 14.3128L11.111 15.3825L16.5992 9.22184Z"
    />
  </svg>
);

export const repeatable = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1058 8.48018C13.8987 10.1435 13.1709 11.6853 12.0387 12.859C10.9066 14.0327 9.43543 14.7706 7.86052 14.9547C6.28561 15.1388 4.69788 14.7585 3.35118 13.8745C2.00448 12.9906 0.976573 11.654 0.431809 10.0786C-0.112955 8.50317 -0.143118 6.77984 0.346141 5.18413C0.8354 3.58842 1.81583 2.21247 3.13067 1.27629C4.44552 0.340102 6.01885 -0.102261 7.59911 0.0199237C9.17938 0.142108 10.6753 0.821783 11.8478 1.95028C11.8478 1.95028 12.7984 0.576922 13.0768 0.576922C13.3551 0.576922 15.1905 5.99662 14.9838 6.34615C14.7771 6.69567 8.99028 5.44276 8.99028 5.1923C8.99028 4.94185 10.2416 3.82114 10.2416 3.82114C9.46441 3.07306 8.47276 2.62251 7.42521 2.54152C6.37767 2.46052 5.33472 2.75376 4.46312 3.37435C3.59152 3.99494 2.9416 4.90704 2.61728 5.96483C2.29295 7.02261 2.31295 8.16499 2.67407 9.20933C3.03518 10.2537 3.71657 11.1396 4.60929 11.7256C5.50201 12.3116 6.5545 12.5637 7.59849 12.4417C8.64249 12.3196 9.61769 11.8305 10.3682 11.0524C11.1187 10.2744 11.6011 9.25234 11.7384 8.14975L14.1058 8.48018Z"
      fill="currentColor"
    />
  </svg>
);

export const noneRepeatable = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9663 8.24271C14.125 9.86932 13.72 11.5012 12.8203 12.8602C11.9205 14.2192 10.5817 15.2213 9.03199 15.6958C7.48224 16.1703 5.81743 16.0877 4.32123 15.4622C2.82502 14.8367 1.58998 13.707 0.826564 12.2655C0.063143 10.824 -0.181432 9.15999 0.134513 7.55692C0.450457 5.95384 1.30737 4.51092 2.55925 3.47402C3.81113 2.43711 5.37697 1.87043 6.99645 1.87043C6.99645 1.87043 6.99645 0.655604 6.99645 0.0713124C6.99645 -0.512979 12.3757 2.6694 12.3757 3.2058C12.3757 3.74221 6.99999 6.89458 6.99645 6.3403C6.99292 5.78601 6.99645 4.2132 6.99645 4.2132C5.91449 4.2132 4.86953 4.59386 4.03316 5.28661C3.19679 5.97936 2.62429 6.94337 2.41321 8.01437C2.23308 8.92831 2.32565 9.87192 2.67362 10.7275L10.4927 5.79634C10.4927 5.79634 12.1443 4.7454 12.4818 4.54172C12.8193 4.33804 13.3213 5.80845 13.5769 6.51619C13.7766 7.06919 13.9083 7.64889 13.9663 8.24271ZM8.35756 13.4519C7.32219 13.7689 6.20993 13.7137 5.21033 13.2959C4.76877 13.1113 4.36125 12.8609 3.99991 12.556L11.5396 7.80099C11.5935 8.02069 11.6319 8.24507 11.6541 8.47255C11.7602 9.55928 11.4896 10.6495 10.8885 11.5575C10.2874 12.4654 9.39294 13.1349 8.35756 13.4519Z"
      fill="currentColor"
    />
  </svg>
);

export const repeatableInterval = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.21551 7.4224V3.80172H7.84482V6.57758L10.0776 8.81033L8.81034 10.0172L6.21551 7.4224Z"
      fill="currentColor"
    />
    <path
      d="M0.0603448 0.724138H1.68965V2.43924C2.51055 1.48345 3.57982 0.760689 4.78998 0.358027C6.3994 -0.177485 8.14853 -0.110407 9.71221 0.546792C11.2759 1.20399 12.5478 2.4066 13.2914 3.93108C14.035 5.45555 14.1998 7.19818 13.7552 8.83504C13.3105 10.4719 12.2867 11.8916 10.8739 12.8303C9.46116 13.769 7.75563 14.1628 6.07434 13.9385C4.39306 13.7142 2.85041 12.8871 1.73314 11.6109C0.615874 10.3346 0 8.69617 0 6.99999H1.74961C1.74961 8.27222 2.21155 9.50117 3.04957 10.4584C3.88758 11.4156 5.04465 12.036 6.30571 12.2043C7.56676 12.3725 8.846 12.0771 9.90565 11.3731C10.9653 10.669 11.7332 9.60411 12.0668 8.37638C12.4003 7.14865 12.2767 5.84158 11.7189 4.69814C11.1611 3.5547 10.2072 2.65267 9.03431 2.15974C7.86146 1.6668 6.54952 1.61649 5.34236 2.01815C4.34299 2.35068 3.47171 2.97425 2.83614 3.80172H4.76724V5.55172H0.844827C0.482758 5.55172 0.0603448 5.18965 0.0603448 4.76724V0.724138Z"
      fill="currentColor"
    />
  </svg>
);

export const change_price_source_arrow = (
  <svg
    width="20"
    height="15"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 2.4C14 2.59091 7.86111 8 7.5 8C7.13889 8 1 2.59091 1 2.4C1 2.20909 2.08333 0.999776 2.625 1C3.16667 1.00022 7.13889 5.2 7.5 5.2C7.86111 5.2 11.8333 1 12.375 1C12.9167 1 14 2.20909 14 2.4Z"
      stroke="#434348"
    />
  </svg>
);

export const requirements = (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M3.5 3.75a.25.25 0 01.25-.25h13.5a.25.25 0 01.25.25v10a.75.75 0 001.5 0v-10A1.75 1.75 0 0017.25 2H3.75A1.75 1.75 0 002 3.75v16.5c0 .966.784 1.75 1.75 1.75h7a.75.75 0 000-1.5h-7a.25.25 0 01-.25-.25V3.75z" />
    <path d="M6.25 7a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zm-.75 4.75a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75zm16.28 4.53a.75.75 0 10-1.06-1.06l-4.97 4.97-1.97-1.97a.75.75 0 10-1.06 1.06l2.5 2.5a.75.75 0 001.06 0l5.5-5.5z" />
  </svg>
);

export const noExpiring = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 78 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.3392 90.9652C16.5818 90.0925 0.0181543 72.9369 1.49134e-05 51.8948C4.97161e-06 51.9063 0 51.9178 0 51.9293C0 72.9501 16.5705 90.0936 37.3392 90.9652ZM40.3679 90.9764C40.331 90.9777 40.2941 90.9789 40.2573 90.9801C39.8398 90.9933 39.4207 91 39 91M78 51.9293C78 72.9174 61.4809 90.0403 40.7575 90.961C61.4696 90.0392 77.9819 72.9043 78 51.8948C78 51.9063 78 51.9178 78 51.9293Z"
      fill="currentColor"
      fillOpacity="0.72"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.3291 0H25.6709V9.8913H34.557V13.1095C15.1212 15.3175 0.0173369 31.8373 1.49134e-05 51.8948C0.0181543 72.9369 16.5818 90.0925 37.3392 90.9652C37.89 90.9884 38.4436 91 39 91C39.4207 91 39.8398 90.9933 40.2573 90.9801L40.3679 90.9764C40.4979 90.9719 40.6278 90.9668 40.7575 90.961C61.4696 90.0392 77.9819 72.9043 78 51.8948C77.9827 31.8373 62.8788 15.3175 43.443 13.1095V9.8913H52.3291V0ZM39 24.6088C24.0818 24.6088 11.9882 36.7719 11.9882 51.7759C11.9882 57.2101 13.5746 62.2716 16.3071 66.5176L53.9223 29.1271C49.6469 26.2721 44.5166 24.6088 39 24.6088ZM66.0118 51.7759C66.0118 66.7798 53.9182 78.9429 39 78.9429C33.2749 78.9429 27.9658 77.1515 23.5965 74.0957L61.4084 36.6012C64.3149 40.9342 66.0118 46.1557 66.0118 51.7759Z"
      fill="currentColor"
    />
  </svg>
);

export const untradeableOnly = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 43 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="21.0005" cy="20.0742" rx="20.1099" ry="19.2188" fill="none" />
    <path
      d="M42.901 20.5C42.901 31.8218 33.2973 41 21.4505 41C9.60372 41 0 31.8218 0 20.5C0 9.17816 9.60372 0 21.4505 0C33.2973 0 42.901 9.17816 42.901 20.5ZM4.38635 20.5C4.38635 29.5067 12.0262 36.808 21.4505 36.808C30.8748 36.808 38.5147 29.5067 38.5147 20.5C38.5147 11.4933 30.8748 4.19198 21.4505 4.19198C12.0262 4.19198 4.38635 11.4933 4.38635 20.5Z"
      fill="url(#paint0_linear_2672_12560)"
    />
    <path
      d="M10.221 28.7666C10.1073 28.8726 9.48764 29.774 10.1073 30.3602C10.727 30.9464 11.6987 30.785 11.926 30.5727C12.1534 30.3604 32.5003 12.2779 32.8413 11.959C33.1823 11.64 33.1876 10.4737 32.5003 10.1528C31.8649 9.85616 31.0226 9.83383 30.6816 10.1528C30.3406 10.4718 10.3347 28.6605 10.221 28.7666Z"
      fill="url(#paint1_linear_2672_12560)"
    />
    <path
      d="M31.1204 23.7528C26.7264 23.7528 22.833 23.8798 22.4453 24.003C22.0576 24.1262 20.9136 24.6986 21.7454 25.5043C22.5772 26.31 26.0901 25.8111 30.8405 26.0047C30.1406 26.8805 29.5983 27.9698 27.761 29.0073C27.761 29.0073 30.202 29.2374 31.2601 28.5069C33.8755 26.7009 35.5461 25.1012 35.7322 24.9905C35.7357 24.995 35.7391 24.9994 35.7426 25.0039C35.7546 24.9834 35.751 24.9794 35.7322 24.9905C32.9505 21.4412 30.1319 20.6937 27.621 20.5C28.4643 22.3766 29.1641 21.7511 31.1204 23.7528Z"
      fill="url(#paint2_linear_2672_12560)"
    />
    <path
      d="M11.2359 14.93C16.0056 14.93 20.7752 14.8025 21.1961 14.93C21.6169 15.0575 22.9407 15.3714 22.0378 16.2049C21.1349 17.0384 16.2521 16.5148 11.0956 16.7151C12.0803 17.621 12.5605 18.238 14.5548 19.3113C14.5548 19.3113 12.0917 20.067 10.9433 19.3113C7.81771 17.2545 7.02574 15.4637 7.16802 15.6949C10.2098 12.6235 11.6178 11.3061 14.3467 11.1055C13.9367 12.0127 12.8811 12.3723 11.2359 14.93Z"
      fill="url(#paint3_linear_2672_12560)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2672_12560"
        x1="21.4505"
        y1="0"
        x2="21.4505"
        y2="41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB800" />
        <stop offset="1" stopColor="#FF7A00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2672_12560"
        x1="21.4514"
        y1="9.92187"
        x2="21.4514"
        y2="30.7662"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB800" />
        <stop offset="1" stopColor="#FF7A00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2672_12560"
        x1="28.5994"
        y1="20.5"
        x2="28.5994"
        y2="29.0417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB800" />
        <stop offset="1" stopColor="#FF7A00" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2672_12560"
        x1="14.7494"
        y1="11.1055"
        x2="14.7494"
        y2="19.6471"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB800" />
        <stop offset="1" stopColor="#FF7A00" />
      </linearGradient>
    </defs>
  </svg>
);

export const excludeActiveSquad = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25ZM5.11217 25C5.11217 35.9837 14.0163 44.8878 25 44.8878C35.9837 44.8878 44.8878 35.9837 44.8878 25C44.8878 14.0163 35.9837 5.11217 25 5.11217C14.0163 5.11217 5.11217 14.0163 5.11217 25Z"
      fill="url(#paint0_linear_2347_9341)"
    />
    <path
      d="M44.0543 25.1087C44.0543 35.4929 35.3993 43.9674 24.6522 43.9674C13.905 43.9674 5.25 35.4929 5.25 25.1087C5.25 14.7245 13.905 6.25 24.6522 6.25C35.3993 6.25 44.0543 14.7245 44.0543 25.1087Z"
      fill="#282C39"
      stroke="url(#paint1_linear_2347_9341)"
      strokeWidth="2.5"
    />
    <path
      d="M24.1255 31.5932H8.8799C8.8799 31.5932 8.48036 25.8166 10.9126 23.5048C11.609 22.843 12.9454 22.0775 12.9454 22.0775C12.9454 22.0775 11.6253 19.7767 11.9292 18.2712C12.3729 16.0726 14.113 14.4648 16.5028 14.4648C18.8925 14.4648 20.6327 16.0726 21.0764 18.2712C21.3803 19.7767 23.8683 22.0775 23.8683 22.0775C23.8683 22.0775 21.3963 22.843 22.0926 23.5048C24.5249 25.8166 24.1255 31.5932 24.1255 31.5932Z"
      fill="url(#paint2_linear_2347_9341)"
      stroke="#282C39"
    />
    <path
      d="M40.4185 31.5932H25.1729C25.1729 31.5932 24.7733 25.8166 27.2056 23.5048C27.902 22.843 27.7762 22.0775 27.7762 22.0775C27.7762 22.0775 27.9183 19.7767 28.2221 18.2712C28.6659 16.0726 30.406 14.4648 32.7958 14.4648C35.1855 14.4648 36.9257 16.0726 37.3694 18.2712C37.6732 19.7767 36.3529 22.0775 36.3529 22.0775C36.3529 22.0775 37.6892 22.843 38.3856 23.5048C40.8179 25.8166 40.4185 31.5932 40.4185 31.5932Z"
      fill="url(#paint3_linear_2347_9341)"
      stroke="#282C39"
    />
    <path
      d="M32.2739 33.019H17.0283C17.0283 33.019 16.6288 27.2423 19.0611 24.9306C19.7574 24.2688 21.0938 23.5032 21.0938 23.5032C21.0938 23.5032 19.7737 21.2025 20.0776 19.6969C20.5213 17.4984 22.2615 15.8906 24.6512 15.8906C27.041 15.8906 28.7812 17.4984 29.2249 19.6969C29.5287 21.2025 28.2083 23.5032 28.2083 23.5032C28.2083 23.5032 29.5447 24.2688 30.2411 24.9306C32.6733 27.2423 32.2739 33.019 32.2739 33.019Z"
      fill="url(#paint4_linear_2347_9341)"
      stroke="#282C39"
    />
    <path
      d="M9.75803 40.1631L10.236 42.3892L13.4689 43.7266L40.2467 9.83706L39.2571 6.37392L36.5359 6.27355L9.75803 40.1631Z"
      fill="url(#paint5_linear_2347_9341)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2347_9341"
        x1="25"
        y1="0"
        x2="25"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DA4545" />
        <stop offset="1" stopColor="#DB3C6C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2347_9341"
        x1="24.6522"
        y1="5"
        x2="24.6522"
        y2="45.2174"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="white" />
        <stop offset="1" stopColor="#8B8B8B" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2347_9341"
        x1="16.5027"
        y1="14.4648"
        x2="16.5027"
        y2="31.5932"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="white" />
        <stop offset="1" stopColor="#8B8B8B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2347_9341"
        x1="32.7957"
        y1="14.4648"
        x2="32.7957"
        y2="31.5932"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="white" />
        <stop offset="1" stopColor="#8B8B8B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2347_9341"
        x1="24.6511"
        y1="15.8906"
        x2="24.6511"
        y2="33.019"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="white" />
        <stop offset="1" stopColor="#8B8B8B" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2347_9341"
        x1="25.3921"
        y1="5.8844"
        x2="24.1006"
        y2="42.868"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DA454B" />
        <stop offset="1" stopColor="#DC4368" />
      </linearGradient>
    </defs>
  </svg>
);

export const activeSquadWithCircle = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19.0938" cy="19.0938" r="18.2812" fill="#282C39" />
    <path
      d="M39 19.5C39 30.2696 30.2696 39 19.5 39C8.73045 39 0 30.2696 0 19.5C0 8.73045 8.73045 0 19.5 0C30.2696 0 39 8.73045 39 19.5ZM3.9875 19.5C3.9875 28.0673 10.9327 35.0125 19.5 35.0125C28.0673 35.0125 35.0125 28.0673 35.0125 19.5C35.0125 10.9327 28.0673 3.9875 19.5 3.9875C10.9327 3.9875 3.9875 10.9327 3.9875 19.5Z"
      fill="url(#paint0_linear_2603_9710)"
    />
    <path
      d="M18.783 25.3387H6.02045C6.02045 25.3387 5.68598 20.5029 7.72211 18.5676C8.30505 18.0136 9.42377 17.3728 9.42377 17.3728C9.42377 17.3728 8.3187 15.4467 8.57308 14.1864C8.94454 12.3459 10.4013 11 12.4018 11C14.4023 11 15.8591 12.3459 16.2305 14.1864C16.4849 15.4467 18.5677 17.3728 18.5677 17.3728C18.5677 17.3728 16.4983 18.0136 17.0812 18.5676C19.1174 20.5029 18.783 25.3387 18.783 25.3387Z"
      fill="url(#paint1_linear_2603_9710)"
      stroke="#282C39"
    />
    <path
      d="M32.4236 25.3387H19.6611C19.6611 25.3387 19.3266 20.5029 21.3627 18.5676C21.9457 18.0136 21.8404 17.3728 21.8404 17.3728C21.8404 17.3728 21.9593 15.4467 22.2137 14.1864C22.5852 12.3459 24.0419 11 26.0424 11C28.043 11 29.4997 12.3459 29.8712 14.1864C30.1255 15.4467 29.0202 17.3728 29.0202 17.3728C29.0202 17.3728 30.1389 18.0136 30.7218 18.5676C32.758 20.5029 32.4236 25.3387 32.4236 25.3387Z"
      fill="url(#paint2_linear_2603_9710)"
      stroke="#282C39"
    />
    <path
      d="M25.6033 26.5321H12.8408C12.8408 26.5321 12.5063 21.6962 14.5424 19.761C15.1254 19.207 16.2441 18.5661 16.2441 18.5661C16.2441 18.5661 15.139 16.6401 15.3934 15.3797C15.7648 13.5393 17.2216 12.1934 19.2221 12.1934C21.2226 12.1934 22.6794 13.5393 23.0508 15.3797C23.3052 16.6401 22.1999 18.5661 22.1999 18.5661C22.1999 18.5661 23.3186 19.207 23.9015 19.761C25.9377 21.6962 25.6033 26.5321 25.6033 26.5321Z"
      fill="url(#paint3_linear_2603_9710)"
      stroke="#282C39"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2603_9710"
        x1="19.5"
        y1="0"
        x2="19.5"
        y2="39"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEFEFE" />
        <stop offset="1" stopColor="#8F8F8F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2603_9710"
        x1="12.4017"
        y1="11"
        x2="12.4017"
        y2="25.3387"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="white" />
        <stop offset="1" stopColor="#8B8B8B" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2603_9710"
        x1="26.0423"
        y1="11"
        x2="26.0423"
        y2="25.3387"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="white" />
        <stop offset="1" stopColor="#8B8B8B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2603_9710"
        x1="19.222"
        y1="12.1934"
        x2="19.222"
        y2="26.5321"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="white" />
        <stop offset="1" stopColor="#8B8B8B" />
      </linearGradient>
    </defs>
  </svg>
);

export const market = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 85 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.9379 15.1706V35.7193H24.4561V50.7883L0.101562 25.4449L24.4561 0.101562V15.1706H53.9379Z"
      fill="currentColor"
    />
    <path
      d="M30.8652 41.8839V62.4325H60.347V77.5016L84.7016 52.1582L60.347 26.8148V41.8839H30.8652Z"
      fill="currentColor"
    />
  </svg>
);

export const both = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 98 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.8644 64.4969C14.8261 64.4969 0.203125 50.1252 0.203125 32.3969C0.203125 14.6685 14.8261 0.296875 32.8644 0.296875C37.2082 0.296875 41.3539 1.13026 45.1451 2.64323C40.0584 6.24776 37.6364 8.50009 34.6538 13.2161C34.0645 13.1637 33.4676 13.1369 32.8644 13.1369C22.0414 13.1369 13.2676 21.7599 13.2676 32.3969C13.2676 43.0339 22.0414 51.6569 32.8644 51.6569C33.6917 51.6569 34.5069 51.6065 35.3073 51.5087C36.2593 51.3923 37.1902 51.2089 38.0949 50.9632C42.4681 49.7758 46.228 47.1359 48.8031 43.6048C51.1057 40.4475 52.4612 36.5778 52.4612 32.3969C52.4612 29.3541 51.7432 26.4761 50.4648 23.9176C54.3759 19.3787 56.7977 18.0432 61.3322 16.6497C64.0023 21.3021 65.5257 26.6746 65.5257 32.3969C65.5257 39.315 63.2989 45.722 59.5114 50.9632C56.7073 54.8435 53.0478 58.0849 48.8031 60.4217C47.9017 60.918 46.9738 61.3735 46.0221 61.7857C41.9968 63.529 37.5456 64.4969 32.8644 64.4969Z"
      fill="currentColor"
    />
    <path
      d="M97.4031 32.3969C97.4031 50.1252 82.7802 64.4969 64.7418 64.4969C60.7603 64.4969 56.6376 63.3561 53.1095 62.0742C58.6462 58.1374 61.1069 55.1091 63.2601 51.6569C63.5474 51.6692 64.4515 51.6569 64.7418 51.6569C75.5648 51.6569 84.3386 43.0339 84.3386 32.3969C84.3386 21.7599 75.5648 13.1369 64.7418 13.1369C62.9304 13.1369 61.1765 13.3784 59.5114 13.8305C55.1381 15.0179 51.3783 17.6579 48.8031 21.1889C48.4035 21.7369 48.0323 22.3064 47.6919 22.8953C46.0708 25.699 45.1451 28.9417 45.1451 32.3969C45.1451 35.5364 45.9094 38.5005 47.2652 41.1192C43.0282 45.4756 40.6819 47.4673 36.4993 48.5303C33.6899 43.79 32.0805 38.2782 32.0805 32.3969C32.0805 26.1751 33.8816 20.3668 36.9992 15.4481C37.3482 14.8975 37.7136 14.3581 38.0949 13.8305C40.8989 9.95022 44.5584 6.70885 48.8031 4.372C53.5176 1.77655 58.9539 0.296875 64.7418 0.296875C82.7802 0.296875 97.4031 14.6685 97.4031 32.3969Z"
      fill="currentColor"
    />
  </svg>
);

export const club = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 102 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2178 8.63477C27.9701 7.12645 28.848 5.64291 29.4835 1.82038C37.8449 0.712729 42.5407 0.220563 51.2984 0.306425C60.0562 0.22057 64.752 0.712694 73.1134 1.82026C73.7489 5.64247 74.6268 7.12588 78.379 8.63407C74.4631 40.0026 69.3171 54.28 51.2984 65.3914L51.1214 65.2542C33.4324 54.1687 28.2684 39.7083 24.2178 8.63477Z"
      fill="url(#paint0_linear_1036_14)"
    />
    <path
      d="M24.2178 8.63477C27.9701 7.12645 28.848 5.64291 29.4835 1.82038C37.8449 0.712729 42.5407 0.220563 51.2984 0.306425C60.0562 0.22057 64.752 0.712694 73.1134 1.82026C73.7489 5.64247 74.6268 7.12588 78.379 8.63407C74.4631 40.0026 69.3171 54.28 51.2984 65.3914L51.1214 65.2542C33.4324 54.1687 28.2684 39.7083 24.2178 8.63477Z"
      fill="currentColor"
    />
    <path
      d="M28.7313 47.9863C23.4892 37.0793 21.2639 30.3824 19.7044 16.9502L4.65963 17.7072C3.93481 20.112 3.61167 20.8729 0.898438 22.2491C2.50723 41.4958 6.68929 51.2833 18.1999 60.105C25.1146 55.8012 27.3179 51.7598 28.7313 47.9863Z"
      fill="url(#paint1_linear_1036_14)"
    />
    <path
      d="M28.7313 47.9863C23.4892 37.0793 21.2639 30.3824 19.7044 16.9502L4.65963 17.7072C3.93481 20.112 3.61167 20.8729 0.898438 22.2491C2.50723 41.4958 6.68929 51.2833 18.1999 60.105C25.1146 55.8012 27.3179 51.7598 28.7313 47.9863Z"
      fill="currentColor"
    />
    <path
      d="M73.8656 47.9823C79.1077 37.0762 81.3329 30.3798 82.8925 16.9488L97.9372 17.7057C98.6621 20.1102 98.9852 20.8711 101.698 22.2472C99.8178 41.4175 96.1819 51.0362 84.3969 60.1001C78.3179 56.7257 76.155 52.7791 73.8656 47.9823Z"
      fill="url(#paint2_linear_1036_14)"
    />
    <path
      d="M73.8656 47.9823C79.1077 37.0762 81.3329 30.3798 82.8925 16.9488L97.9372 17.7057C98.6621 20.1102 98.9852 20.8711 101.698 22.2472C99.8178 41.4175 96.1819 51.0362 84.3969 60.1001C78.3179 56.7257 76.155 52.7791 73.8656 47.9823Z"
      fill="currentColor"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1036_14"
        x1="51.2984"
        y1="0.296875"
        x2="51.2984"
        y2="65.3969"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--primary-light-blue)" />
        <stop offset="1" stopColor="#079BFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1036_14"
        x1="51.2984"
        y1="0.296875"
        x2="51.2984"
        y2="65.3969"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--primary-light-blue)" />
        <stop offset="1" stopColor="#079BFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1036_14"
        x1="51.2984"
        y1="0.296875"
        x2="51.2984"
        y2="65.3969"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--primary-light-blue)" />
        <stop offset="1" stopColor="#079BFF" />
      </linearGradient>
    </defs>
  </svg>
);

export const filter = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 25 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2V4.77037L9.26316 13.7333V19.1111L15.5789 24V13.7333L23 4.77037V2.0003L2 2Z"
      stroke="currentColor"
      strokeWidth="2.5"
    />
  </svg>
);

export const cross = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 20"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4" // TODO: remove opacity, change fill to "currentColor" and update accordingly
      d="M18.8105 17.0457L11.7116 10L18.8105 2.9543L16.6902 0.668652L9.5 7.80497L2.3098 0.668652L0.189504 2.9543L7.2884 10L0.189504 17.0457L2.3098 19.3313L9.5 12.195L16.6902 19.3313L18.8105 17.0457Z"
      fill="white"
    />
  </svg>
);

export const crossWithDynamicColor = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8105 17.0457L11.7116 10L18.8105 2.9543L16.6902 0.668652L9.5 7.80497L2.3098 0.668652L0.189504 2.9543L7.2884 10L0.189504 17.0457L2.3098 19.3313L9.5 12.195L16.6902 19.3313L18.8105 17.0457Z"
      fill="currentColor"
    />
  </svg>
);

export const thickCross = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.806217 2.88312C0.231556 2.30846 0.231557 1.37675 0.806218 0.802089C1.38088 0.227428 2.31259 0.227429 2.88725 0.80209L16.414 14.3288C16.9886 14.9035 16.9886 15.8352 16.414 16.4098C15.8393 16.9845 14.9076 16.9845 14.3329 16.4098L0.806217 2.88312Z"
      fill="white"
    />
    <path
      d="M2.88703 16.411C2.31236 16.9857 1.38066 16.9857 0.805996 16.411C0.231335 15.8364 0.231335 14.9047 0.805996 14.33L14.3327 0.803295C14.9074 0.228634 15.8391 0.228635 16.4137 0.803296C16.9884 1.37796 16.9884 2.30967 16.4137 2.88433L2.88703 16.411Z"
      fill="white"
    />
  </svg>
);

export const crossRounded = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="8.05469"
      y="21.25"
      width="1.88267"
      height="18.8267"
      rx="0.941336"
      transform="rotate(-135 8.05469 21.25)"
      fill="currentColor"
    />
    <rect
      x="6.72266"
      y="7.67188"
      width="1.88267"
      height="18.8267"
      rx="0.941336"
      transform="rotate(-45 6.72266 7.67188)"
      fill="currentColor"
    />
  </svg>
);

export const prioritizeDuplicates = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24.4805" cy="24.4785" r="23.4375" fill="#282C39" />
    <path
      d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25ZM5.11217 25C5.11217 35.9837 14.0163 44.8878 25 44.8878C35.9837 44.8878 44.8878 35.9837 44.8878 25C44.8878 14.0163 35.9837 5.11217 25 5.11217C14.0163 5.11217 5.11217 14.0163 5.11217 25Z"
      fill="url(#paint0_linear_2490_9613)"
    />
    <path
      d="M10.035 32.5165H30.8534C30.8534 32.5165 31.3989 24.5854 28.0776 21.4115C27.1267 20.5028 27.2984 19.4518 27.2984 19.4518C27.2984 19.4518 27.1044 16.2929 26.6895 14.2259C26.0836 11.2074 23.7073 9 20.4441 9C17.1808 9 14.8045 11.2074 14.1986 14.2259C13.7837 16.293 15.5867 19.4518 15.5867 19.4518C15.5867 19.4518 13.7619 20.5028 12.811 21.4115C9.48961 24.5854 10.035 32.5165 10.035 32.5165Z"
      fill="url(#paint1_linear_2490_9613)"
      stroke="#282C39"
      strokeWidth="2"
    />
    <path
      d="M18.1522 35.7626H38.9705C38.9705 35.7626 39.5161 27.8315 36.1948 24.6576C35.2439 23.7489 33.419 22.6979 33.419 22.6979C33.419 22.6979 35.2216 19.539 34.8067 17.472C34.2008 14.4535 31.8245 12.2461 28.5613 12.2461C25.298 12.2461 22.9217 14.4535 22.3158 17.472C21.9009 19.5391 23.7039 22.6979 23.7039 22.6979C23.7039 22.6979 21.8791 23.7489 20.9282 24.6576C17.6068 27.8315 18.1522 35.7626 18.1522 35.7626Z"
      fill="url(#paint2_linear_2490_9613)"
      stroke="#282C39"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2490_9613"
        x1="25"
        y1="0"
        x2="25"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF45B5" />
        <stop offset="1" stopColor="#D31B74" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2490_9613"
        x1="20.4442"
        y1="9"
        x2="20.4442"
        y2="32.5165"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF45B5" />
        <stop offset="1" stopColor="#D31B74" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2490_9613"
        x1="28.5614"
        y1="12.2461"
        x2="28.5614"
        y2="35.7626"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF45B5" />
        <stop offset="1" stopColor="#D31B74" />
      </linearGradient>
    </defs>
  </svg>
);

export const add_player_slot = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 144 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_0_1)">
      <path
        d="M143.949 140.561C144.065 121.763 143.949 22.4543 143.949 22.4543L128.936 0H15.0979L0.0510493 22.4543C0.0510493 22.4543 -0.064387 121.763 0.0516346 140.561C-0.155655 147.496 24.9876 158.14 73.1075 178C119.639 157.88 142.518 147.523 143.949 140.561Z"
        fill="url(#paint0_linear_0_1)"
      />
    </g>
    <path
      d="M1.54978 23.5759L1.55053 22.9112L15.8983 1.5H128.134L142.45 22.9104L142.45 23.5759L142.454 26.7598C142.457 29.5119 142.461 33.4645 142.465 38.2664C142.474 47.8702 142.485 60.8709 142.493 74.4591C142.507 101.48 142.507 130.814 142.45 140.385C142.315 140.896 141.968 141.551 141.267 142.363C140.513 143.237 139.416 144.216 137.93 145.311C134.957 147.501 130.596 150.033 124.76 153.01C113.193 158.908 96.08 166.43 73.0939 176.372C49.3061 166.553 31.2391 159.022 19.1165 153.086C12.9979 150.09 8.47154 147.538 5.50565 145.339C4.02162 144.238 2.99066 143.272 2.34802 142.434C1.70249 141.592 1.5392 140.999 1.55097 140.606L1.55177 140.579L1.55161 140.552C1.49363 131.159 1.49348 101.639 1.50783 74.4591C1.51501 60.8709 1.52582 47.8702 1.53482 38.2664C1.53933 33.4645 1.54339 29.5119 1.54631 26.7598L1.54978 23.5759Z"
      stroke="url(#paint1_linear_0_1)"
      strokeWidth="3"
    />
    <g filter="url(#filter1_d_0_1)">
      <path
        d="M97 78.4286V85.5714H75.5714V107H68.4286V85.5714H47V78.4286H68.4286V57H75.5714V78.4286H97Z"
        fill="url(#paint2_linear_0_1)"
        fillOpacity="0.2"
        shapeRendering="crispEdges"
      />
      <path
        d="M68.4286 85.0714H47.5V78.9286H68.4286H68.9286V78.4286V57.5H75.0714V78.4286V78.9286H75.5714H96.5V85.0714H75.5714H75.0714V85.5714V106.5H68.9286V85.5714V85.0714H68.4286Z"
        stroke="url(#paint3_linear_0_1)"
        strokeOpacity="0.1"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_0_1"
        x="0"
        y="0"
        width="154"
        height="188"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="10" dy="10" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_0_1" />
      </filter>
      <filter
        id="filter1_d_0_1"
        x="47"
        y="57"
        width="57"
        height="57"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_0_1"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_0_1"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_0_1"
        x1="18.7826"
        y1="1.46661e-06"
        x2="96.7279"
        y2="171.377"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#171717" />
        <stop offset="0.656242" stopColor="#181818" />
        <stop offset="0.875015" stopColor="#212121" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_0_1"
        x1="35.4783"
        y1="-2.06179e-07"
        x2="107.705"
        y2="162.517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#151515" />
        <stop offset="1" stopColor="#393939" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_0_1"
        x1="72"
        y1="57"
        x2="72"
        y2="107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBCBCB" stopOpacity="0.51" />
        <stop offset="1" stopColor="white" stopOpacity="0.87" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_0_1"
        x1="72"
        y1="57"
        x2="72"
        y2="107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEDEDE" stopOpacity="0.15" />
        <stop offset="1" stopColor="#D0D0D0" stopOpacity="0.65" />
      </linearGradient>
    </defs>
  </svg>
);

export const add_player_hover = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 144 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_2276_9380)">
      <path
        d="M143.949 140.561C144.065 121.763 143.949 22.4543 143.949 22.4543L128.936 0H15.0979L0.0510493 22.4543C0.0510493 22.4543 -0.064387 121.763 0.0516346 140.561C-0.155655 147.496 24.9876 158.14 73.1075 178C119.639 157.88 142.518 147.523 143.949 140.561Z"
        fill="url(#paint0_radial_2276_9380)"
      />
    </g>
    <path
      d="M1.54978 23.5759L1.55053 22.9112L15.8983 1.5H128.134L142.45 22.9104L142.45 23.5759L142.454 26.7598C142.457 29.5119 142.461 33.4645 142.465 38.2664C142.474 47.8702 142.485 60.8709 142.493 74.4591C142.507 101.48 142.507 130.814 142.45 140.385C142.315 140.896 141.968 141.551 141.267 142.363C140.513 143.237 139.416 144.216 137.93 145.311C134.957 147.501 130.596 150.033 124.76 153.01C113.193 158.908 96.08 166.43 73.0939 176.372C49.3061 166.553 31.2391 159.022 19.1165 153.086C12.9979 150.09 8.47154 147.538 5.50565 145.339C4.02162 144.238 2.99066 143.272 2.34802 142.434C1.70249 141.592 1.5392 140.999 1.55097 140.606L1.55177 140.579L1.55161 140.552C1.49363 131.159 1.49348 101.639 1.50783 74.4591C1.51501 60.8709 1.52582 47.8702 1.53482 38.2664C1.53933 33.4645 1.54339 29.5119 1.54631 26.7598L1.54978 23.5759Z"
      stroke="url(#paint1_linear_2276_9380)"
      strokeWidth="3"
    />
    <g filter="url(#filter1_dd_2276_9380)">
      <path
        d="M97 78.4286V85.5714H75.5714V107H68.4286V85.5714H47V78.4286H68.4286V57H75.5714V78.4286H97Z"
        fill="url(#paint2_linear_2276_9380)"
      />
      <path
        d="M68.4286 85.0714H47.5V78.9286H68.4286H68.9286V78.4286V57.5H75.0714V78.4286V78.9286H75.5714H96.5V85.0714H75.5714H75.0714V85.5714V106.5H68.9286V85.5714V85.0714H68.4286Z"
        stroke="url(#paint3_linear_2276_9380)"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_2276_9380"
        x="0"
        y="0"
        width="154"
        height="188"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="10" dy="10" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2276_9380"
        />
      </filter>
      <filter
        id="filter1_dd_2276_9380"
        x="42"
        y="52"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5" dy="5" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2276_9380"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2276_9380"
          result="effect2_dropShadow_2276_9380"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2276_9380"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_2276_9380"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(74 84.5) rotate(90.7639) scale(150.013 155.191)"
      >
        <stop stopColor="#8A8A8A" />
        <stop offset="0.366447" stopColor="#545151" />
        <stop offset="0.875015" stopColor="#212121" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_2276_9380"
        x1="35.4783"
        y1="-2.06179e-07"
        x2="107.705"
        y2="162.517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#262626" />
        <stop offset="1" stopColor="#484848" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2276_9380"
        x1="72"
        y1="57"
        x2="72"
        y2="107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DAD5D5" />
        <stop offset="1" stopColor="#A7A7A7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2276_9380"
        x1="72"
        y1="57"
        x2="72"
        y2="107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F5F5" />
        <stop offset="1" stopColor="#D0D0D0" />
      </linearGradient>
    </defs>
  </svg>
);

export const sidebar = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 31 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2484_9468)">
      <path
        d="M3 101C13.1204 101 22.2116 94.6813 25.9854 88.8748L24.0985 88.1917C18.781 94.1689 13.8065 97.5845 4.54379 98.7799L3 101Z"
        fill="#191C20"
      />
      <path
        d="M3.01465 2L3 101L4.54379 98.7799L4.55844 4.73243L3.01465 2Z"
        fill="url(#paint0_linear_2484_9468)"
      />
      <path
        d="M25.9854 88.8748L26 11.9051L24.1131 13.6128L24.0985 88.1917L25.9854 88.8748Z"
        fill="url(#paint1_linear_2484_9468)"
      />
      <path
        d="M26 11.9051L3.01465 2L4.55844 4.73243L24.1131 13.6128L26 11.9051Z"
        fill="#4E5863"
      />
      <path
        d="M4.54379 98.7799C13.8065 97.5845 18.781 94.1689 24.0985 88.1917L24.1131 13.6128L4.55844 4.73243L4.54379 98.7799Z"
        fill="url(#paint2_linear_2484_9468)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2484_9468"
        x="0"
        y="0"
        width="31"
        height="107"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2484_9468"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2484_9468"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2484_9468"
        x1="14.477"
        y1="2"
        x2="14.477"
        y2="103.441"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3E444B" />
        <stop offset="1" stopColor="#2D3237" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2484_9468"
        x1="14.5"
        y1="2"
        x2="14.5"
        y2="101"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1D2227" />
        <stop offset="1" stopColor="#191B1E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2484_9468"
        x1="14.5"
        y1="2"
        x2="14.5"
        y2="101"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30343A" />
        <stop offset="1" stopColor="#262A2F" />
      </linearGradient>
    </defs>
  </svg>
);

export const owned = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10 18.1132C14.4808 18.1132 18.1132 14.4808 18.1132 10C18.1132 8.14438 17.4902 6.43427 16.4421 5.06738C15.9427 4.41625 15.3469 3.84301 14.676 3.36902C13.3544 2.43533 11.7412 1.88679 10 1.88679C5.5192 1.88679 1.88679 5.5192 1.88679 10C1.88679 11.7412 2.43533 13.3544 3.36902 14.676C3.84301 15.3469 4.41625 15.9427 5.06738 16.4421C6.43427 17.4902 8.14438 18.1132 10 18.1132Z"
    />
    <path d="M5.91438 8.96538L4.4 10.4798L8.27009 14.35L15.5055 7.11442L13.9911 5.6L8.27009 11.3212L5.91438 8.96538Z" />
  </svg>
);

export const owned_icon_true = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6" stroke="#00FF15" />
    <path
      d="M4.84402 9.10901C4.84402 9.10901 3.88014 8.34431 3.11402 6.62372C2.86821 6.07167 3.49721 5.57242 3.87983 5.85901C4.64536 6.4324 5.60072 7.5796 5.60072 7.5796C7.89484 5.85901 8.08602 5.47666 9.23195 3.56503C9.42417 3.18294 9.99875 3.75678 9.80653 4.33008C9.80653 4.33008 9.42417 6.05066 8.46724 7.19739C7.65264 8.17354 5.98195 9.30033 5.98195 9.30033C5.60064 9.49184 5.25335 9.44568 4.84402 9.10901Z"
      fill="#00FF15"
      stroke="#75FB50"
      strokeOpacity="0.5"
      strokeWidth="0.5"
    />
  </svg>
);

export const untradeable_icon_true = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6" stroke="var(--brand-meta-orange)" />
    <path
      d="M3.72921 4.74232C4.95963 4.74232 6.19005 4.70808 6.29862 4.74232C6.40719 4.77657 6.74867 4.86089 6.51575 5.08474C6.28284 5.3086 5.02322 5.16799 3.69303 5.22177C3.94704 5.46509 4.07092 5.63081 4.58539 5.91906C4.58539 5.91906 3.94999 6.12203 3.65372 5.91906C2.84743 5.36665 2.64312 4.88568 2.67983 4.94778C3.4645 4.12285 3.82772 3.76901 4.5317 3.71513C4.42592 3.9588 4.15362 4.05538 3.72921 4.74232Z"
      fill="var(--brand-meta-orange)"
    />
    <path
      d="M2.98239 9.29647C2.9462 9.33065 2.74891 9.62116 2.9462 9.8101C3.14349 9.99903 3.45284 9.947 3.52522 9.87858C3.5976 9.81016 10.184 3.81792 10.2925 3.71513C10.4011 3.61235 10.4028 3.23645 10.184 3.13303C9.98168 3.03741 9.71351 3.03022 9.60494 3.13303C9.49638 3.23583 3.01858 9.26229 2.98239 9.29647Z"
      fill="var(--brand-meta-orange)"
    />
    <path
      d="M9.50839 7.5277C8.37238 7.5277 7.3658 7.56245 7.26556 7.59619C7.16532 7.62992 6.86957 7.78657 7.08462 8.00709C7.29966 8.22761 8.20788 8.09107 9.43602 8.14405C9.25507 8.38374 9.11486 8.6819 8.63986 8.96585C8.63986 8.96585 9.27095 9.02884 9.54449 8.82889C10.2207 8.3346 10.6526 7.89676 10.7007 7.86647C10.7016 7.86769 10.7025 7.8689 10.7034 7.87012C10.7065 7.86451 10.7056 7.86342 10.7007 7.86647C9.98154 6.89502 9.25284 6.69042 8.60367 6.63742C8.82168 7.15104 9.00262 6.97983 9.50839 7.5277Z"
      fill="var(--brand-meta-orange)"
    />
    <path
      d="M2.98288 9.29647C2.94669 9.33065 2.74941 9.62116 2.94669 9.8101C3.14398 9.99903 3.45334 9.947 3.52571 9.87858C3.59809 9.81016 10.1845 3.81792 10.293 3.71513C10.4016 3.61235 10.4033 3.23645 10.1845 3.13303C9.98217 3.03741 9.71401 3.03022 9.60544 3.13303C9.49687 3.23583 3.01907 9.26229 2.98288 9.29647Z"
      fill="var(--brand-meta-orange)"
    />
  </svg>
);

export const position_mod_icon_true = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6" stroke="#00FBFF" />
    <circle cx="9.94439" cy="8.05182" r="0.764706" fill="#00FBFF" />
    <path
      d="M6.00574 3.84645C5.21168 3.99349 4.28516 4.9935 4.28516 4.9935C6.09605 4.15612 7.04053 4.56624 8.68222 6.14056L7.34398 6.52292C7.1528 6.52328 9.44692 6.90563 9.44692 6.90563C9.44692 6.90563 9.52178 6.15295 9.44692 5.56675C9.33737 4.70891 9.06457 3.92392 9.06457 4.03771V5.56676C9.06457 5.56676 7.43113 3.58249 6.00574 3.84645Z"
      fill="#00FBFF"
    />
    <circle cx="3.06158" cy="5.37603" r="0.764706" fill="#00FBFF" />
  </svg>
);

export const duplicate_icon_true = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6" fill="#282D32" stroke="#FF45B5" />
    <circle cx="6.5" cy="6.5" r="6" stroke="#FF45B5" />
    <path
      d="M2.34304 8.82336H8.20545C8.20545 8.82336 8.35909 6.58998 7.4238 5.69622C7.15603 5.44033 7.20437 5.14437 7.20437 5.14437C7.20437 5.14437 7.14976 4.25485 7.03292 3.67277C6.86229 2.82278 6.19314 2.20117 5.27421 2.20117C4.35528 2.20117 3.68612 2.82278 3.51551 3.67277C3.39867 4.25486 3.90639 5.14437 3.90639 5.14437C3.90639 5.14437 3.39252 5.44033 3.12475 5.69622C2.18946 6.58998 2.34304 8.82336 2.34304 8.82336Z"
      fill="#FF45B5"
      stroke="#282C39"
      strokeWidth="0.8"
    />
    <path
      d="M4.6282 9.73743H10.4906C10.4906 9.73743 10.6442 7.50405 9.70896 6.61028C9.44119 6.3544 8.92731 6.05843 8.92731 6.05843C8.92731 6.05843 9.43492 5.16891 9.31807 4.58683C9.14744 3.73685 8.4783 3.11523 7.55937 3.11523C6.64044 3.11523 5.97127 3.73684 5.80066 4.58683C5.68383 5.16892 6.19155 6.05843 6.19155 6.05843C6.19155 6.05843 5.67768 6.3544 5.4099 6.61028C4.47461 7.50405 4.6282 9.73743 4.6282 9.73743Z"
      fill="#FF45B5"
      stroke="#282C39"
      strokeWidth="0.8"
    />
    <circle cx="6.5" cy="6.5" r="6" stroke="#FF45B5" />
  </svg>
);

export const owned_icon_false = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6" stroke="#3A566C" />
    <path
      d="M4.84402 9.10901C4.84402 9.10901 3.88014 8.34431 3.11402 6.62372C2.86821 6.07167 3.49721 5.57242 3.87983 5.85901C4.64536 6.4324 5.60072 7.5796 5.60072 7.5796C7.89484 5.85901 8.08602 5.47666 9.23195 3.56503C9.42417 3.18294 9.99875 3.75678 9.80653 4.33008C9.80653 4.33008 9.42417 6.05066 8.46724 7.19739C7.65264 8.17354 5.98195 9.30033 5.98195 9.30033C5.60064 9.49184 5.25335 9.44568 4.84402 9.10901Z"
      fill="#3A566C"
      stroke="#3A566C"
      strokeOpacity="0.5"
      strokeWidth="0.5"
    />
  </svg>
);

export const untradeable_icon_false = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6" stroke="#3A566C" />
    <path
      d="M3.72921 4.74232C4.95963 4.74232 6.19005 4.70808 6.29862 4.74232C6.40719 4.77657 6.74867 4.86089 6.51575 5.08474C6.28284 5.3086 5.02322 5.16799 3.69303 5.22177C3.94704 5.46509 4.07092 5.63081 4.58539 5.91906C4.58539 5.91906 3.94999 6.12203 3.65372 5.91906C2.84743 5.36665 2.64312 4.88568 2.67983 4.94778C3.4645 4.12285 3.82772 3.76901 4.5317 3.71513C4.42592 3.9588 4.15362 4.05538 3.72921 4.74232Z"
      fill="#3A566C"
    />
    <path
      d="M2.98239 9.29647C2.9462 9.33065 2.74891 9.62116 2.9462 9.8101C3.14349 9.99903 3.45284 9.947 3.52522 9.87858C3.5976 9.81016 10.184 3.81792 10.2925 3.71513C10.4011 3.61235 10.4028 3.23645 10.184 3.13303C9.98168 3.03741 9.71351 3.03022 9.60494 3.13303C9.49638 3.23583 3.01858 9.26229 2.98239 9.29647Z"
      fill="#3A566C"
    />
    <path
      d="M9.50839 7.5277C8.37238 7.5277 7.3658 7.56245 7.26556 7.59619C7.16532 7.62992 6.86957 7.78657 7.08462 8.00709C7.29966 8.22761 8.20788 8.09107 9.43602 8.14405C9.25507 8.38374 9.11486 8.6819 8.63986 8.96585C8.63986 8.96585 9.27095 9.02884 9.54449 8.82889C10.2207 8.3346 10.6526 7.89676 10.7007 7.86647C10.7016 7.86769 10.7025 7.8689 10.7034 7.87012C10.7065 7.86451 10.7056 7.86342 10.7007 7.86647C9.98154 6.89502 9.25284 6.69042 8.60367 6.63742C8.82168 7.15104 9.00262 6.97983 9.50839 7.5277Z"
      fill="#3A566C"
    />
    <path
      d="M2.98288 9.29647C2.94669 9.33065 2.74941 9.62116 2.94669 9.8101C3.14398 9.99903 3.45334 9.947 3.52571 9.87858C3.59809 9.81016 10.1845 3.81792 10.293 3.71513C10.4016 3.61235 10.4033 3.23645 10.1845 3.13303C9.98217 3.03741 9.71401 3.03022 9.60544 3.13303C9.49687 3.23583 3.01907 9.26229 2.98288 9.29647Z"
      fill="#3A566C"
    />
  </svg>
);

export const position_mod_icon_false = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6" stroke="#3A566C" />
    <circle cx="9.94439" cy="8.05182" r="0.764706" fill="#3A566C" />
    <path
      d="M6.00574 3.84645C5.21168 3.99349 4.28516 4.9935 4.28516 4.9935C6.09605 4.15612 7.04053 4.56624 8.68222 6.14056L7.34398 6.52292C7.1528 6.52328 9.44692 6.90563 9.44692 6.90563C9.44692 6.90563 9.52178 6.15295 9.44692 5.56675C9.33737 4.70891 9.06457 3.92392 9.06457 4.03771V5.56676C9.06457 5.56676 7.43113 3.58249 6.00574 3.84645Z"
      fill="#3A566C"
    />
    <circle cx="3.06158" cy="5.37603" r="0.764706" fill="#3A566C" />
  </svg>
);

export const duplicate_icon_false = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6" fill="#282D32" stroke="#3A566C" />
    <circle cx="6.5" cy="6.5" r="6" stroke="#3A566C" />
    <path
      d="M2.34304 8.82336H8.20545C8.20545 8.82336 8.35909 6.58998 7.4238 5.69622C7.15603 5.44033 7.20437 5.14437 7.20437 5.14437C7.20437 5.14437 7.14976 4.25485 7.03292 3.67277C6.86229 2.82278 6.19314 2.20117 5.27421 2.20117C4.35528 2.20117 3.68612 2.82278 3.51551 3.67277C3.39867 4.25486 3.90639 5.14437 3.90639 5.14437C3.90639 5.14437 3.39252 5.44033 3.12475 5.69622C2.18946 6.58998 2.34304 8.82336 2.34304 8.82336Z"
      fill="#3A566C"
      stroke="#282C39"
      strokeWidth="0.8"
    />
    <path
      d="M4.6282 9.73743H10.4906C10.4906 9.73743 10.6442 7.50405 9.70896 6.61028C9.44119 6.3544 8.92731 6.05843 8.92731 6.05843C8.92731 6.05843 9.43492 5.16891 9.31807 4.58683C9.14744 3.73685 8.4783 3.11523 7.55937 3.11523C6.64044 3.11523 5.97127 3.73684 5.80066 4.58683C5.68383 5.16892 6.19155 6.05843 6.19155 6.05843C6.19155 6.05843 5.67768 6.3544 5.4099 6.61028C4.47461 7.50405 4.6282 9.73743 4.6282 9.73743Z"
      fill="#3A566C"
      stroke="#282C39"
      strokeWidth="0.8"
    />
    <circle cx="6.5" cy="6.5" r="6" stroke="#3A566C" />
  </svg>
);

export const arrow = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0607 0.93934C12.4749 0.353553 11.5251 0.353553 10.9393 0.93934L1.3934 10.4853C0.807611 11.0711 0.807611 12.0208 1.3934 12.6066C1.97919 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.93934ZM13.5 4V2H10.5V4H13.5Z"
      fill="currentColor"
    />
  </svg>
);

export const elipsis = (
  <svg
    width="100%"
    height="24"
    viewBox="0 0 17 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.24245 4.14205C1.80448 4.14205 1.42865 3.9852 1.11497 3.67152C0.801284 3.35784 0.644443 2.98201 0.644443 2.54403C0.644443 2.10606 0.801284 1.73023 1.11497 1.41655C1.42865 1.10286 1.80448 0.946023 2.24245 0.946023C2.68043 0.946023 3.05626 1.10286 3.36994 1.41655C3.68362 1.73023 3.84047 2.10606 3.84047 2.54403C3.84047 2.83404 3.76648 3.10038 3.61852 3.34304C3.47647 3.5857 3.28412 3.78101 3.04146 3.92898C2.80472 4.07102 2.53838 4.14205 2.24245 4.14205ZM8.50222 4.14205C8.06425 4.14205 7.68842 3.9852 7.37473 3.67152C7.06105 3.35784 6.90421 2.98201 6.90421 2.54403C6.90421 2.10606 7.06105 1.73023 7.37473 1.41655C7.68842 1.10286 8.06425 0.946023 8.50222 0.946023C8.94019 0.946023 9.31602 1.10286 9.62971 1.41655C9.94339 1.73023 10.1002 2.10606 10.1002 2.54403C10.1002 2.83404 10.0262 3.10038 9.87828 3.34304C9.73624 3.5857 9.54389 3.78101 9.30123 3.92898C9.06448 4.07102 8.79815 4.14205 8.50222 4.14205ZM14.762 4.14205C14.324 4.14205 13.9482 3.9852 13.6345 3.67152C13.3208 3.35784 13.164 2.98201 13.164 2.54403C13.164 2.10606 13.3208 1.73023 13.6345 1.41655C13.9482 1.10286 14.324 0.946023 14.762 0.946023C15.2 0.946023 15.5758 1.10286 15.8895 1.41655C16.2032 1.73023 16.36 2.10606 16.36 2.54403C16.36 2.83404 16.286 3.10038 16.1381 3.34304C15.996 3.5857 15.8037 3.78101 15.561 3.92898C15.3242 4.07102 15.0579 4.14205 14.762 4.14205Z"
      fill="#04B1FF"
    />
  </svg>
);

export const importClub = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 546 636"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M546 483.941C546 567.54 423.774 635.311 273 635.311C122.226 635.311 0 567.54 0 483.941C0 430.479 49.9858 383.49 125.458 356.559L143.03 374.517C102.084 395.828 76.249 427.044 76.249 461.826C76.249 526.053 164.334 578.118 272.992 578.118C381.65 578.118 469.735 526.053 469.735 461.826C469.735 427.013 443.855 395.773 402.848 374.461L420.414 356.514C495.958 383.437 546 430.449 546 483.941ZM272.886 538.72C355.896 538.72 423.19 501.408 423.19 455.381C423.19 432.763 406.939 412.249 380.558 397.233L366.811 411.279C375.973 420.676 381.215 431.578 381.215 443.203C381.215 478.564 332.719 507.229 272.895 507.229C213.072 507.229 164.576 478.564 164.576 443.203C164.576 431.573 169.822 420.667 178.992 411.267L165.242 397.216C138.844 412.234 122.582 432.754 122.582 455.381C122.582 501.408 189.875 538.72 272.886 538.72Z"
      fill="currentColor"
    />
    <path
      d="M190.807 -0.000579834H355.026V249.263H475.454L272.916 455.176L70.3789 249.263H190.807L190.807 -0.000579834Z"
      fill="currentColor"
    />
  </svg>
);

export const detectEvos = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6425 16.0886C16.6019 16.0886 16.5761 16.132 16.5954 16.1678C16.9504 16.8218 17.2062 17.1899 17.8818 17.6901C17.891 17.697 17.9024 17.7008 17.9139 17.7008H27.8382C27.8382 17.7008 28.6443 17.7008 28.6443 16.8947C28.6443 16.0886 27.8382 16.0886 27.8382 16.0886H16.6425Z"
      fill="white"
    />
    <path
      d="M14.8562 10.8484C14.828 10.8487 14.8048 10.8707 14.8031 10.8989C14.7723 11.4215 14.7815 11.7379 14.9297 12.2871C14.936 12.3104 14.9572 12.3267 14.9814 12.3267H29.7191C29.7191 12.3267 30.5252 12.3267 30.5252 11.5206C30.5252 10.7145 29.7191 10.7145 29.7191 10.7145L14.8562 10.8484Z"
      fill="white"
    />
    <path
      d="M29.4503 8.69924L32.4061 7.22137C32.4892 14.0429 31.8316 17.4613 27.0321 21.4626L23.942 19.9847C24.8362 19.3883 25.5906 18.8115 26.226 18.2346L27.8382 18.2382C27.8382 18.2382 29.1817 18.2382 29.1817 16.8947C29.1817 16.0915 28.7016 15.7685 28.3154 15.6386C28.771 14.7857 29.0582 13.8781 29.2307 12.8641H29.7191C29.7191 12.8641 31.0626 12.8641 31.0626 11.5206C31.0626 10.1771 29.7191 10.1771 29.7191 10.1771H29.4526C29.4614 9.7074 29.4596 9.21576 29.4503 8.69924Z"
      fill="white"
    />
    <path
      d="M16.1496 22.5374C16.7645 23.0063 17.1569 23.2279 18.0305 23.4779C18.5158 23.5927 18.8058 23.7039 19.374 24.0153C18.3154 24.5446 17.443 25.113 16.7276 25.7618H15.2092C15.2092 25.7618 13.8657 25.7618 13.8657 27.1053C13.8657 27.954 14.4825 28.2666 14.838 28.3817C14.4979 29.1503 14.2501 30.0147 14.0757 31.0015H13.4626C13.4626 31.0015 12.1191 31.0015 12.1191 32.345C12.1191 33.6886 13.4626 33.6886 13.4626 33.6886H13.7843C13.7582 34.1587 13.7409 34.6508 13.7313 35.1664L10.9099 36.6443C10.5069 30.0611 11.5817 26.0305 16.1496 22.5374Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5968 5.87786L10.9098 4.4C10.2381 15.1481 12.5222 21.7313 21.255 23.3435C22.2798 23.5327 23.1877 23.801 23.9899 24.1466C24.6032 23.3127 25.3705 22.5991 26.2499 22.0477C25.0169 21.42 23.5813 20.9667 21.9267 20.6565C14.6718 19.2962 13.3283 13.5359 13.5968 5.87786ZM32.4694 36.986C31.8689 37.126 31.2431 37.2 30.6 37.2C30.2234 37.2 29.8528 37.1746 29.4898 37.1255C29.4798 37.4524 29.4666 37.7847 29.4503 38.1221L32.4061 39.6C32.4457 38.6895 32.4671 37.8186 32.4694 36.986ZM37.9993 29C37.9993 32.4424 35.6489 35.3359 32.4653 36.1628C31.8693 36.3176 31.2441 36.4 30.5997 36.4C30.2286 36.4 29.8639 36.3727 29.5074 36.32C27.4163 36.0106 25.6102 34.8263 24.473 33.1511C24.1354 32.6537 23.8567 32.1129 23.647 31.5389C23.3578 30.7471 23.2 29.892 23.2 29C23.2 28.6302 23.2271 28.2667 23.2795 27.9115C23.3621 27.3511 23.5075 26.8113 23.7083 26.2992C23.963 25.6498 24.3068 25.0451 24.7247 24.5002C25.3522 23.6822 26.1466 22.999 27.0571 22.5016C28.1092 21.9267 29.3163 21.6 30.5997 21.6C34.6864 21.6 37.9993 24.9131 37.9993 29ZM22.8006 31.5389H13.4626C13.4626 31.5389 12.6565 31.5389 12.6565 32.345C12.6565 33.1511 13.4626 33.1511 13.4626 33.1511H23.5268C23.2297 32.6459 22.9851 32.106 22.8006 31.5389ZM22.8552 26.2992C22.6753 26.8151 22.5455 27.3545 22.4716 27.9115H15.2092C15.2092 27.9115 14.4031 27.9115 14.4031 27.1053C14.4031 26.2992 15.2092 26.2992 15.2092 26.2992H22.8552ZM27.0039 28.2989L25.6 29.6935L29.1878 33.2572L35.8954 26.5945L34.4915 25.2L29.1878 30.4682L27.0039 28.2989Z"
      fill="white"
    />
    <path
      d="M2.6 44C1.16406 44 0 42.8359 0 41.4V36H2.40078V41.5996H8V44H2.6Z"
      fill="white"
    />
    <path
      d="M2.6 0C1.16406 0 0 1.16406 0 2.6V8H2.40078V2.39961H8V0H2.6Z"
      fill="white"
    />
    <path
      d="M41.4 0C42.8359 0 44 1.16406 44 2.6V8H41.6008V2.39961H36V0H41.4Z"
      fill="white"
    />
    <path
      d="M44 41.4C44 42.8359 42.8359 44 41.4 44H36V41.5996H41.6008V36H44V41.4Z"
      fill="white"
    />
  </svg>
);

export const tradeable = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 43 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="21.0005" cy="20.0742" rx="20.1099" ry="19.2188" fill="none" />
    <path
      d="M42.901 20.5C42.901 31.8218 33.2973 41 21.4505 41C9.60372 41 0 31.8218 0 20.5C0 9.17816 9.60372 0 21.4505 0C33.2973 0 42.901 9.17816 42.901 20.5ZM4.38635 20.5C4.38635 29.5067 12.0262 36.808 21.4505 36.808C30.8748 36.808 38.5147 29.5067 38.5147 20.5C38.5147 11.4933 30.8748 4.19198 21.4505 4.19198C12.0262 4.19198 4.38635 11.4933 4.38635 20.5Z"
      fill="url(#paint0_linear_2720_9270)"
    />
    <path
      d="M29.497 24.189C24.2734 24.189 19.645 24.3525 19.1841 24.5112C18.7232 24.6699 17.3633 25.407 18.3521 26.4445C19.3409 27.4821 23.517 26.8397 29.1642 27.089C28.3322 28.2168 27.6874 29.6197 25.5033 30.9557C25.5033 30.9557 28.4052 31.2521 29.6629 30.3113C32.7721 27.9856 34.7581 25.9254 34.9794 25.7829C34.9835 25.7886 34.9876 25.7944 34.9918 25.8001C35.006 25.7737 35.0017 25.7685 34.9794 25.7829C31.6726 21.2121 28.3219 20.2494 25.3369 20C26.3394 22.4167 27.1714 21.6111 29.497 24.189Z"
      fill="url(#paint1_linear_2720_9270)"
    />
    <path
      d="M11.8377 15.4775C17.4881 15.4775 23.1386 15.3282 23.6372 15.4775C24.1357 15.6267 25.7039 15.9943 24.6343 16.97C23.5647 17.9458 17.7801 17.3329 11.6715 17.5673C12.838 18.628 13.4069 19.3503 15.7695 20.6068C15.7695 20.6068 12.8515 21.4915 11.491 20.6068C7.78824 18.1989 6.85002 16.1023 7.01858 16.373C10.6221 12.7772 12.29 11.2348 15.5229 11C15.0372 12.0621 13.7867 12.4831 11.8377 15.4775Z"
      fill="url(#paint2_linear_2720_9270)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2720_9270"
        x1="21.4505"
        y1="0"
        x2="21.4505"
        y2="41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01DFFF" />
        <stop offset="1" stopColor="#0196FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2720_9270"
        x1="26.5"
        y1="20"
        x2="26.5"
        y2="31"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01DFFF" />
        <stop offset="1" stopColor="#0196FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2720_9270"
        x1="16"
        y1="11"
        x2="16"
        y2="21"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01DFFF" />
        <stop offset="1" stopColor="#0196FF" />
      </linearGradient>
    </defs>
  </svg>
);

export const close_enhanced_view = (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.77817 19.3348L19.3345 3.77847M19.3345 19.3348L3.77817 3.77847"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="square"
    />
  </svg>
);

export const close_enhanced_view_hover = (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2908_9257)">
      <path
        d="M8.77817 23.3348L24.3345 7.77847M24.3345 23.3348L8.77817 7.77847"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2908_9257"
        x="0.953125"
        y="0.949219"
        width="31.2109"
        height="31.2148"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.690196 0 0 0 0 0.792157 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2908_9257"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2908_9257"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const player_bin_icon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.03214 24.5544L5.00962 12.8801M9.39181 12.8801L9.24574 24.3988M12.8976 24.5544L13.6279 12.8801M5.59392 27.9789C3.11068 27.9789 2.52638 25.644 2.52638 25.644C2.52638 25.644 0.627436 9.61133 1.06565 9.61133H17.4258C18.0101 9.61133 15.9651 25.644 15.9651 25.644C15.9651 25.644 15.5269 27.9789 14.2122 27.9789H5.59392Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M12.1316 3.48792C12.1316 3.48792 13.9755 3.50284 15.7559 3.50226C17.5363 3.50168 17.7127 6.60684 17.5383 6.61055C17.3638 6.61426 1.40289 6.60325 1.14747 6.6069C0.892046 6.61055 1.15109 3.5025 2.93012 3.50246C4.70915 3.50243 6.49275 3.49888 6.49275 3.49888M12.1316 3.48792C12.1316 3.48792 12.4683 1.17261 11.7207 1.06798C10.9731 0.963356 7.93435 0.99254 7.08827 1.06779C6.24219 1.14304 6.49275 3.49888 6.49275 3.49888M12.1316 3.48792L6.49275 3.49888"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const player_bin_icon_hover = (
  <svg
    width="29"
    height="41"
    viewBox="0 0 29 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2910_9263)">
      <path
        d="M11.0321 31.5759L10.0096 19.9016M14.3918 19.9016L14.2457 31.4203M17.8976 31.5759L18.6279 19.9016M10.5939 35.0004C8.11068 35.0004 7.52638 32.6655 7.52638 32.6655C7.52638 32.6655 5.62744 16.6328 6.06565 16.6328H22.4258C23.0101 16.6328 20.9651 32.6655 20.9651 32.6655C20.9651 32.6655 20.5269 35.0004 19.2122 35.0004H10.5939Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M18.0636 9.62642C18.0636 9.62642 19.919 10.0183 21.7135 10.3819C23.508 10.7456 22.7899 13.7022 22.6134 13.6702C22.4369 13.6382 6.35284 10.3622 6.09471 10.3135C5.83657 10.2649 6.99404 7.39443 8.78704 7.75833C10.58 8.12223 12.3783 8.48363 12.3783 8.48363M18.0636 9.62642C18.0636 9.62642 18.8556 7.42443 18.1226 7.16887C17.3895 6.91331 14.3213 6.3203 13.4538 6.22102C12.5864 6.12174 12.3783 8.48363 12.3783 8.48363M18.0636 9.62642L12.3783 8.48363"
        stroke="currentColor"
        strokeWidth="2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2910_9263"
        x="0"
        y="0.248047"
        width="28.9375"
        height="40.752"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.690196 0 0 0 0 0.792157 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2910_9263"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2910_9263"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export const transferList = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 719 688"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M719 343.57C719 533.318 558.046 687.14 359.5 687.14C160.954 687.14 0 533.318 0 343.57C0 153.821 160.954 0 359.5 0C558.046 0 719 153.821 719 343.57ZM73.5131 343.57C73.5131 494.517 201.554 616.884 359.5 616.884C517.446 616.884 645.487 494.517 645.487 343.57C645.487 192.623 517.446 70.2556 359.5 70.2556C201.554 70.2556 73.5131 192.623 73.5131 343.57Z"
      fill="url(#paint0_linear_2802_11058)"
    />
    <path
      d="M329.343 392.654V446.113H203.077V486.569L134.07 418.661L203.077 352.198V392.654H329.343Z"
      fill="url(#paint1_linear_2802_11058)"
    />
    <path
      d="M229.504 474.885V528.926H355.771V569.822L424.777 501.176L355.771 433.99V474.885H229.504Z"
      fill="url(#paint2_linear_2802_11058)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M357.113 364.705H230.555V164.592C230.555 157.088 236.82 150.834 244.338 150.834H522.515C530.033 150.834 536.299 157.088 536.299 164.592V478.52C536.299 486.024 529.86 492.278 522.342 492.278H456.103L357.113 397.224V364.705ZM280.677 215.871V187.334H380.921V215.871H280.677ZM280.677 275.762V247.226H486.177V275.762H280.677ZM280.677 336.408V307.872H486.177V336.408H280.677ZM407.235 400.236V371.699H486.177V400.236H407.235Z"
      fill="url(#paint3_linear_2802_11058)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2802_11058"
        x1="359.5"
        y1="0"
        x2="359.5"
        y2="687.14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CE52FF" />
        <stop offset="1" stopColor="#634AFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2802_11058"
        x1="335.184"
        y1="150.834"
        x2="335.184"
        y2="569.822"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CE52FF" />
        <stop offset="1" stopColor="#634AFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2802_11058"
        x1="335.184"
        y1="150.834"
        x2="335.184"
        y2="569.822"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CE52FF" />
        <stop offset="1" stopColor="#634AFF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2802_11058"
        x1="335.184"
        y1="150.834"
        x2="335.184"
        y2="569.822"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CE52FF" />
        <stop offset="1" stopColor="#634AFF" />
      </linearGradient>
    </defs>
  </svg>
);

export const plus = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 17.5H31M17 32V3"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="square"
    />
  </svg>
);

export const roundedPlus = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.57876 9.44326C0.957896 9.44326 0.45459 8.94005 0.45459 8.31929V8.24138C0.45459 7.62063 0.957896 7.11741 1.57876 7.11741H15.6114C16.2323 7.11741 16.7356 7.62063 16.7356 8.24138V8.31929C16.7356 8.94005 16.2323 9.44326 15.6114 9.44326H1.57876Z"
      fill="white"
    />
    <path
      d="M7.43227 1.26435C7.43227 0.643599 7.93557 0.140381 8.55643 0.140381H8.63396C9.25481 0.140381 9.75812 0.6436 9.75812 1.26435V15.2974C9.75812 15.9182 9.25482 16.4214 8.63396 16.4214H8.55643C7.93557 16.4214 7.43227 15.9182 7.43227 15.2974V1.26435Z"
      fill="white"
    />
  </svg>
);

export const manually_added_icon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5013" cy="6.49935" r="5.83333" fill="#282C39" />
    <path
      d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5ZM1.32917 6.5C1.32917 9.35577 3.64423 11.6708 6.5 11.6708C9.35577 11.6708 11.6708 9.35577 11.6708 6.5C11.6708 3.64423 9.35577 1.32917 6.5 1.32917C3.64423 1.32917 1.32917 3.64423 1.32917 6.5Z"
      fill="url(#paint0_linear_3044_9271)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29219 2.36719H7.70647V5.29219H10.6315V7.70647H7.70647V10.6315H5.29219V7.70647H2.36719V5.29219H5.29219V2.36719ZM7.01004 3.06362H5.98862V5.98862H3.06362V7.01004H5.98862V9.93504H7.01004V7.01004H9.93504V5.98862H7.01004V3.06362Z"
      fill="url(#paint1_linear_3044_9271)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3044_9271"
        x1="6.5"
        y1="0"
        x2="6.5"
        y2="13"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01FFA5" />
        <stop offset="1" stopColor="#01D0E9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3044_9271"
        x1="6.49933"
        y1="2.36719"
        x2="6.49933"
        y2="10.6315"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01FFA5" />
        <stop offset="1" stopColor="#01D0E9" />
      </linearGradient>
    </defs>
  </svg>
);
export const chemistryLogo = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 115 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.4362 26.0547L58.9706 1.52037L83.505 26.0547L58.9706 50.5891L34.4362 26.0547Z"
      fill="var(--brand-light-blue)"
    />
    <path
      d="M34.4362 26.0547L58.9706 1.52037L55.9367 1.52031L31.4023 26.0547L34.4362 26.0547Z"
      fill="#8CE3FF"
    />
    <path
      d="M58.9706 50.5891L34.4362 26.0547L31.4023 26.0547L55.9367 50.5891L58.9706 50.5891Z"
      fill="#0088B3"
    />
    <path
      d="M65.1413 56.7579L89.6757 32.2235L114.21 56.7579L89.6757 81.2922L65.1413 56.7579Z"
      fill="var(--brand-light-blue)"
    />
    <path
      d="M65.1413 56.7579L89.6757 32.2235L86.6418 32.2234L62.1074 56.7578L65.1413 56.7579Z"
      fill="#8CE3FF"
    />
    <path
      d="M89.6757 81.2922L65.1413 56.7579L62.1074 56.7578L86.6418 81.2922L89.6757 81.2922Z"
      fill="#0088B3"
    />
    <path
      d="M3.03389 57.4532L27.5683 32.9188L52.1026 57.4532L27.5683 81.9875L3.03389 57.4532Z"
      fill="var(--brand-light-blue)"
    />
    <path
      d="M3.03389 57.4532L27.5683 32.9188L24.5344 32.9187L0 57.4531L3.03389 57.4532Z"
      fill="#8CE3FF"
    />
    <path
      d="M27.5683 81.9875L3.03389 57.4532L0 57.4531L24.5344 81.9875L27.5683 81.9875Z"
      fill="#0088B3"
    />
  </svg>
);

export const filterIconForPosition = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 35 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1042 0.00384712C29.6741 0.00384712 35 5.3301 35 11.9004C35 18.4706 29.6741 23.7969 23.1042 23.7969H11.2084H2.76893C2.3627 23.7969 2.19045 23.1843 2.52452 22.9532C4.7707 21.3992 9 17.7028 9 11.9004C9 6.12254 4.18633 2.4329 1.59981 0.867395C1.24262 0.651203 1.40534 0.00384712 1.82286 0.00384712H11.2084H23.1042Z"
      stroke="none"
    />
    <path
      d="M13 5V6.76296L17.8421 12.4667V15.8889L22.0526 19V12.4667L27 6.76296V5.00019L13 5Z"
      strokeWidth="2"
    />
  </svg>
);

export const filterIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1V2.94912L6.105 9.2551V13.0387L10.5441 16.4783V9.2551L15.7601 2.94912V1.00021L1 1Z"
      strokeWidth="1.59733"
    />
  </svg>
);

export const playerRecommendationIcon2 = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="10.2539"
      cy="10.245"
      rx="10.2539"
      ry="10.245"
      transform="matrix(0.906526 0.422151 -0.423086 0.90609 9.19922 0.265625)"
      fill="#292929"
    />
    <path
      d="M23.7597 14.0757C23.7597 19.3899 19.4453 23.7005 14.1199 23.7005C8.79454 23.7005 4.48011 19.3899 4.48011 14.0757C4.48011 8.76146 8.79454 4.45081 14.1199 4.45081C19.4453 4.45081 23.7597 8.76146 23.7597 14.0757Z"
      stroke="url(#paint0_radial_1380_1145)"
      strokeOpacity="0.75"
      strokeWidth="2.21413"
    />
    <g filter="url(#filter0_f_1380_1145)">
      <ellipse
        cx="0.962095"
        cy="0.960937"
        rx="0.962095"
        ry="0.960937"
        transform="matrix(0.966016 -0.258484 0.259155 0.965836 22.2617 9.03906)"
        fill="url(#paint1_radial_1380_1145)"
      />
    </g>
    <ellipse
      cx="14.1201"
      cy="14.0756"
      rx="8.14155"
      ry="8.13024"
      stroke="url(#paint2_radial_1380_1145)"
      strokeOpacity="0.75"
      strokeWidth="1.87349"
    />
    <g filter="url(#filter1_f_1380_1145)">
      <ellipse
        cx="0.659491"
        cy="0.658693"
        rx="0.659491"
        ry="0.658693"
        transform="matrix(0.966016 -0.258482 0.259157 0.965835 20.4863 16.3281)"
        fill="url(#paint3_radial_1380_1145)"
      />
    </g>
    <g filter="url(#filter2_di_1380_1145)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2384 14.1467C19.2384 14.4726 19.2079 14.7915 19.1496 15.1005H15.0783V19.173C14.7689 19.2315 14.4496 19.2621 14.1231 19.2621C13.7967 19.2621 13.4774 19.2315 13.1679 19.173V15.1005H9.09659C9.0383 14.7915 9.00781 14.4726 9.00781 14.1467C9.00781 13.8207 9.0383 13.5018 9.09659 13.1928H13.1679V9.12028C13.4774 9.06183 13.7967 9.03125 14.1231 9.03125C14.4496 9.03125 14.7689 9.06183 15.0783 9.12028V13.1928H19.1496C19.2079 13.5018 19.2384 13.8207 19.2384 14.1467Z"
        fill="url(#paint4_linear_1380_1145)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_1380_1145"
        x="22.3082"
        y="8.58749"
        width="2.26446"
        height="2.26251"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.0851588"
          result="effect1_foregroundBlur_1380_1145"
        />
      </filter>
      <filter
        id="filter1_f_1380_1145"
        x="20.5496"
        y="16.0477"
        width="1.48868"
        height="1.49063"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.0425794"
          result="effect1_foregroundBlur_1380_1145"
        />
      </filter>
      <filter
        id="filter2_di_1380_1145"
        x="7.2282"
        y="7.25164"
        width="13.7897"
        height="13.7936"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.889806" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.486 0 0 0 0 0 0 0 0 0.75 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1380_1145"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1380_1145"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.136893" dy="0.41068" />
        <feGaussianBlur stdDeviation="0.273787" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.54 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1380_1145"
        />
      </filter>
      <radialGradient
        id="paint0_radial_1380_1145"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.1199 14.0757) rotate(90) scale(10.7319 10.7469)"
      >
        <stop
          offset="0.922664"
          stopColor="var(--brand-meta-orange)"
          stopOpacity="0"
        />
        <stop offset="0.94016" stopColor="#FFA725" />
        <stop offset="0.957501" stopColor="#FFD515" />
        <stop offset="0.975569" stopColor="#FFA200" />
        <stop
          offset="0.98901"
          stopColor="var(--brand-meta-orange)"
          stopOpacity="0"
        />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1380_1145"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0.962095 0.960937) rotate(90) scale(0.960937 0.962095)"
      >
        <stop stopColor="var(--brand-meta-orange)" />
        <stop offset="0.5625" stopColor="#FF8100" />
        <stop offset="1" stopColor="#FFB23E" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1380_1145"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.1201 14.0756) rotate(90) scale(8.13024 8.14155)"
      >
        <stop offset="0.897582" stopColor="#FF7600" stopOpacity="0" />
        <stop offset="0.958345" stopColor="#FFC900" />
        <stop offset="0.988887" stopColor="#FF9E00" />
        <stop offset="1" stopColor="var(--brand-meta-orange)" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_1380_1145"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0.659491 0.658693) rotate(90) scale(0.658693 0.659491)"
      >
        <stop stopColor="var(--brand-meta-orange)" />
        <stop offset="0.5625" stopColor="#FF8100" />
        <stop offset="1" stopColor="#FFB23E" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_1380_1145"
        x1="14.1231"
        y1="9.03125"
        x2="14.1231"
        y2="19.2621"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.445395" stopColor="#FFC400" />
        <stop offset="0.865369" stopColor="#FF9000" />
      </linearGradient>
    </defs>
  </svg>
);

export const metaRatingLogoPlain = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4375 9.30625C18.4375 14.4459 14.3101 18.6125 9.21875 18.6125C4.12738 18.6125 0 14.4459 0 9.30625C0 4.16655 4.12738 0 9.21875 0C14.3101 0 18.4375 4.16655 18.4375 9.30625ZM0.875782 9.30625C0.875782 13.9577 4.61106 17.7284 9.21875 17.7284C13.8264 17.7284 17.5617 13.9577 17.5617 9.30625C17.5617 4.65482 13.8264 0.884094 9.21875 0.884094C4.61106 0.884094 0.875782 4.65482 0.875782 9.30625Z"
      fill="#FF7A00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25806 7.60697C9.96181 7.60697 10.6496 7.53809 11.3152 7.40665C12.9039 7.09291 14.3662 6.42273 15.6169 5.482C15.6348 5.46855 15.6526 5.45504 15.6704 5.44148C15.6659 5.4347 15.6613 5.42794 15.6568 5.42121C15.1892 4.72455 14.811 4.26001 14.3243 3.82357C14.0641 3.59034 13.773 3.36514 13.4205 3.11683C13.3877 3.09372 13.3544 3.07041 13.3205 3.04688C12.1565 3.82662 10.7599 4.28085 9.25806 4.28085C7.76483 4.28085 6.37553 3.83178 5.21552 3.06017C5.17547 3.08477 5.13618 3.10922 5.09759 3.13357C4.59551 3.45046 4.21219 3.75181 3.8224 4.17164C3.52161 4.49561 3.21695 4.89013 2.85089 5.41677C2.84657 5.423 2.84223 5.42924 2.83789 5.4355C2.85472 5.44835 2.87159 5.46115 2.8885 5.4739C4.14058 6.41816 5.60537 7.09098 7.19723 7.40592C7.86398 7.53784 8.55302 7.60697 9.25806 7.60697Z"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1177 9.44568C11.1177 8.9289 11.155 8.62339 11.227 8.12633C12.5712 7.90637 13.8425 7.26378 15.0038 6.64153C14.6424 7.5055 14.443 8.45255 14.443 9.44568C14.443 10.9359 14.892 12.3224 15.6634 13.4801C15.069 14.4462 14.5637 14.97 13.2887 15.853C11.926 14.0714 11.1177 11.8519 11.1177 9.44568ZM7.28651 8.13037C5.94427 7.91357 4.67431 7.27482 3.5136 6.65686C3.8732 7.52098 4.07158 8.46786 4.07158 9.46067C4.07158 10.9543 3.62262 12.3439 2.85118 13.5041C3.44562 14.4724 3.95095 14.9974 5.22596 15.8823C6.58864 14.0967 7.39694 11.8723 7.39694 9.46067C7.39694 8.94012 7.35928 8.63095 7.28651 8.13037Z"
      fill="#F2F2F2"
    />
  </svg>
);

export const playerRecommendation = ({ hovered }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 172 173"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {hovered && (
      <g filter="url(#filter0_f_1349_2676)">
        <ellipse
          cx="100"
          cy="100.117"
          rx="75"
          ry="75.101"
          fill="url(#paint0_radial_1349_2676)"
          fillOpacity="0.5"
        />
      </g>
    )}
    <g filter="url(#filter0_d_1349_2660)">
      <path
        d="M145.105 86.6839C145.105 119.201 118.743 145.562 86.2225 145.562C53.7021 145.562 27.34 119.201 27.34 86.6839C27.34 54.1669 53.7021 27.8058 86.2225 27.8058C118.743 27.8058 145.105 54.1669 145.105 86.6839Z"
        stroke="url(#paint0_radial_1349_2660)"
        strokeOpacity="0.75"
        strokeWidth="16.1741"
      />
      <g filter="url(#filter1_f_1349_2660)">
        <ellipse
          cx="5.99587"
          cy="5.99553"
          rx="5.99587"
          ry="5.99553"
          transform="matrix(0.96593 -0.258803 0.258835 0.965922 136.965 55.25)"
          fill="url(#paint1_radial_1349_2660)"
        />
      </g>
      <ellipse
        cx="86.2208"
        cy="86.6765"
        rx="50.7345"
        ry="50.7312"
        stroke="url(#paint2_radial_1349_2660)"
        strokeOpacity="0.75"
        strokeWidth="13.6858"
      />
      <g filter="url(#filter2_f_1349_2660)">
        <ellipse
          cx="4.11002"
          cy="4.10976"
          rx="4.11002"
          ry="4.10976"
          transform="matrix(0.965931 -0.258802 0.258836 0.965921 125.89 100.758)"
          fill="url(#paint3_radial_1349_2660)"
        />
      </g>
    </g>
    <g filter="url(#filter3_di_1349_2660)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.127 87.1145C118.127 89.1486 117.937 91.1382 117.573 93.0665H92.2028V118.478C90.2744 118.843 88.2846 119.034 86.2503 119.034C84.216 119.034 82.2262 118.843 80.2978 118.478V93.0665H54.9272C54.564 91.1382 54.374 89.1486 54.374 87.1145C54.374 85.0805 54.564 83.0909 54.9272 81.1625H80.2978V55.7509C82.2262 55.3861 84.216 55.1953 86.2503 55.1953C88.2846 55.1953 90.2744 55.3861 92.2028 55.7509V81.1625H117.573C117.937 83.0909 118.127 85.0805 118.127 87.1145Z"
        fill="url(#paint4_linear_1349_2660)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1349_2660"
        x="18.2529"
        y="19.7188"
        width="135.939"
        height="140.93"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1349_2660"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1349_2660"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_f_1349_2660"
        x="137.066"
        y="52.248"
        width="14.4834"
        height="14.4805"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.622082"
          result="effect1_foregroundBlur_1349_2660"
        />
      </filter>
      <filter
        id="filter2_f_1349_2660"
        x="126.19"
        y="98.9326"
        width="9.46584"
        height="9.46291"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.311041"
          result="effect1_foregroundBlur_1349_2660"
        />
      </filter>
      <filter
        id="filter3_di_1349_2660"
        x="41.374"
        y="42.1953"
        width="89.7529"
        height="89.8359"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.486 0 0 0 0 0 0 0 0 0.75 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1349_2660"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1349_2660"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.54 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1349_2660"
        />
      </filter>
      <radialGradient
        id="paint0_radial_1349_2660"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(86.2225 86.6839) rotate(90) scale(66.9652 66.9695)"
      >
        <stop
          offset="0.922664"
          stopColor="var(--brand-meta-orange)"
          stopOpacity="0"
        />
        <stop offset="0.94016" stopColor="#FFA725" />
        <stop offset="0.957501" stopColor="#FFD515" />
        <stop offset="0.975569" stopColor="#FFA200" />
        <stop
          offset="0.98901"
          stopColor="var(--brand-meta-orange)"
          stopOpacity="0"
        />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1349_2660"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.99587 5.99553) rotate(90) scale(5.99553 5.99587)"
      >
        <stop stopColor="var(--brand-meta-orange)" />
        <stop offset="0.5625" stopColor="#FF8100" />
        <stop offset="1" stopColor="#FFB23E" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1349_2660"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(86.2208 86.6765) rotate(90) scale(50.7312 50.7345)"
      >
        <stop offset="0.897582" stopColor="#FF7600" stopOpacity="0" />
        <stop offset="0.958345" stopColor="#FFC900" />
        <stop offset="0.988887" stopColor="#FF9E00" />
        <stop offset="1" stopColor="var(--brand-meta-orange)" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_1349_2660"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.11002 4.10976) rotate(90) scale(4.10976 4.11002)"
      >
        <stop stopColor="var(--brand-meta-orange)" />
        <stop offset="0.5625" stopColor="#FF8100" />
        <stop offset="1" stopColor="#FFB23E" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_1349_2660"
        x1="86.2503"
        y1="55.1953"
        x2="86.2503"
        y2="119.034"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.445395" stopColor="#FFC400" />
        <stop offset="0.865369" stopColor="#FF9000" />
      </linearGradient>

      {hovered && (
        <filter
          id="filter0_f_1349_2676"
          x="0"
          y="0.015625"
          width="200"
          height="200.203"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="12.5"
            result="effect1_foregroundBlur_1349_2676"
          />
        </filter>
      )}

      {hovered && (
        <radialGradient
          id="paint0_radial_1349_2676"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(100 100.117) rotate(90) scale(75.101 75)"
        >
          <stop stopColor="#FFA800" />
          <stop offset="1" stopColor="#FFA800" stopOpacity="0" />
        </radialGradient>
      )}
    </defs>
  </svg>
);

export const playerRecommendationHovered = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 200 201"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1349_2660)">
      <path
        d="M159.105 99.6839C159.105 132.201 132.743 158.562 100.222 158.562C67.7021 158.562 41.34 132.201 41.34 99.6839C41.34 67.1669 67.7021 40.8058 100.222 40.8058C132.743 40.8058 159.105 67.1669 159.105 99.6839Z"
        stroke="url(#paint0_radial_1349_2660)"
        strokeOpacity="0.75"
        strokeWidth="16.1741"
      />
      <g filter="url(#filter1_f_1349_2660)">
        <ellipse
          cx="5.99587"
          cy="5.99553"
          rx="5.99587"
          ry="5.99553"
          transform="matrix(0.96593 -0.258803 0.258835 0.965922 150.965 68.25)"
          fill="url(#paint1_radial_1349_2660)"
        />
      </g>
      <ellipse
        cx="100.221"
        cy="99.6765"
        rx="50.7345"
        ry="50.7312"
        stroke="url(#paint2_radial_1349_2660)"
        strokeOpacity="0.75"
        strokeWidth="13.6858"
      />
      <g filter="url(#filter2_f_1349_2660)">
        <ellipse
          cx="4.11002"
          cy="4.10976"
          rx="4.11002"
          ry="4.10976"
          transform="matrix(0.965931 -0.258802 0.258836 0.965921 139.89 113.758)"
          fill="url(#paint3_radial_1349_2660)"
        />
      </g>
    </g>
    <g filter="url(#filter3_di_1349_2660)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.127 100.115C132.127 102.149 131.937 104.138 131.573 106.067H106.203V131.478C104.274 131.843 102.285 132.034 100.25 132.034C98.216 132.034 96.2262 131.843 94.2978 131.478V106.067H68.9272C68.564 104.138 68.374 102.149 68.374 100.115C68.374 98.0805 68.564 96.0909 68.9272 94.1625H94.2978V68.7509C96.2262 68.3861 98.216 68.1953 100.25 68.1953C102.285 68.1953 104.274 68.3861 106.203 68.7509V94.1625H131.573C131.937 96.0909 132.127 98.0805 132.127 100.115Z"
        fill="url(#paint4_linear_1349_2660)"
      />
    </g>
    <g filter="url(#filter4_f_1349_2660)">
      <ellipse
        cx="100"
        cy="100.117"
        rx="75"
        ry="75.101"
        fill="url(#paint5_radial_1349_2660)"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1349_2660"
        x="32.2529"
        y="32.7188"
        width="135.939"
        height="140.93"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1349_2660"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1349_2660"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_f_1349_2660"
        x="151.066"
        y="65.248"
        width="14.4834"
        height="14.4805"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.622082"
          result="effect1_foregroundBlur_1349_2660"
        />
      </filter>
      <filter
        id="filter2_f_1349_2660"
        x="140.19"
        y="111.933"
        width="9.46584"
        height="9.46291"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.311041"
          result="effect1_foregroundBlur_1349_2660"
        />
      </filter>
      <filter
        id="filter3_di_1349_2660"
        x="55.374"
        y="55.1953"
        width="89.7529"
        height="89.8359"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.486 0 0 0 0 0 0 0 0 0.75 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1349_2660"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1349_2660"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.54 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1349_2660"
        />
      </filter>
      <filter
        id="filter4_f_1349_2660"
        x="0"
        y="0.015625"
        width="200"
        height="200.203"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="12.5"
          result="effect1_foregroundBlur_1349_2660"
        />
      </filter>
      <radialGradient
        id="paint0_radial_1349_2660"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(100.222 99.6839) rotate(90) scale(66.9652 66.9695)"
      >
        <stop
          offset="0.922664"
          stopColor="var(--brand-meta-orange)"
          stopOpacity="0"
        />
        <stop offset="0.94016" stopColor="#FFA725" />
        <stop offset="0.957501" stopColor="#FFD515" />
        <stop offset="0.975569" stopColor="#FFA200" />
        <stop
          offset="0.98901"
          stopColor="var(--brand-meta-orange)"
          stopOpacity="0"
        />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1349_2660"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.99587 5.99553) rotate(90) scale(5.99553 5.99587)"
      >
        <stop stopColor="var(--brand-meta-orange)" />
        <stop offset="0.5625" stopColor="#FF8100" />
        <stop offset="1" stopColor="#FFB23E" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1349_2660"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(100.221 99.6765) rotate(90) scale(50.7312 50.7345)"
      >
        <stop offset="0.897582" stopColor="#FF7600" stopOpacity="0" />
        <stop offset="0.958345" stopColor="#FFC900" />
        <stop offset="0.988887" stopColor="#FF9E00" />
        <stop offset="1" stopColor="var(--brand-meta-orange)" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_1349_2660"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.11002 4.10976) rotate(90) scale(4.10976 4.11002)"
      >
        <stop stopColor="var(--brand-meta-orange)" />
        <stop offset="0.5625" stopColor="#FF8100" />
        <stop offset="1" stopColor="#FFB23E" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_1349_2660"
        x1="100.25"
        y1="68.1953"
        x2="100.25"
        y2="132.034"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.445395" stopColor="#FFC400" />
        <stop offset="0.865369" stopColor="#FF9000" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_1349_2660"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(100 100.117) rotate(90) scale(75.101 75)"
      >
        <stop stopColor="#FFA800" />
        <stop offset="1" stopColor="#FFA800" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const metaRatingLogoLabel = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 73 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.9668 0.514648V5.37775H56.2405V0.514648H54.9668Z"
      fill="#FF7A00"
    />
    <path
      d="M58.3535 0.514648V5.37775H59.7333V2.35182L62.9176 5.37775H64.2974V0.514648H62.9176V3.64865L59.7333 0.514648H58.3535Z"
      fill="#FF7A00"
    />
    <path
      d="M69.7048 3.3883L70.0232 2.39357H72.5706V3.94093C72.5706 4.82513 71.9337 5.37775 70.8723 5.37775H68.1127C66.9451 5.37775 66.2021 4.7146 66.2021 3.60935V2.17252C66.2021 1.28832 66.9451 0.514648 67.9004 0.514648H72.3583L72.0399 1.50937H68.4311C67.7943 1.50937 67.582 1.84095 67.582 2.39357V3.49883C67.582 4.16198 67.9004 4.2725 68.4311 4.2725H70.8723C71.0846 4.2725 71.1907 4.16198 71.1907 3.8304V3.3883H69.7048Z"
      fill="#FF7A00"
    />
    <path
      d="M33.4243 0.514648V5.37775H34.698V1.6199H37.6699C37.6699 1.6199 38.0945 1.6199 38.0945 2.17252C38.0945 2.72515 37.6699 2.72515 37.6699 2.72515H34.9103L37.4577 5.37775H39.3682L37.5638 3.60935H37.8822C37.8822 3.60935 39.3682 3.60935 39.3682 2.062C39.3682 0.514648 37.8822 0.514648 37.8822 0.514648H33.4243Z"
      fill="#FF7A00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.9712 5.37775H47.435L44.7165 0.514648H43.2527L40.4297 5.37775H41.998L42.4163 4.63316H45.5529L45.9712 5.37775ZM45.0302 3.70242L43.9846 1.84095L42.939 3.70242H45.0302Z"
      fill="#FF7A00"
    />
    <path
      d="M49.7282 1.6199V5.37775H50.9795V1.6199H52.9608L53.3779 0.514648H47.7469L47.4341 1.6199H49.7282Z"
      fill="#FF7A00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.4214 5.37775H27.8851L25.1667 0.514648H23.7029L20.8799 5.37775H22.4482L22.8665 4.63316H26.0031L26.4214 5.37775ZM25.4804 3.70242L24.4348 1.84095L23.3892 3.70242H25.4804Z"
      fill="#FF7A00"
    />
    <path
      d="M17.2297 1.6199V5.37775H18.481V1.6199H20.4623L20.8794 0.514648H15.2484L14.9355 1.6199H17.2297Z"
      fill="#FF7A00"
    />
    <path
      d="M0.0761719 5.37775H1.34986V2.72515L2.94196 5.37775H4.00337L5.59547 2.72515V5.37775H6.86916V0.514648H5.59547L3.47267 3.94093L1.456 0.514648H0.0761719V5.37775Z"
      fill="#FF7A00"
    />
    <path
      d="M8.56738 0.622717V5.37775H13.4582L13.8744 4.29706H9.81609V3.54058H13.25L13.5622 2.45989H9.81609V1.70341H13.4582L13.8744 0.514648L8.56738 0.622717Z"
      fill="#FF7A00"
    />
  </svg>
);

export const roleIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4933 12.4C15.4933 14.02 14.18 15.3333 12.56 15.3333C10.94 15.3333 9.62666 14.02 9.62666 12.4C9.62666 10.78 10.94 9.46665 12.56 9.46665C14.18 9.46665 15.4933 10.78 15.4933 12.4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.88 10.8747V13.9253C24.88 14.0549 24.7749 14.16 24.6453 14.16H22.3785C21.649 18.2574 18.4174 21.489 14.32 22.2185V24.4853C14.32 24.6149 14.2149 24.72 14.0853 24.72H11.0347C10.9051 24.72 10.8 24.6149 10.8 24.4853V22.2185C6.70259 21.489 3.471 18.2574 2.74146 14.16H0.474657C0.345054 14.16 0.23999 14.0549 0.23999 13.9253V10.8747C0.23999 10.7451 0.345054 10.64 0.474657 10.64H2.74146C3.471 6.54259 6.70259 3.311 10.8 2.58146V0.314653C10.8 0.18505 10.9051 0.0799866 11.0347 0.0799866H14.0853C14.2149 0.0799866 14.32 0.18505 14.32 0.314653V2.58146C18.4174 3.311 21.649 6.54259 22.3785 10.64H24.6453C24.7749 10.64 24.88 10.7451 24.88 10.8747ZM12.56 19.5771C8.59617 19.5771 5.38285 16.3638 5.38285 12.4C5.38285 8.43616 8.59617 5.22285 12.56 5.22285C16.5238 5.22285 19.7371 8.43616 19.7371 12.4C19.7371 16.3638 16.5238 19.5771 12.56 19.5771Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4933 12.4C15.4933 14.02 14.18 15.3333 12.56 15.3333C10.94 15.3333 9.62666 14.02 9.62666 12.4C9.62666 10.78 10.94 9.46665 12.56 9.46665C14.18 9.46665 15.4933 10.78 15.4933 12.4Z"
      fill="currentColor"
    />
  </svg>
);

export const chemistryIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.43994 21.96L0.559937 20.8824L6.27994 11.831V2.77959L5.61994 2.56408V0.839996H14.8599V2.56408L14.1999 2.77959V11.831L19.9199 20.8824L19.2599 21.96H1.43994ZM8.25994 11.831L6.27994 15.0637H14.1999L12.2199 11.831V2.77959H8.25994V11.831Z"
      fill="currentColor"
    />
  </svg>
);

export const detailedPlayerIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="11.3649"
      y="11.3344"
      width="9.12384"
      height="2.02741"
      rx="1.0137"
      fill="currentColor"
    />
    <rect
      x="0.215332"
      y="21.475"
      width="20.2752"
      height="2.02741"
      rx="1.0137"
      fill="currentColor"
    />
    <rect
      x="0.215332"
      y="16.4012"
      width="20.2752"
      height="2.02741"
      rx="1.0137"
      fill="currentColor"
    />
    <path
      d="M8.45162 10.77L4.49162 13.28L0.531616 10.77V2.35428C3.42816 0.63005 5.14664 0.36512 8.45162 2.35428V10.77Z"
      fill="currentColor"
    />
    <rect
      x="11.3649"
      y="1.20062"
      width="9.12384"
      height="2.02741"
      rx="1.0137"
      fill="currentColor"
    />
    <rect
      x="11.3649"
      y="6.27435"
      width="9.12384"
      height="2.02741"
      rx="1.0137"
      fill="currentColor"
    />
  </svg>
);

export const metaRatingOnCard = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60 30.2778C60 46.9998 46.5685 60.5556 30 60.5556C13.4315 60.5556 0 46.9998 0 30.2778C0 13.5558 13.4315 0 30 0C46.5685 0 60 13.5558 60 30.2778ZM2.85 30.2778C2.85 45.4112 15.0055 57.6792 30 57.6792C44.9945 57.6792 57.15 45.4112 57.15 30.2778C57.15 15.1444 44.9945 2.87639 30 2.87639C15.0055 2.87639 2.85 15.1444 2.85 30.2778Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.1281 24.7469C32.4183 24.7469 34.6566 24.5228 36.8226 24.0952C41.9927 23.0744 46.7512 20.894 50.8212 17.8334C50.8794 17.7896 50.9374 17.7457 50.9954 17.7015C50.9806 17.6795 50.9659 17.6575 50.9511 17.6356C49.4293 15.369 48.1988 13.8576 46.6148 12.4377C45.7682 11.6789 44.8207 10.9462 43.6738 10.1383C43.5671 10.0631 43.4586 9.98728 43.3483 9.91071C39.5604 12.4476 35.0153 13.9254 30.1281 13.9254C25.2688 13.9254 20.7477 12.4644 16.9727 9.95396C16.8424 10.034 16.7145 10.1135 16.589 10.1928C14.9551 11.2238 13.7076 12.2042 12.4392 13.5701C11.4603 14.6241 10.4689 15.9077 9.27767 17.6211C9.26359 17.6414 9.24948 17.6617 9.23535 17.6821C9.29013 17.7239 9.34503 17.7655 9.40006 17.807C13.4746 20.8792 18.2414 23.0681 23.4217 24.0928C25.5914 24.522 27.8337 24.7469 30.1281 24.7469Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.1798 30.7291C36.1798 29.0478 36.3012 28.0538 36.5356 26.4367C40.9097 25.721 45.0469 23.6304 48.826 21.6059C47.6501 24.4168 47.0013 27.498 47.0013 30.7291C47.0013 35.5776 48.4623 40.0886 50.9727 43.8551C49.0383 46.9982 47.3938 48.7026 43.2447 51.5752C38.8102 45.7789 36.1798 38.5578 36.1798 30.7291ZM23.7122 26.4498C19.3443 25.7445 15.2115 23.6663 11.4343 21.6558C12.6045 24.4672 13.2501 27.5478 13.2501 30.7779C13.2501 35.6373 11.789 40.1584 9.2786 43.9333C11.213 47.0834 12.8575 48.7916 17.0067 51.6707C21.4412 45.8614 24.0716 38.6241 24.0716 30.7779C24.0716 29.0843 23.949 28.0784 23.7122 26.4498Z"
      fill="currentColor"
    />
  </svg>
);

export const evolutionsIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 17 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.200195 5.58817V1.4221C0.200195 0.992966 0.676849 0.74801 1.01086 1.00549L2.0242 1.78663C2.15178 1.88497 2.22686 2.03932 2.22686 2.20323V5.58817C2.22686 16.0033 16.4135 11.8373 16.4135 21.7317V27.659C16.4135 28.0461 16.0172 28.2979 15.6803 28.1247L14.6669 27.604C14.4953 27.5158 14.3869 27.3354 14.3869 27.1382V21.7317C14.3869 13.3996 0.200195 17.5656 0.200195 5.58817Z"
      fill="currentColor"
    />
    <path
      d="M16.9202 5.58817C16.9202 5.58817 16.9202 3.50513 15.9069 3.50513C14.8935 3.50513 14.8935 5.58817 14.8935 5.58817C14.8935 9.49036 12.9021 11.3457 10.4115 12.6606L12.48 13.7341C14.951 12.2721 16.9202 10.0505 16.9202 5.58817Z"
      fill="currentColor"
    />
    <path
      d="M0.706862 21.7317V24.8563C0.706862 24.8563 0.706862 26.4185 1.7202 26.4185C2.73353 26.4185 2.73353 24.8563 2.73353 24.8563V21.7317C2.73353 18.7616 4.53612 17.3796 6.85621 16.2884L4.7602 15.2509C2.47363 16.4993 0.706862 18.24 0.706862 21.7317Z"
      fill="currentColor"
    />
    <path
      d="M13.8802 5.58817H3.2402V7.6712H13.8802V5.58817Z"
      fill="currentColor"
    />
    <path
      d="M4.25353 9.75424C4.76531 10.6451 5.56937 11.1371 6.78686 11.8373H10.3335C11.401 11.3501 12.2825 10.8249 12.8669 9.75424H4.25353Z"
      fill="currentColor"
    />
    <path
      d="M3.74686 20.1694C4.08895 19.021 4.47577 18.6324 5.26686 18.0864H11.8535C12.8421 18.6649 13.1302 19.1417 13.3735 20.1694H3.74686Z"
      fill="currentColor"
    />
    <path
      d="M3.74686 22.2525V24.3355H13.3735V22.2525H3.74686Z"
      fill="currentColor"
    />
  </svg>
);

export const evolutionUpgradeTriangle = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1369 0.518265C9.73992 0.239997 9.21125 0.239997 8.81431 0.518266L4.23779 3.72656L0.494415 6.3508C0.033452 6.67395 0.262103 7.39844 0.825054 7.39844L18.1261 7.39844C18.6891 7.39844 18.9177 6.67395 18.4568 6.3508L10.1369 0.518265Z"
      fill="currentColor"
    />
  </svg>
);

export const evolve = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.353516 3.02675V0.579855C0.353516 0.327811 0.633477 0.183939 0.829658 0.335165L1.42484 0.793958C1.49977 0.851722 1.54387 0.942376 1.54387 1.03865V3.02675C1.54387 9.14399 9.87637 6.6971 9.87637 12.5085V15.9898C9.87637 16.2172 9.64356 16.365 9.4457 16.2634L8.85052 15.9575C8.7497 15.9057 8.68601 15.7998 8.68601 15.6839V12.5085C8.68601 7.61468 0.353516 10.0616 0.353516 3.02675Z"
      fill="currentColor"
    />
    <path
      d="M10.174 3.02675C10.174 3.02675 10.174 1.8033 9.57878 1.8033C8.9836 1.8033 8.9836 3.02675 8.9836 3.02675C8.9836 5.31866 7.81395 6.4084 6.35109 7.18069L7.56602 7.81116C9.01738 6.95249 10.174 5.64767 10.174 3.02675Z"
      fill="currentColor"
    />
    <path
      d="M0.651105 12.5085V14.3437C0.651105 14.3437 0.651105 15.2612 1.24628 15.2612C1.84146 15.2612 1.84146 14.3437 1.84146 14.3437V12.5085C1.84146 10.764 2.90021 9.95234 4.26291 9.31143L3.03182 8.70206C1.68881 9.43529 0.651105 10.4577 0.651105 12.5085Z"
      fill="currentColor"
    />
    <path
      d="M8.38842 3.02675H2.13905V4.2502H8.38842V3.02675Z"
      fill="currentColor"
    />
    <path
      d="M2.73423 5.47365C3.03482 5.99689 3.50708 6.28588 4.22218 6.6971H6.3053C6.93227 6.41099 7.45001 6.10247 7.79325 5.47365H2.73423Z"
      fill="currentColor"
    />
    <path
      d="M2.43664 11.5909C2.63756 10.9164 2.86477 10.6882 3.32941 10.3674H7.19807C7.7787 10.7072 7.94794 10.9873 8.09084 11.5909H2.43664Z"
      fill="currentColor"
    />
    <path
      d="M2.43664 12.8143V14.0378H8.09084V12.8143H2.43664Z"
      fill="currentColor"
    />
  </svg>
);

export const addSBCToFavorite = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 25 25"
    fill="var(--fill2)"
  >
    <ellipse
      cx="12.6291"
      cy="12.591"
      rx="11.6291"
      ry="11.6296"
      stroke="var(--stroke1)"
      strokeWidth="1.17979"
    />
    <path
      d="M12.7991 3.99512L14.7667 9.93466H21.1341L15.9828 13.6055L17.9504 19.545L12.7991 15.8742L7.64777 19.545L9.6154 13.6055L4.46409 9.93466H10.8315L12.7991 3.99512Z"
      stroke="white"
      fill="var(--fill1)"
      strokeWidth="1.10158"
    />
  </svg>
);

export const hideSBC = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 25 26"
    fill="none"
  >
    <ellipse
      cx="12.6291"
      cy="12.8503"
      rx="11.6291"
      ry="11.6296"
      fill="var(--fill2)"
    />
    <path
      d="M16.0289 12.535C16.0289 14.733 14.4045 16.5149 12.4007 16.5149C10.3968 16.5149 8.77244 14.733 8.77244 12.535C8.77244 12.1597 8.81978 11.7966 8.90828 11.4524C9.19008 11.6501 9.525 11.7647 9.88431 11.7647C10.8862 11.7647 11.6984 10.8738 11.6984 9.77475C11.6984 9.38061 11.594 9.01324 11.4138 8.70412C11.7275 8.60705 12.0586 8.55511 12.4007 8.55511C14.4045 8.55511 16.0289 10.337 16.0289 12.535Z"
      fill="var(--fill1)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.22958 12.0183C8.09445 2.41587 18.7002 5.13202 21.5644 11.8938C21.702 12.2186 21.6987 12.5956 21.5628 12.9212C18.212 20.9452 7.52784 21.6916 3.22389 13.0523C3.06457 12.7325 3.06819 12.3369 3.22958 12.0183ZM19.9729 12.1389C17.2674 6.78342 8.98316 5.09759 4.88478 12.1608C4.7527 12.3885 4.74684 12.6833 4.87328 12.9148C8.39928 19.3689 17.2756 18.8083 19.9862 12.785C20.0782 12.5806 20.0736 12.3383 19.9729 12.1389Z"
      fill="var(--fill1)"
    />
    <path
      d="M2.87305 18.1035L20.8913 5.26824L21.9269 7.01741L3.90861 19.8527L2.87305 18.1035Z"
      fill="var(--fill1)"
      stroke="var(--fill1)"
    />
    <path
      d="M21.5691 6.41312L3.55085 19.2484L4.26637 20.457L22.2846 7.6217L21.5691 6.41312Z"
      fill="var(--fill2)"
      stroke="var(--fill2)"
    />
    <ellipse
      cx="12.6291"
      cy="12.8503"
      rx="11.6291"
      ry="11.6296"
      stroke="white"
      strokeWidth="1.17979"
    />
  </svg>
);

export const completedSBC = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 25 26"
    fill="var(--fillInnerCircle)"
  >
    <ellipse
      cx="12.704"
      cy="13.1842"
      rx="11.704"
      ry="11.7045"
      stroke="var(--strokeOuterCircle)"
      strokeWidth="1.18419"
    />
    <path
      d="M9.10241 12.7343L7.75195 14.0848L11.2031 17.5362L17.6553 11.0837L16.3049 9.73315L11.2031 14.8351L9.10241 12.7343Z"
      fill="var(--fillCheckMark)"
    />
  </svg>
);

export const options = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.536 0H2.816V2.65564C3.70543 2.92894 4.352 3.75696 4.352 4.736C4.352 5.71504 3.70543 6.54306 2.816 6.81636V13.056H1.536V6.81636C0.64657 6.54306 0 5.71504 0 4.736C0 3.75696 0.64657 2.92894 1.536 2.65564V0ZM2.176 3.584C2.41282 3.584 2.63295 3.65546 2.816 3.77799C3.12473 3.98465 3.328 4.33659 3.328 4.736C3.328 5.13541 3.12473 5.48735 2.816 5.69401C2.63295 5.81654 2.41282 5.888 2.176 5.888C1.93918 5.888 1.71905 5.81654 1.536 5.69401C1.22727 5.48735 1.024 5.13541 1.024 4.736C1.024 4.33659 1.22727 3.98465 1.536 3.77799C1.71905 3.65546 1.93918 3.584 2.176 3.584Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4 0H7.68V6.23964C8.56943 6.51294 9.216 7.34096 9.216 8.32C9.216 9.29904 8.56943 10.1271 7.68 10.4004V13.056H6.4V10.4004C5.51057 10.1271 4.864 9.29904 4.864 8.32C4.864 7.34096 5.51057 6.51294 6.4 6.23964V0ZM5.888 8.32C5.888 7.92059 6.09127 7.56865 6.4 7.36199C6.58305 7.23946 6.80318 7.168 7.04 7.168C7.27682 7.168 7.49695 7.23946 7.68 7.36199C7.98873 7.56865 8.192 7.92059 8.192 8.32C8.192 8.71941 7.98873 9.07135 7.68 9.27801C7.49695 9.40054 7.27682 9.472 7.04 9.472C6.80318 9.472 6.58305 9.40054 6.4 9.27801C6.09127 9.07135 5.888 8.71941 5.888 8.32Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.008 0H12.288V2.65564C13.1774 2.92894 13.824 3.75696 13.824 4.736C13.824 5.71504 13.1774 6.54306 12.288 6.81636V13.056H11.008V6.81636C10.1186 6.54306 9.472 5.71504 9.472 4.736C9.472 3.75696 10.1186 2.92894 11.008 2.65564V0ZM10.496 4.736C10.496 4.33659 10.6993 3.98465 11.008 3.77799C11.1911 3.65546 11.4112 3.584 11.648 3.584C11.8848 3.584 12.1049 3.65546 12.288 3.77799C12.5967 3.98465 12.8 4.33659 12.8 4.736C12.8 5.13541 12.5967 5.48735 12.288 5.69401C12.1049 5.81654 11.8848 5.888 11.648 5.888C11.4112 5.888 11.1911 5.81654 11.008 5.69401C10.6993 5.48735 10.496 5.13541 10.496 4.736Z"
      fill="currentColor"
    />
  </svg>
);

export const locked = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 11.6243C6 11.2709 6.28654 10.9843 6.64 10.9843H17.36C17.7135 10.9843 18 11.2709 18 11.6243V18.36C18 18.7135 17.7135 19 17.36 19H6.64C6.28654 19 6 18.7135 6 18.36V11.6243Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9538 3C9.3836 3 7.3 5.15208 7.3 7.80681V9.67173C7.3 9.84846 7.44327 9.99173 7.62 9.99173L8.24923 9.99173C8.42596 9.99173 8.56923 9.84846 8.56923 9.67173V7.80681C8.56923 5.8761 10.0846 4.31095 11.9538 4.31095C13.8231 4.31095 15.3385 5.8761 15.3385 7.80681V9.67173C15.3385 9.84846 15.4817 9.99173 15.6585 9.99173L16.2877 9.99173C16.4644 9.99173 16.6077 9.84846 16.6077 9.67173V7.80681C16.6077 5.15208 14.5241 3 11.9538 3Z"
    />
  </svg>
);

export const lock = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 104 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 78C0 75.7909 1.79086 74 4 74H100C102.209 74 104 75.7909 104 78V140C104 142.209 102.209 144 100 144H4C1.79086 144 0 142.209 0 140V78Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52 0C27.6995 0 8 19.6995 8 44L20 44C20 26.3269 34.3269 12 52 12C69.6731 12 84 26.3269 84 44V74H96V44C96 19.6995 76.3005 0 52 0Z"
      fill="currentColor"
    />
  </svg>
);

export const chainedEvo = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 124 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33 2C33 0.89543 33.8954 0 35 0H56C57.1046 0 58 0.895431 58 2V8C58 9.10457 57.1046 10 56 10H35C33.8954 10 33 9.10457 33 8V2Z"
      fill="currentColor"
    />
    <rect
      x="0.4"
      y="0.4"
      width="24.2"
      height="9.2"
      rx="1.6"
      stroke="currentColor"
      strokeWidth="0.8"
      strokeDasharray="6 6"
    />
    <rect x="66" width="25" height="10" rx="2" fill="currentColor" />
    <rect
      x="99.4"
      y="0.4"
      width="24.2"
      height="9.2"
      rx="1.6"
      stroke="currentColor"
      strokeWidth="0.8"
      strokeDasharray="6 6"
    />
    <path d="M45 5H79" stroke="currentColor" strokeWidth="2" />
    <path d="M27 5L31 5" stroke="currentColor" strokeWidth="0.8" />
    <path d="M93 5L97 5" stroke="currentColor" strokeWidth="0.8" />
  </svg>
);
export const standAloneEvo = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 58 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2C0 0.89543 0.89543 0 2 0H23C24.1046 0 25 0.895431 25 2V8C25 9.10457 24.1046 10 23 10H2C0.89543 10 0 9.10457 0 8V2Z"
      fill="currentColor"
    />
    <rect x="33" width="25" height="10" rx="2" fill="currentColor" />
    <path d="M12 5L45 5" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export const info2 = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="white" />
    <g>
      <path
        d="M11 5H15L14.5 15.3226C14.5 15.3226 14.5 15.8387 13 15.8387C11.5 15.8387 11.5 15.3226 11.5 15.3226L11 5Z"
        fill="currentColor"
      />
      <path
        d="M14.5 18.4194V20.4839C14.5 20.4839 14.5 21 13 21C11.5 21 11.5 20.4839 11.5 20.4839V18.4194C11.5 18.4194 11.5 17.9032 13 17.9032C14.5 17.9032 14.5 18.4194 14.5 18.4194Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const doubleArrow = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 34 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 11.7158L17 0L34 11.7158V22.7684L17 11.0526L0 22.7684V11.7158Z"
      fill="currentColor"
    />
    <path
      d="M0 30.9474L17 19.2316L34 30.9474V42L17 30.2842L0 42V30.9474Z"
      fill="currentColor"
    />
  </svg>
);
