import axios from "../../privateApi";

export type ClubStats = {
  clubValue: number;
  sbcFodder: number;
  potentialCoins: number;
  transferList?: number;
  rank?: number;
};

export const getStats = async (): Promise<ClubStats> => {
  const res = await axios.get("/user-clubs/stats");
  return res.data;
};

export default getStats;
