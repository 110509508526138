import { clsx } from "clsx";

type Props = {
  variant?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
  icon?: JSX.Element;
  children: string;
  disabled?: boolean;
  locked?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const IconSection = ({ icon, size, locked, disabled }) => (
  <div
    className={clsx(
      "my-auto stroke-gray-900",
      size === "small" || size === "medium" ? "w-3 h-3" : "w-4 h-4",
      locked || disabled ? "text-dark-disabled" : "text-light-primary"
    )}
  >
    {icon}
  </div>
);

const PrimaryButton = ({
  size = "medium",
  icon,
  children,
  disabled,
  locked,
  onClick,
}: Omit<Props, "variant">) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={clsx(
      "flex justify-center w-fit rounded-lg",
      // Padding-x with icon is different from padding without icon
      icon && size === "small"
        ? "px-2"
        : !icon && size === "small"
        ? "px-4"
        : icon && size === "medium"
        ? "px-3"
        : !icon && size === "medium"
        ? "px-4"
        : "px-10",
      size === "small"
        ? "py-1 gap-x-1"
        : size === "medium"
        ? "py-[5px]  gap-x-1"
        : "h-10  gap-x-2 w-48",
      locked || disabled
        ? "bg-gray-800"
        : "bg-gray-200 shadow-button hover:bg-gray-100"
    )}
  >
    <p
      className={clsx(
        "font-medium my-auto whitespace-nowrap",
        size === "small" ? "text-sm" : "text-base",
        locked || disabled ? "text-dark-disabled" : "text-light-primary"
      )}
    >
      {children}
    </p>
    {icon && (
      <IconSection
        icon={icon}
        size={size}
        locked={locked}
        disabled={disabled}
      />
    )}
  </button>
);

const SecondaryButton = ({
  size = "medium",
  icon,
  children,
  disabled,
  locked,
  onClick,
}: Omit<Props, "variant">) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        "w-fit rounded-[10px]  border-b border-gray-200",
        locked || disabled
          ? "text-dark-disabled"
          : "hover:text-white text-secondary"
      )}
      style={{
        boxShadow:
          "0px -1.5px 0px 0px rgba(47, 43, 67, 0.30) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div
        className={clsx(
          "flex justify-center w-fit rounded-t-[10px]  rounded-b-[8px]",
          // Padding-x with icon is different from padding without icon
          icon && size === "small"
            ? "px-2"
            : !icon && size === "small"
            ? "px-4"
            : icon && size === "medium"
            ? "px-3"
            : !icon && size === "medium"
            ? "px-4"
            : "px-10",
          size === "small"
            ? "py-1 gap-x-1"
            : size === "medium"
            ? "py-[5px]  gap-x-1"
            : "h-10  gap-x-2 w-48",
          locked || disabled
            ? "bg-none border border-gray-600"
            : "hover:bg-gray-800 bg-gray-875 shadow-button border-2 hover:border-gray-100 border-secondary-dark"
        )}
      >
        <p
          className={clsx(
            "font-medium my-auto translate-y-[1px] whitespace-nowrap",
            size === "small" ? "text-sm" : "text-base"
          )}
        >
          {children}
        </p>
        {icon && (
          <IconSection
            icon={icon}
            size={size}
            locked={locked}
            disabled={disabled}
          />
        )}
      </div>
    </button>
  );
};

export const Button = ({
  variant = "primary",
  size = "medium",
  icon,
  children,
  disabled,
  locked,
  onClick,
}: Props) => {
  return variant === "primary" ? (
    <PrimaryButton
      size={size}
      icon={icon}
      children={children}
      disabled={disabled}
      locked={locked}
      onClick={onClick}
    />
  ) : (
    <SecondaryButton
      size={size}
      icon={icon}
      children={children}
      disabled={disabled}
      locked={locked}
      onClick={onClick}
    />
  );
};
