import { Player } from "../../../types/Player";
import axios from "../../privateApi";

export interface PlayerSwap extends Player {
  new_solution_info: {
    player_chems: number[];
    n_untradeables: number;
    n_owned_players: number;
    n_duplicates: number;
    rating: number;
    total_chem: number;
    price: number;
    transfer_market_cost: number;
  };
}

export const playerSwap = async (
  id: number,
  hybrid: number,
  untradeable: boolean,
  excludeGoldPlayers: boolean,
  excludeSilverPlayers: boolean,
  excludeBronzePlayers: boolean,
  excludeActiveSquad: boolean,
  excludeSpecialPlayers: boolean,
  excludeObjectivePlayers: boolean,
  excludeSbcPlayers: boolean,
  includeTransferList: boolean,
  prioritizeDuplicates: boolean,

  playerIds: number[],
  excludedPlayers: number[]
) => {
  if (hybrid === 4) hybrid = 0;
  else if (hybrid === 0) hybrid = 4;
  const response = await axios.post<PlayerSwap[][]>("/player-swap", {
    id,
    hybrid,
    untradeable,
    excludeGoldPlayers,
    excludeSilverPlayers,
    excludeBronzePlayers,
    excludeSpecialPlayers,
    excludeObjectivePlayers,
    excludeSbcPlayers,
    excludeActiveSquad,
    includeTransferList,
    prioritizeDuplicates,
    playerIds,
    excludedPlayers,
  });
  if (response.data) {
    return response.data;
  }
  return [] as PlayerSwap[][];
};
