import { useCallback, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { usePageVisibility } from "../../../hooks/usePageVisibility";
import { globalStateSelector } from "../../../redux/globalState/globalStateSlice";
import { getMessageSelector } from "../../../redux/message/messageSlice";
import { playersSelector } from "../../../redux/players/playersSlice";
import { AppDispatch } from "../../../redux/store";
import {
  canUseClubImportSelector,
  fetchUserNotifications,
  getUserSelector,
} from "../../../redux/user/userSlice";
import {
  BOTTOM_RAIL_DESKTOP,
  BOTTOM_RAIL_MOBILE,
  CHROME_EXTENSION_VERSION,
  LEADERBOARD,
  LEADERBOARD_MOBILE,
  MPU_DESKTOP,
  MPU_MOBILE,
  MPU_PLAYERSPAGE,
} from "../../../settings";
import { AllowTrackingPopup } from "../../features/Extension/AllowTrackingPopup";
import { Notifications } from "../Notifications/Notifications";

type Props = {
  children: React.ReactNode;
  isLoggedIn: boolean;
};

const AppWrapper = ({ children, isLoggedIn }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const baseScriptSrc =
    "https://live.primis.tech/live/liveView.php?s=117224&schain=1.0,1!snack-media.com,SNM_3441,1";

  const { userNotifications, user } = useSelector(getUserSelector);
  const { snackIsReady } = useSelector(globalStateSelector);
  const { alerts } = useSelector(getMessageSelector);
  const canUseClubImport = useSelector(canUseClubImportSelector);
  const { filterBarOpen } = useSelector(playersSelector);
  const handleVisibilityChange = useCallback(
    (visible) => {
      if (isLoggedIn && visible) {
        dispatch(fetchUserNotifications());
      }
    },
    [isLoggedIn, dispatch]
  ); // Dependencies array is empty, so this function is defined only once

  usePageVisibility(handleVisibilityChange);

  useEffect(() => {
    if (!snackIsReady) return;

    const blockedPages = [
      "/subscription",
      "/who-are-we",
      "/meta-rating",
      "/login",
      "/profile",
    ];

    const blockedPage = blockedPages.includes(location.pathname);
    const primisWrapper = document.getElementById("primisPlayerContainerDiv");
    const existingScript = document.querySelector(
      'script[src^="https://live.primis.tech/live/liveView.php"]'
    );

    if (
      !blockedPage &&
      !primisWrapper &&
      !existingScript &&
      !isMobile &&
      !filterBarOpen
    ) {
      const newScript = document.createElement("script");
      newScript.src = baseScriptSrc + `?t=${Date.now()}`;
      newScript.async = true;
      document.body.appendChild(newScript);
    } else if (primisWrapper && blockedPage) {
      primisWrapper.remove();
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    }
    if (!blockedPage && !filterBarOpen) {
      window.killSlot([
        isMobile ? BOTTOM_RAIL_MOBILE : BOTTOM_RAIL_DESKTOP,
        isMobile ? MPU_MOBILE : MPU_DESKTOP,
        isMobile ? LEADERBOARD_MOBILE : LEADERBOARD,
        MPU_PLAYERSPAGE,
      ]);
      window.refreshBid([
        isMobile ? BOTTOM_RAIL_MOBILE : BOTTOM_RAIL_DESKTOP,
        isMobile ? MPU_MOBILE : MPU_DESKTOP,
        isMobile ? LEADERBOARD_MOBILE : LEADERBOARD,
        MPU_PLAYERSPAGE,
      ]);
    } else {
      window.killSlot([
        isMobile ? BOTTOM_RAIL_MOBILE : BOTTOM_RAIL_DESKTOP,
        isMobile ? MPU_MOBILE : MPU_DESKTOP,
        isMobile ? LEADERBOARD_MOBILE : LEADERBOARD,
        MPU_PLAYERSPAGE,
      ]);
    }
  }, [location, snackIsReady, filterBarOpen]);

  const versionId = [2, 1, 0];
  const chromeExtensionsVersionAsArray = localStorage
    .getItem(CHROME_EXTENSION_VERSION)
    ?.split(".");

  let isChromeVersionUpdated = !!localStorage.getItem(CHROME_EXTENSION_VERSION);

  if (chromeExtensionsVersionAsArray) {
    for (let index = 0; index < versionId.length; index++) {
      const currentVersion = versionId[index];
      const extensionVersion = parseInt(
        chromeExtensionsVersionAsArray[index] || "0"
      );

      if (extensionVersion > currentVersion) {
        break; // The extension version is greater at a higher significance level, so stop checking
      } else if (extensionVersion < currentVersion) {
        isChromeVersionUpdated = false; // The extension version is lesser, set flag to false
        break; // No need to check further as we found a lower segment
      }
      // If they are equal, continue to the next segment
    }
  }

  return (
    <div className="min-h-screen">
      {children}
      {snackIsReady && (
        <div>
          <div id={isMobile ? BOTTOM_RAIL_MOBILE : BOTTOM_RAIL_DESKTOP} />
        </div>
      )}
      {(userNotifications.length > 0 || alerts.length > 0) && (
        <Notifications userNotifications={userNotifications} alerts={alerts} />
      )}
      {canUseClubImport &&
        user.allowTracking === undefined &&
        isChromeVersionUpdated && <AllowTrackingPopup />}
    </div>
  );
};

export default AppWrapper;
