const formationCoordinates = {
  any: {
    coordinates: [
      { x: 0.5, y: 0.75 },
      { x: 0.1, y: 0.5 },
      { x: 0.3, y: 0.5 },
      { x: 0.5, y: 0.5 },
      { x: 0.7, y: 0.5 },
      { x: 0.9, y: 0.5 },
      { x: 0.3, y: 0.25 },
      { x: 0.1, y: 0.25 },
      { x: 0.5, y: 0.25 },
      { x: 0.7, y: 0.25 },
      { x: 0.9, y: 0.25 },
    ],
  },
  f541a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.59 },
      { x: 0.7, y: 0.64 },
      { x: 0.5, y: 0.61 },
      { x: 0.3, y: 0.64 },
      { x: 0.1, y: 0.59 },
      { x: 0.85, y: 0.36 },
      { x: 0.65, y: 0.38 },
      { x: 0.35, y: 0.38 },
      { x: 0.15, y: 0.36 },
      { x: 0.5, y: 0.16 },
    ],
    labels: [
      "GK",
      "RWB",
      "CB",
      "CB",
      "CB",
      "LWB",
      "RM",
      "CM",
      "CM",
      "LM",
      "ST",
    ],
  },
  f5212: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.56 },
      { x: 0.7, y: 0.62 },
      { x: 0.5, y: 0.61 },
      { x: 0.3, y: 0.62 },
      { x: 0.1, y: 0.56 },
      { x: 0.69, y: 0.39 },
      { x: 0.31, y: 0.39 },
      { x: 0.5, y: 0.21 },
      { x: 0.7, y: 0.15 },
      { x: 0.3, y: 0.15 },
    ],
    labels: [
      "GK",
      "RWB",
      "CB",
      "CB",
      "CB",
      "LWB",
      "CM",
      "CM",
      "CAM",
      "ST",
      "ST",
    ],
  },
  f5221: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.56 },
      { x: 0.7, y: 0.65 },
      { x: 0.5, y: 0.61 },
      { x: 0.3, y: 0.65 },
      { x: 0.1, y: 0.56 },
      { x: 0.67, y: 0.42 },
      { x: 0.33, y: 0.42 },
      { x: 0.7, y: 0.19 },
      { x: 0.5, y: 0.14 },
      { x: 0.3, y: 0.19 },
    ],
    labels: [
      "GK",
      "RWB",
      "CB",
      "CB",
      "CB",
      "LWB",
      "CM",
      "CM",
      "RW",
      "ST",
      "LW",
    ],
  },
  f442a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.89, y: 0.64 },
      { x: 0.69, y: 0.66 },
      { x: 0.31, y: 0.66 },
      { x: 0.11, y: 0.64 },
      { x: 0.65, y: 0.42 },
      { x: 0.35, y: 0.42 },
      { x: 0.85, y: 0.4 },
      { x: 0.15, y: 0.4 },
      { x: 0.67, y: 0.18 },
      { x: 0.33, y: 0.18 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "RM",
      "LM",
      "ST",
      "ST",
    ],
  },
  f4222: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.7, y: 0.66 },
      { x: 0.3, y: 0.66 },
      { x: 0.1, y: 0.64 },
      { x: 0.65, y: 0.42 },
      { x: 0.35, y: 0.42 },
      { x: 0.87, y: 0.35 },
      { x: 0.13, y: 0.35 },
      { x: 0.62, y: 0.15 },
      { x: 0.38, y: 0.15 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "CAM",
      "CAM",
      "ST",
      "ST",
    ],
  },
  f433: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.75, y: 0.44 },
      { x: 0.5, y: 0.46 },
      { x: 0.25, y: 0.44 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CM", "CM", "CM", "RW", "ST", "LW"],
  },
  f442: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.89, y: 0.64 },
      { x: 0.69, y: 0.66 },
      { x: 0.31, y: 0.66 },
      { x: 0.11, y: 0.64 },
      { x: 0.85, y: 0.4 },
      { x: 0.65, y: 0.42 },
      { x: 0.35, y: 0.42 },
      { x: 0.15, y: 0.4 },
      { x: 0.67, y: 0.18 },
      { x: 0.33, y: 0.18 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "RM", "CM", "CM", "LM", "ST", "ST"],
  },
  f41212a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.64 },
      { x: 0.68, y: 0.69 },
      { x: 0.32, y: 0.69 },
      { x: 0.12, y: 0.64 },
      { x: 0.5, y: 0.47 },
      { x: 0.8, y: 0.4 },
      { x: 0.2, y: 0.4 },
      { x: 0.5, y: 0.24 },
      { x: 0.7, y: 0.14 },
      { x: 0.3, y: 0.14 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CM",
      "CM",
      "CAM",
      "ST",
      "ST",
    ],
  },
  f424: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.65 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.1, y: 0.65 },
      { x: 0.68, y: 0.47 },
      { x: 0.32, y: 0.47 },
      { x: 0.89, y: 0.325 },
      { x: 0.68, y: 0.15 },
      { x: 0.32, y: 0.15 },
      { x: 0.11, y: 0.325 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CM", "CM", "RW", "ST", "ST", "LW"],
  },
  f343: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.77, y: 0.66 },
      { x: 0.5, y: 0.61 },
      { x: 0.23, y: 0.66 },
      { x: 0.9, y: 0.39 },
      { x: 0.68, y: 0.435 },
      { x: 0.32, y: 0.435 },
      { x: 0.1, y: 0.39 },
      { x: 0.72, y: 0.18 },
      { x: 0.5, y: 0.16 },
      { x: 0.28, y: 0.18 },
    ],
    labels: ["GK", "CB", "CB", "CB", "RM", "CM", "CM", "LM", "RW", "ST", "LW"],
  },
  f3412: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.79, y: 0.67 },
      { x: 0.5, y: 0.61 },
      { x: 0.21, y: 0.67 },
      { x: 0.9, y: 0.43 },
      { x: 0.685, y: 0.445 },
      { x: 0.315, y: 0.445 },
      { x: 0.1, y: 0.43 },
      { x: 0.5, y: 0.28 },
      { x: 0.7, y: 0.17 },
      { x: 0.3, y: 0.17 },
    ],
    labels: ["GK", "CB", "CB", "CB", "RM", "CM", "CM", "LM", "CAM", "ST", "ST"],
  },
  f3421: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.79, y: 0.67 },
      { x: 0.5, y: 0.61 },
      { x: 0.21, y: 0.67 },
      { x: 0.9, y: 0.44 },
      { x: 0.675, y: 0.45 },
      { x: 0.325, y: 0.45 },
      { x: 0.1, y: 0.44 },
      { x: 0.705, y: 0.205 },
      { x: 0.295, y: 0.205 },
      { x: 0.5, y: 0.15 },
    ],
    labels: ["GK", "CB", "CB", "CB", "RM", "CM", "CM", "LM", "CF", "CF", "ST"],
  },
  f433b: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.75, y: 0.44 },
      { x: 0.25, y: 0.44 },
      { x: 0.5, y: 0.4 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "CM",
      "RW",
      "ST",
      "LW",
    ],
  },
  f4321: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.68, y: 0.67 },
      { x: 0.32, y: 0.67 },
      { x: 0.1, y: 0.64 },
      { x: 0.8, y: 0.4 },
      { x: 0.5, y: 0.43 },
      { x: 0.2, y: 0.4 },
      { x: 0.7, y: 0.175 },
      { x: 0.3, y: 0.175 },
      { x: 0.5, y: 0.135 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CM", "CM", "CM", "CF", "CF", "ST"],
  },
  f451a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.7, y: 0.66 },
      { x: 0.3, y: 0.66 },
      { x: 0.1, y: 0.64 },
      { x: 0.9, y: 0.35 },
      { x: 0.7, y: 0.4 },
      { x: 0.5, y: 0.43 },
      { x: 0.3, y: 0.4 },
      { x: 0.1, y: 0.35 },
      { x: 0.5, y: 0.16 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "RM", "CM", "CM", "CM", "LM", "ST"],
  },
  f433d: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.5, y: 0.48 },
      { x: 0.71, y: 0.43 },
      { x: 0.29, y: 0.43 },
      { x: 0.5, y: 0.25 },
      { x: 0.73, y: 0.2 },
      { x: 0.27, y: 0.2 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CDM", "CM", "CM", "CF", "RW", "LW"],
  },
  f451: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.7, y: 0.66 },
      { x: 0.3, y: 0.66 },
      { x: 0.1, y: 0.64 },
      { x: 0.9, y: 0.4 },
      { x: 0.5, y: 0.46 },
      { x: 0.1, y: 0.4 },
      { x: 0.7, y: 0.34 },
      { x: 0.3, y: 0.34 },
      { x: 0.5, y: 0.16 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "RM",
      "CM",
      "LM",
      "CAM",
      "CAM",
      "ST",
    ],
  },
  f3142: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.79, y: 0.67 },
      { x: 0.5, y: 0.62 },
      { x: 0.21, y: 0.67 },
      { x: 0.5, y: 0.4 },
      { x: 0.9, y: 0.36 },
      { x: 0.69, y: 0.37 },
      { x: 0.31, y: 0.37 },
      { x: 0.1, y: 0.36 },
      { x: 0.7, y: 0.145 },
      { x: 0.3, y: 0.145 },
    ],
    labels: ["GK", "CB", "CB", "CB", "CDM", "RM", "CM", "CM", "LM", "ST", "ST"],
  },
  f4231: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.69 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.1, y: 0.69 },
      { x: 0.8, y: 0.47 },
      { x: 0.2, y: 0.47 },
      { x: 0.7, y: 0.25 },
      { x: 0.5, y: 0.375 },
      { x: 0.3, y: 0.25 },
      { x: 0.5, y: 0.135 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "CAM",
      "CAM",
      "CAM",
      "ST",
    ],
  },
  f41212: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.68, y: 0.66 },
      { x: 0.32, y: 0.66 },
      { x: 0.1, y: 0.64 },
      { x: 0.5, y: 0.48 },
      { x: 0.78, y: 0.4 },
      { x: 0.22, y: 0.4 },
      { x: 0.5, y: 0.25 },
      { x: 0.7, y: 0.16 },
      { x: 0.3, y: 0.16 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "RM",
      "LM",
      "CAM",
      "ST",
      "ST",
    ],
  },
  f433a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.5, y: 0.51 },
      { x: 0.75, y: 0.44 },
      { x: 0.25, y: 0.44 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CDM", "CM", "CM", "RW", "ST", "LW"],
  },
  f4231a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.7 },
      { x: 0.69, y: 0.72 },
      { x: 0.31, y: 0.72 },
      { x: 0.1, y: 0.7 },
      { x: 0.71, y: 0.5 },
      { x: 0.29, y: 0.5 },
      { x: 0.9, y: 0.335 },
      { x: 0.1, y: 0.335 },
      { x: 0.5, y: 0.375 },
      { x: 0.5, y: 0.135 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "RM",
      "LM",
      "CAM",
      "ST",
    ],
  },
  f4411: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.7, y: 0.68 },
      { x: 0.3, y: 0.68 },
      { x: 0.1, y: 0.64 },
      { x: 0.9, y: 0.38 },
      { x: 0.7, y: 0.42 },
      { x: 0.3, y: 0.42 },
      { x: 0.1, y: 0.38 },
      { x: 0.5, y: 0.345 },
      { x: 0.5, y: 0.12 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "RM", "CM", "CM", "LM", "CF", "ST"],
  },
  f4411a: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.7, y: 0.68 },
      { x: 0.3, y: 0.68 },
      { x: 0.1, y: 0.64 },
      { x: 0.905, y: 0.38 },
      { x: 0.715, y: 0.42 },
      { x: 0.285, y: 0.42 },
      { x: 0.095, y: 0.38 },
      { x: 0.5, y: 0.36 },
      { x: 0.5, y: 0.14 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "RM", "CM", "CM", "LM", "CAM", "ST"],
  },
  f433c: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.73, y: 0.44 },
      { x: 0.27, y: 0.44 },
      { x: 0.5, y: 0.4 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CM", "CM", "CAM", "RW", "ST", "LW"],
  },
  f4312: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.64 },
      { x: 0.69, y: 0.66 },
      { x: 0.31, y: 0.66 },
      { x: 0.1, y: 0.64 },
      { x: 0.82, y: 0.36 },
      { x: 0.5, y: 0.46 },
      { x: 0.18, y: 0.36 },
      { x: 0.5, y: 0.22 },
      { x: 0.7, y: 0.13 },
      { x: 0.3, y: 0.13 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CM", "CM", "CM", "CAM", "ST", "ST"],
  },
  f532: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.59 },
      { x: 0.7, y: 0.64 },
      { x: 0.5, y: 0.61 },
      { x: 0.3, y: 0.64 },
      { x: 0.1, y: 0.59 },
      { x: 0.5, y: 0.37 },
      { x: 0.78, y: 0.35 },
      { x: 0.22, y: 0.35 },
      { x: 0.63, y: 0.13 },
      { x: 0.37, y: 0.13 },
    ],
    labels: [
      "GK",
      "RWB",
      "CB",
      "CB",
      "CB",
      "LWB",
      "CDM",
      "CM",
      "CM",
      "ST",
      "ST",
    ],
  },
  f4132: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.65 },
      { x: 0.7, y: 0.68 },
      { x: 0.3, y: 0.68 },
      { x: 0.1, y: 0.65 },
      { x: 0.5, y: 0.58 },
      { x: 0.78, y: 0.36 },
      { x: 0.5, y: 0.36 },
      { x: 0.22, y: 0.36 },
      { x: 0.63, y: 0.15 },
      { x: 0.37, y: 0.15 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CDM", "RM", "CM", "LM", "ST", "ST"],
  },
  f4141: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.63 },
      { x: 0.7, y: 0.66 },
      { x: 0.3, y: 0.66 },
      { x: 0.1, y: 0.63 },
      { x: 0.5, y: 0.47 },
      { x: 0.89, y: 0.345 },
      { x: 0.7, y: 0.36 },
      { x: 0.3, y: 0.36 },
      { x: 0.11, y: 0.345 },
      { x: 0.5, y: 0.15 },
    ],
    labels: ["GK", "RB", "CB", "CB", "LB", "CDM", "RM", "CM", "CM", "LM", "ST"],
  },
  f352: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.78, y: 0.66 },
      { x: 0.5, y: 0.61 },
      { x: 0.22, y: 0.66 },
      { x: 0.69, y: 0.43 },
      { x: 0.31, y: 0.43 },
      { x: 0.9, y: 0.36 },
      { x: 0.1, y: 0.36 },
      { x: 0.5, y: 0.28 },
      { x: 0.7, y: 0.14 },
      { x: 0.3, y: 0.14 },
    ],
    labels: [
      "GK",
      "CB",
      "CB",
      "CB",
      "CDM",
      "CDM",
      "RM",
      "LM",
      "CAM",
      "ST",
      "ST",
    ],
  },
  f4213: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.88, y: 0.67 },
      { x: 0.68, y: 0.7 },
      { x: 0.32, y: 0.7 },
      { x: 0.12, y: 0.67 },
      { x: 0.75, y: 0.44 },
      { x: 0.25, y: 0.44 },
      { x: 0.5, y: 0.4 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: [
      "GK",
      "RB",
      "CB",
      "CB",
      "LB",
      "CDM",
      "CDM",
      "CAM",
      "RW",
      "ST",
      "LW",
    ],
  },
  f523: {
    coordinates: [
      { x: 0.5, y: 0.84 },
      { x: 0.9, y: 0.59 },
      { x: 0.7, y: 0.64 },
      { x: 0.5, y: 0.61 },
      { x: 0.3, y: 0.64 },
      { x: 0.1, y: 0.59 },
      { x: 0.67, y: 0.42 },
      { x: 0.33, y: 0.42 },
      { x: 0.73, y: 0.215 },
      { x: 0.5, y: 0.17 },
      { x: 0.27, y: 0.215 },
    ],
    labels: [
      "GK",
      "RWB",
      "CB",
      "CB",
      "CB",
      "LWB",
      "CM",
      "CM",
      "RW",
      "ST",
      "LW",
    ],
  },
};

export default function getFormation(formation: string) {
  return formationCoordinates[formation] || formationCoordinates["any"];
}
