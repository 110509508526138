import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { updateShowModal } from "../../../../redux/message/messageSlice";
import { AppDispatch } from "../../../../redux/store";
import { clearBuyIntent } from "../../../../redux/user/userSlice";
import { PremiumModal } from "../PremiumModal";

export const AlreadySubscriberModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  return (
    <PremiumModal
      title="You are already a subscriber"
      content="It looks like you are already an EasySBC subscriber. Thank you for supporting us! EasySBC wouldn't exist without people like you. We will now take you to the main page"
      onPositiveButtonClicked={() => {
        dispatch(clearBuyIntent());
        dispatch(updateShowModal({ modalType: "none" }));
        navigate("/");
      }}
      onCloseClicked={() => {
        dispatch(updateShowModal({ modalType: "none" }));
        dispatch(clearBuyIntent());
      }}
      positiveActionButtonLabel="Go to main page"
      negativeActionButtonLabel="Cancel"
    />
  );
};
