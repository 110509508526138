import { Sbc } from "../../../redux/sbcs/sbcsSlice";
import axios from "../../privateApi";

export const getSbc = async (
  sbcId: number,
  setId: number,
  platform?: string
): Promise<Sbc> => {
  const res = await axios.get(`/sbcs?setId=${setId}&id=${sbcId}`);
  return {
    ...res.data,
    reward: res.data?.rewards[0],
    price: platform === "Playstation" ? res.data?.psPrice : res.data?.pcPrice,
  };
};
