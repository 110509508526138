import axios from "axios";

import { setupInterceptorsTo } from "./interceptors";

const private_api = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL_FC24,
    headers: {
      "Content-Type": "application/json",
    },
  })
);

export default private_api;

export { logout } from "./privateRequests/user/logout";
export { createCheckoutSession } from "./privateRequests/stripe/createCheckoutSession";
export { getUser } from "./privateRequests/user/getUser";
export { patchUser } from "./privateRequests/user/patchUser";
export { getPlayers } from "./privateRequests/players/getPlayers";
export { deleteAllPlayers } from "./privateRequests/players/deleteAllPlayers";
export { deleteSpecificPlayers } from "./privateRequests/players/deleteSpecificPlayers";
export { createCustomerPortalSession } from "./privateRequests/stripe/createCustomerPortalSession";
export { voteSBCSet, undoVoteSBCSet } from "./privateRequests/sbcs/vote";
export { verifyPayment } from "./privateRequests/stripe/verify-payment";
export { getUserPlayers } from "./privateRequests/players/getUserPlayers";
export { getStats } from "./privateRequests/user/getStats";
export { trackReactivateSubscription } from "./privateRequests/stripe/trackReactivateSubscription";
export { markSbcSolved } from "./privateRequests/sbcs/markSbcSolved";
export { markSbcSetSolved } from "./privateRequests/sbcs/markSbcSetSolved";
export { getSbcSets } from "./privateRequests/sbcs/getSbcSets";
export { getSbcs } from "./privateRequests/sbcs/getSbcs";
export { getSbcSet } from "./privateRequests/sbcs/getSbcSet";
export { getSbc } from "./publicRequests/sbcs/getSbc";
export { playerSwap } from "./privateRequests/sbcs/player-swap";
export { hideSbcSet } from "./privateRequests/sbcs/hideSbcSet";
export { markSbcSetAsFavorite } from "./privateRequests/sbcs/markSbcSetAsFavorite";
