import axios from "../../apiFc24";

export type MetaRating = {
  metaRating: number;
  chemstyleId: number;
  chemistry: 0 | 1 | 2 | 3;
  isBestChemstyleAtChem?: boolean;
  archetypeId?: string;
};

type Props = {
  resourceId: number;
  archetypeId?: string;
  chemistry?: number;
  allChemStyles?: number;
  evoPath?: string;
  evoPathId?: number;
};
export const getMetaRatings = async ({
  resourceId,
  archetypeId,
  chemistry,
  allChemStyles,
  evoPath,
  evoPathId,
}: Props) => {
  const queryParams = new URLSearchParams();
  if (evoPath) queryParams.append("evolutionPath", evoPath);
  if (evoPathId) queryParams.append("evoPathId", "" + evoPathId);
  if (archetypeId) queryParams.append("archetypeId", archetypeId);
  if (chemistry >= 0) queryParams.append("chemistry", chemistry.toString());
  queryParams.append("resourceId", resourceId.toString());
  queryParams.append(
    "allChemStyles",
    allChemStyles ? allChemStyles.toString() : "0"
  );
  const url = `/squad-builder/meta-ratings?${queryParams.toString()}`;

  const res = await axios.get<MetaRating[]>(url);
  return res.data;
};
