import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { updateShowModal } from "../../../../redux/message/messageSlice";
import { AppDispatch } from "../../../../redux/store";
import {
  clearBuyIntent,
  getUserSelector,
} from "../../../../redux/user/userSlice";
import { PremiumModal } from "../PremiumModal";

export const SignInToSubscribeModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { buyIntent } = useSelector(getUserSelector);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <PremiumModal
      title="Sign in to Subscribe"
      content="You need a valid Google account to import players with our Chrome extension.
            Please sign in to verify your account and we will take you to payments after."
      positiveActionButtonLabel="Sign in"
      negativeActionButtonLabel="Cancel"
      onPositiveButtonClicked={() => {
        dispatch(updateShowModal({ modalType: "none" }));
        dispatch(clearBuyIntent());
        navigate("/login", {
          state: {
            location,
            buyIntent: buyIntent?.subscriptionId,
            discountId: buyIntent?.discountId,
          },
        });
      }}
      onCloseClicked={() => {
        dispatch(updateShowModal({ modalType: "none" }));
        dispatch(clearBuyIntent());
      }}
    />
  );
};
