import axios from "../../apiFc24";

export type FormationArchetypesResponse = { [key: string]: string[] };

export const getFormationArchetypes = async () => {
  const res = await axios.get<FormationArchetypesResponse>(
    "/squad-builder/archetypes-formations"
  );
  return res.data;
};
