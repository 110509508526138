import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { fireGTMEvent } from "../../../../functions/fireGTMEvent";
import { updateShowModal } from "../../../../redux/message/messageSlice";
import { AppDispatch } from "../../../../redux/store";
import { PremiumModal } from "../PremiumModal";

export const PremiumFeatureModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <PremiumModal
      title="This is a Premium Feature"
      content="In order to use this feature you need to have a Premium subscription"
      onPositiveButtonClicked={() => {
        fireGTMEvent("clicked_go_to_subscriptions");
        navigate("subscription");
        dispatch(updateShowModal({ modalType: "none" }));
      }}
      onCloseClicked={() => {
        dispatch(updateShowModal({ modalType: "none" }));
      }}
      positiveActionButtonLabel="Go to Subscriptions"
      negativeActionButtonLabel="Cancel"
    />
  );
};
