import { Solution } from "../../../redux/sbcs/sbcsSlice";
import axios from "../../privateApi";

export const solveSbc = async (
  sbc: number,
  hybrid: number,
  untradeable: boolean,
  excludeGoldPlayers: boolean,
  excludeSilverPlayers: boolean,
  excludeBronzePlayers: boolean,
  excludeActiveSquad: boolean,
  excludeSpecialPlayers: boolean,
  excludeObjectivePlayers: boolean,
  excludeSbcPlayers: boolean,
  includeTransferList: boolean,
  prioritizeDuplicates: boolean,
  includedPlayers: any[],
  excludedPlayers: any[]
): Promise<Solution> => {
  if (process.env.REACT_APP_LOCAL_SBC_SOLUTION)
    return {
      players: [
        {
          name: "Misa",
          resource_id: 258789,
          resource_base_id: "258789",
          // player_enum_id: 258789,
          attribute_array: [84, 77, 77, 80, 61, 84],
          rating: 82,
          chemistry: 2,
          position: "GK",
          position_in_formation: "GK",
          is_transfer_list: false,
          // out_of_position: false,

          price: 700,
          full_name: "Misa",
          nation: "Spain",
          nation_id: "45",
          league: "Liga F",
          // league_id: "2222",
          club: "Real Madrid",
          club_id: "243",
          card_code: "gold rare",

          metal: "gold",
          version: "Rare",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_1_3.png",
          country_png_name: "45.png",
          club_png_name: "243.png",
          player_png_name: "258789.png",
        },
        {
          name: "Koivisto",
          resource_id: 265286,
          resource_base_id: "265286",
          // player_enum_id: 265286,
          attribute_array: [76, 61, 69, 69, 82, 78],
          rating: 80,
          is_transfer_list: false,
          chemistry: 2,
          position: "RWB",
          position_in_formation: "RWB",
          // out_of_position: false,

          price: 750,
          full_name: "Emma Koivisto",
          nation: "Finland",
          nation_id: "17",
          league: "Barclays WSL",
          // league_id: "2216",
          club: "Liverpool",
          club_id: "9",
          card_code: "gold rare",

          metal: "gold",
          version: "Rare",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_1_3.png",
          country_png_name: "17.png",
          club_png_name: "9.png",
          player_png_name: "265286.png",
        },
        {
          name: "Morse",
          resource_id: 267274,
          resource_base_id: "267274",
          // player_enum_id: 267274,
          attribute_array: [53, 50, 65, 64, 80, 76],
          rating: 78,
          chemistry: 2,
          position: "CB",
          position_in_formation: "CB",
          // out_of_position: false,

          price: 500,
          full_name: "Zoe Morse",
          nation: "United States",
          nation_id: "95",
          league: "Barclays WSL",
          // league_id: "2216",
          club: "Brighton",
          club_id: "1808",
          is_transfer_list: false,
          card_code: "gold common",

          metal: "gold",
          version: "Common",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_0_3.png",
          country_png_name: "95.png",
          club_png_name: "1808.png",
          player_png_name: "267274.png",
        },
        {
          name: "Nouwen",
          resource_id: 246317,
          resource_base_id: "246317",
          // player_enum_id: 246317,
          attribute_array: [55, 50, 57, 58, 78, 82],
          rating: 78,
          chemistry: 3,
          position: "CB",
          position_in_formation: "CB",
          is_transfer_list: false,
          // out_of_position: false,

          price: 500,
          full_name: "Aniek Nouwen",
          nation: "Holland",
          nation_id: "34",
          league: "Barclays WSL",
          // league_id: "2216",
          club: "Chelsea",
          club_id: "5",
          card_code: "gold common",

          metal: "gold",
          version: "Common",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_0_3.png",
          country_png_name: "34.png",
          club_png_name: "5.png",
          player_png_name: "246317.png",
        },
        {
          name: "Sáez",
          resource_id: 245959,
          resource_base_id: "245959",
          // player_enum_id: 245959,
          attribute_array: [58, 34, 47, 47, 76, 78],
          rating: 76,
          chemistry: 2,
          position: "CB",
          is_transfer_list: false,
          position_in_formation: "CB",
          // out_of_position: false,

          price: 400,
          full_name: "Camila Sáez",
          nation: "Chile",
          nation_id: "55",
          league: "Liga F",
          // league_id: "2222",
          club: "Madrid CFF",
          club_id: "116334",
          card_code: "gold common",

          metal: "gold",
          version: "Common",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_0_3.png",
          country_png_name: "55.png",
          club_png_name: "116334.png",
          player_png_name: "245959.png",
        },
        {
          name: "Pacheco",
          resource_id: 265319,
          resource_base_id: "265319",
          is_transfer_list: false,
          // player_enum_id: 265319,
          attribute_array: [79, 51, 75, 78, 78, 77],
          rating: 81,
          chemistry: 3,
          position: "LWB",
          position_in_formation: "LWB",
          // out_of_position: false,

          price: 950,
          full_name: "Mayumi Pacheco",
          nation: "England",
          nation_id: "14",
          league: "Barclays WSL",
          // league_id: "2216",
          club: "Aston Villa",
          club_id: "2",
          card_code: "gold rare",

          metal: "gold",
          version: "Rare",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_1_3.png",
          country_png_name: "14.png",
          club_png_name: "2.png",
          player_png_name: "265319.png",
        },
        {
          name: "Cuthbert",
          resource_id: 245882,
          is_transfer_list: false,
          resource_base_id: "245882",
          // player_enum_id: 245882,
          attribute_array: [78, 80, 81, 82, 75, 69],
          rating: 83,
          chemistry: 3,
          position: "CM",
          position_in_formation: "CM",
          // out_of_position: false,

          price: 2000,
          full_name: "Erin Cuthbert",
          nation: "Scotland",
          nation_id: "42",
          league: "Barclays WSL",
          // league_id: "2216",
          club: "Chelsea",
          club_id: "5",
          card_code: "gold rare",

          metal: "gold",
          version: "Rare",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_1_3.png",
          country_png_name: "42.png",
          club_png_name: "5.png",
          player_png_name: "245882.png",
        },
        {
          name: "Nobbs",
          resource_id: 227257,
          resource_base_id: "227257",
          is_transfer_list: false,
          // player_enum_id: 227257,
          attribute_array: [75, 77, 81, 83, 67, 64],
          rating: 81,
          chemistry: 3,
          position: "CM",
          position_in_formation: "CM",
          // out_of_position: false,

          price: 950,
          full_name: "Jordan Nobbs",
          nation: "England",
          nation_id: "14",
          league: "Barclays WSL",
          // league_id: "2216",
          club: "Aston Villa",
          club_id: "2",
          card_code: "gold rare",

          metal: "gold",
          version: "Rare",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_1_3.png",
          country_png_name: "14.png",
          club_png_name: "2.png",
          player_png_name: "227257.png",
        },
        {
          name: "Andonova",
          resource_id: 272005,
          resource_base_id: "272005",
          is_transfer_list: false,
          // player_enum_id: 272005,
          attribute_array: [74, 80, 79, 81, 28, 64],
          rating: 81,
          chemistry: 3,
          position: "CAM",
          position_in_formation: "CAM",
          // out_of_position: false,

          price: 750,
          full_name: "Nataša Andonova",
          nation: "North Macedonia",
          nation_id: "19",
          league: "Liga F",
          // league_id: "2222",
          club: "Levante UD",
          club_id: "1853",
          card_code: "gold rare",

          metal: "gold",
          version: "Rare",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_1_3.png",
          country_png_name: "19.png",
          club_png_name: "1853.png",
          player_png_name: "272005.png",
        },
        {
          name: "Jensen",
          resource_id: 227369,
          resource_base_id: "227369",
          is_transfer_list: false,
          // player_enum_id: 227369,
          attribute_array: [80, 82, 74, 81, 28, 76],
          rating: 81,
          chemistry: 2,
          position: "ST",
          position_in_formation: "ST",
          // out_of_position: false,

          price: 750,
          full_name: "Synne Jensen",
          nation: "Norway",
          nation_id: "36",
          league: "Liga F",
          // league_id: "2222",
          club: "Real Sociedad",
          club_id: "457",
          card_code: "gold rare",

          metal: "gold",
          version: "Rare",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_1_3.png",
          country_png_name: "36.png",
          club_png_name: "457.png",
          player_png_name: "227369.png",
        },
        {
          name: "Gabi Nunes",
          resource_id: 238422,
          resource_base_id: "238422",
          // player_enum_id: 238422,
          attribute_array: [84, 80, 74, 81, 39, 84],
          rating: 81,
          chemistry: 3,
          position: "ST",
          position_in_formation: "ST",
          is_transfer_list: false,
          // out_of_position: false,

          price: 1100,
          full_name: "Gabi Nunes",
          nation: "Brazil",
          nation_id: "54",
          league: "Liga F",
          // league_id: "2222",
          club: "Levante UD",
          club_id: "1853",
          card_code: "gold rare",

          metal: "gold",
          version: "Rare",
          user_owned: false,
          duplicate: false,
          // first_owner: false,
          untradeable: false,
          card_png_name: "e_1_3.png",
          country_png_name: "54.png",
          club_png_name: "1853.png",
          player_png_name: "238422.png",
        },
      ],
      cost: 9350,
      chem: 28,
      rating: 81,
      untradeablesCount: 0,
      ownedPlayersCount: 0,
      duplicatesCount: 0,
      transferMarketCost: 9350,
      formation: "f433",
      solution_message: "abc",
    };

  const response = await axios.post("/sbcs", {
    id: sbc,
    hybrid,
    untradeable,
    excludeGoldPlayers,
    excludeSilverPlayers,
    excludeBronzePlayers,
    excludeActiveSquad,
    excludeSpecialPlayers,
    excludeObjectivePlayers,
    excludeSbcPlayers,
    includeTransferList,
    prioritizeDuplicates,
    includedPlayers,
    excludedPlayers,
  });
  return response.data;
};
