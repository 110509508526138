import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import { render } from "react-dom";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";

import App from "./App";
import "./_index.css";
import "./css/fonts.css";
import "./css/player-cards.css";
import "./css/webapp-formations.css";
import store from "./redux/store";

TagManager.initialize({
  gtmId: "GTM-MLMG3W4",
});

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY!!,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ["production"],
  // disable logging if not in production
  logger: process.env.NODE_ENV === "production" ? console : null,
});

const ErrorBoundary = Bugsnag.getPlugin("react")!!.createErrorBoundary(React);

const root = document.getElementById("root") as HTMLElement;

render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  root
);
