import {
  PayloadAction,
  createAsyncThunk, // PayloadAction, createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { APIStatus } from "../../api/APIStatus.type";
import { getNews } from "../../api/publicRequests/news/getNews";
// import { getNews } from "../../api/publicRequests/news/getNews";
import { IRootState } from "../store";

export type NewsType = {
  title: string;
  id: string;
  tags?: string[];
  publishedDate?: string;
  published?: boolean;
};
type State = {
  status: APIStatus;
  news: NewsType[];
};

const initialState: State = {
  status: "idle",
  news: [],
};

export const fetchNews = createAsyncThunk("news", async () => {
  const res = await getNews();
  let filteredRes = res.filter(
    (n) =>
      n.published ||
      process.env.REACT_APP_ENVIRONMENT === "dev" ||
      process.env.REACT_APP_ENVIRONMENT === "staging"
  );
  return filteredRes.map((n) => ({
    title: n.title,
    id: n.id,
    tags: n.tags,
    publishedDate: n.publishedDate,
  }));
});

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchNews.fulfilled,
      (state, action: PayloadAction<NewsType[]>) => {
        state.news = action.payload;
        state.status = "fulfilled";
      }
    );
    builder
      .addCase(fetchNews.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchNews.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export const newsSelector = (state: IRootState) => state.newsReducer;

export default newsSlice.reducer;
