import { Sbc } from "../../../redux/sbcs/sbcsSlice";
import axios from "../../privateApi";

type BackendSbc = {
  id: number;
  setId: number;
  name: string;
  categoryId: number;
  description: string;
  imageURL: string;
  psPrice: number;
  pcPrice: number;
  formation: string;
  formationName: string;
  rewards: any[];
  requirements: string[];
  sbcsCount: number;
  solved: boolean;
  message?: string;
};

export const getSbcs = async (
  setId: number,
  platform: string
): Promise<Sbc[]> => {
  const res = await axios.get<BackendSbc[]>(`/sbcs?setId=${setId}`);

  return res.data.map((sbc) => ({
    ...sbc,
    setId: setId,
    solvable: true,
    restricted: false,
    reward: sbc.rewards?.[0],
    isCompleted: sbc.solved,
    price: platform === "Playstation" ? sbc.psPrice : sbc.pcPrice,
  }));
};
