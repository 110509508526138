import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  current,
} from "@reduxjs/toolkit";

import { APIStatus } from "../../api/APIStatus.type";
import * as privateApi from "../../api/privateApi";
import { getSbcSets } from "../../api/privateApi";
import {
  RepeatabilityMode,
  SBCRewardType,
} from "../../api/privateRequests/sbcs/getSbcSets";
import { SortSBCSetBy } from "../../components/organisms/SBCs/SortSBCSets/SortSBCSets";
import { IRootState } from "../store";
import { Platform } from "../user/userSlice";

export type SbcCategories =
  | "All"
  | "Favorites"
  | "Players"
  | "Upgrades"
  | "Challenges"
  | "Icons"
  | "Foundations"
  | "Hidden";
export type SbcSet = {
  reward: SBCRewardType;
  id: number;
  imageURL: string;
  name: string;
  description: string;
  pcPrice: number;
  psPrice: number;
  message: string;
  sbcsCount: number;
  startTime: number;
  endTime: number;
  upVotes: number;
  downVotes: number;
  isNew: boolean;
  isHidden: boolean;
  isFavorite: boolean;
  hasRefreshed: boolean;
  timeRemaining: string;
  repeatable: boolean;
  refreshInterval?: number;
  repeats: number;
  categoryId: number;
  repeatabilityMode: RepeatabilityMode;
  unsolvable?: boolean;
  solvedCount: number;
  solved?: boolean;
  wasUpvoted?: boolean;
  wasDownvoted?: boolean;
  remainingCost: number | null;
};

type SBCState = {
  sbcsSets: {
    data: SbcSet[];
    status: APIStatus;
  };
  currentCategory: SbcCategories;
  currentSort: SortSBCSetBy;
};

export type SetVote = "UPVOTE" | "DOWNVOTE";

const initialState: SBCState = {
  sbcsSets: {
    status: "idle",
    data: [],
  },
  currentCategory: "All",
  currentSort: "Newest",
};

export const markSbcSetSolved = createAsyncThunk(
  "mark-sbc-set-solved",
  async (
    {
      setId,
      didComplete,
    }: {
      setId: number;
      didComplete: boolean;
    },
    { dispatch }
  ) => {
    dispatch(updateSbcSetSolved({ setId, didComplete }));
    privateApi.markSbcSetSolved(setId, didComplete);
  }
);

export const markSbcSolved = createAsyncThunk(
  "update-sbc-solved",
  async (
    {
      setId,
      sbcId,
      solved,
    }: {
      setId: number;
      sbcId: number;
      solved: boolean;
    },
    { getState, dispatch }
  ) => {
    const state = getState() as IRootState;

    const sbc = state.sbcsReducer.sbcs.find((sbc) => sbc.id === sbcId);
    const platform = state.userReducer.user.platform || "Playstation";
    dispatch(
      updateSbcSolved({
        setId,
        solved,
        priceOfSbc: sbc.price,
        platfrom: platform,
      })
    );
  }
);

export const hideSbcSet = createAsyncThunk(
  "hide-sbc-set",
  async (
    {
      setId,
      isHidden,
    }: {
      setId: number;
      isHidden: boolean;
    },
    { dispatch }
  ) => {
    dispatch(updateSbcSetHidden({ setId, isHidden }));
    privateApi.hideSbcSet(setId, isHidden);
  }
);
export const markSbcSetAsFavorite = createAsyncThunk(
  "hide-sbc-set",
  async (
    {
      setId,
      isFavorite,
    }: {
      setId: number;
      isFavorite: boolean;
    },
    { dispatch }
  ) => {
    dispatch(updateSbcSetFavorite({ setId, isFavorite }));
    privateApi.markSbcSetAsFavorite(setId, isFavorite);
  }
);

export const fetchSbcsSets = createAsyncThunk("sbc-sets", async () => {
  return getSbcSets();
});
const getCopyOfSet = (state: SBCState, setId: number) => {
  const sbcSetsCopy = [...current(state.sbcsSets.data)];
  let sbcSetIndex = sbcSetsCopy.findIndex((sbcSet) => sbcSet.id === setId);
  return {
    sbcSetIndex,
    sbcSetCopy: { ...sbcSetsCopy[sbcSetIndex] },
    sbcSetsCopy,
  };
};
const sbcSetsSlice = createSlice({
  name: "sbc-sets",
  initialState,
  reducers: {
    updateSbcSolved(
      state,
      action: PayloadAction<{
        setId: number;
        solved: boolean;
        priceOfSbc: number;
        platfrom: Platform;
      }>
    ) {
      const {
        setId,
        solved: solvedPayload,
        priceOfSbc,
        platfrom,
      } = action.payload;
      const { sbcSetsCopy, sbcSetIndex, sbcSetCopy } = getCopyOfSet(
        state,
        setId
      );
      if (solvedPayload && sbcSetCopy.solvedCount < +sbcSetCopy.sbcsCount) {
        sbcSetCopy.solvedCount += 1;
      } else {
        sbcSetCopy.solvedCount -= 1;
      }
      sbcSetCopy.solved = sbcSetCopy.solvedCount === +sbcSetCopy.sbcsCount;
      if (sbcSetCopy.remainingCost) {
        if (solvedPayload) {
          sbcSetCopy.remainingCost -= priceOfSbc;
        } else {
          if (sbcSetCopy.solvedCount === 0) {
            sbcSetCopy.remainingCost = null;
          } else {
            sbcSetCopy.remainingCost += priceOfSbc;
          }
        }
      } else {
        sbcSetCopy.remainingCost =
          platfrom === "Playstation"
            ? sbcSetCopy.psPrice - priceOfSbc
            : sbcSetCopy.pcPrice - priceOfSbc;
      }

      sbcSetsCopy[sbcSetIndex] = sbcSetCopy;
      state.sbcsSets.data = sbcSetsCopy;
    },
    updateSbcSetSolved(state, action) {
      const { setId, didComplete: solvedPayload } = action.payload;
      const { sbcSetsCopy, sbcSetIndex, sbcSetCopy } = getCopyOfSet(
        state,
        setId
      );
      if (solvedPayload) {
        sbcSetCopy.solvedCount = +sbcSetCopy.sbcsCount;
        sbcSetCopy.remainingCost = 0;
      } else {
        sbcSetCopy.solvedCount = 0;
      }
      sbcSetCopy.solved = solvedPayload;
      sbcSetsCopy[sbcSetIndex] = sbcSetCopy;

      state.sbcsSets.data = sbcSetsCopy;
    },
    upvote(state, action) {
      const { setId } = action.payload;

      const sbcSets = state.sbcsSets.data;

      const sbcSetIndex = sbcSets.findIndex((sbcSet) => +sbcSet.id === setId);

      if (sbcSets[sbcSetIndex].wasUpvoted) {
        privateApi.undoVoteSBCSet({ setId, voteType: "UPVOTE" });
        sbcSets[sbcSetIndex].upVotes -= 1;
      } else {
        privateApi.voteSBCSet({ setId, voteType: "UPVOTE" });
        sbcSets[sbcSetIndex].upVotes += 1;
      }

      if (sbcSets[sbcSetIndex].wasDownvoted)
        sbcSets[sbcSetIndex].downVotes -= 1;

      sbcSets[sbcSetIndex].wasDownvoted = false;
      sbcSets[sbcSetIndex].wasUpvoted = !sbcSets[sbcSetIndex].wasUpvoted;
    },
    downvote(state, action) {
      const { setId } = action.payload;

      const sbcSets = state.sbcsSets.data;

      const sbcSetIndex = sbcSets.findIndex((sbcSet) => +sbcSet.id === setId);

      if (sbcSets[sbcSetIndex].wasDownvoted) {
        privateApi.undoVoteSBCSet({ setId, voteType: "DOWNVOTE" });
        sbcSets[sbcSetIndex].downVotes -= 1;
      } else {
        privateApi.voteSBCSet({ setId, voteType: "DOWNVOTE" });
        sbcSets[sbcSetIndex].downVotes += 1;
      }

      if (sbcSets[sbcSetIndex].wasUpvoted) sbcSets[sbcSetIndex].upVotes -= 1;

      sbcSets[sbcSetIndex].wasUpvoted = false;

      sbcSets[sbcSetIndex].wasDownvoted = !sbcSets[sbcSetIndex].wasDownvoted;
    },
    clearSbcSets(state) {
      state.sbcsSets.data = [];
    },
    updateCategory(state, action: PayloadAction<SbcCategories>) {
      state.currentCategory = action.payload;
    },
    updateSort(state, action: PayloadAction<SortSBCSetBy>) {
      state.currentSort = action.payload;
    },
    updateSbcSetHidden(
      state,
      action: PayloadAction<{
        setId: number;
        isHidden: boolean;
      }>
    ) {
      state.sbcsSets.data = state.sbcsSets.data.map((set) =>
        set.id === action.payload.setId
          ? { ...set, isHidden: action.payload.isHidden }
          : set
      );
    },
    updateSbcSetFavorite(
      state,
      action: PayloadAction<{
        setId: number;
        isFavorite: boolean;
      }>
    ) {
      state.sbcsSets.data = state.sbcsSets.data.map((set) =>
        set.id === action.payload.setId
          ? { ...set, isFavorite: action.payload.isFavorite }
          : set
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSbcsSets.pending, (state) => {
        state.sbcsSets.status = "pending";
      })
      .addCase(fetchSbcsSets.fulfilled, (state, action) => {
        state.sbcsSets.status = "fulfilled";
        if (action.payload) state.sbcsSets.data = action.payload;
      })
      .addCase(fetchSbcsSets.rejected, (state) => {
        state.sbcsSets.status = "rejected";
      });
  },
});

export const {
  clearSbcSets,
  updateSbcSolved,
  updateSbcSetSolved,
  updateSbcSetHidden,
  updateSbcSetFavorite,
  upvote,
  downvote,
  updateCategory,
  updateSort,
} = sbcSetsSlice.actions;
export const getSbcSetsSelector = (state: IRootState) => state.sbcsSetsReducer;
export default sbcSetsSlice.reducer;
