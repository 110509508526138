import clsx from "clsx";
import { useEffect, useState } from "react";

import { useEscape } from "../../../hooks/useKeyboardShortcut";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

type Props = {
  title: string;
  content: string;
  onPositiveButtonClicked: () => void;
  onCloseClicked: () => void;
  positiveActionButtonLabel: string;
  negativeActionButtonLabel?: string;
};

export const PremiumModal = ({
  title,
  content,
  onPositiveButtonClicked,
  onCloseClicked,
  positiveActionButtonLabel,
  negativeActionButtonLabel,
}: Props) => {
  const ref = useOutsideClick(() => onCloseClicked());
  useEscape(onCloseClicked);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, 50);
  }, []);

  return (
    <div
      className={clsx(
        "backdrop-blur-sm backdrop-brightness-50 fixed inset-0 flex z-[100] px-4 transition ease-in-out",
        fadeIn ? "opacity-100" : "opacity-0"
      )}
    >
      <div
        ref={ref}
        className={clsx(
          "text-white m-auto mt-[20vh] flex flex-col rounded-lg w-[450px] transition ease-in-out",
          fadeIn ? "translate-y-4" : "translate-y-0",
          "bg-[linear-gradient(134.9deg,_#2B2A2A_31.71%,_#0D0D0D_80.71%)]",
          "shadow-[1px_2px_1px_0px_#ffffff40_inset,_-1px_-2px_1px_0px_#00000040_inset]"
        )}
        style={{
          filter: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.5))",
        }}
      >
        <div className="relative h-24">
          <div
            className={clsx(
              "bg-cover rounded-t-lg h-full w-full",
              "bg-[url(https://cdn.easysbc.io/fut23/misc/modal-bg.png)]",
              "shadow-[1px_2px_1px_0px_#ffffff40_inset]"
            )}
            style={{
              filter: "drop-shadow(0px 2px 0px rgba(101, 209, 255, 0.25))",
            }}
          />
          <div className="absolute h-full top-0 left-[5%] flex text-white text-2xl font-bold">
            <div className="my-auto">{title}</div>
          </div>
        </div>
        <div className="px-[5%] py-[3%] flex flex-col">
          <div className="text-left text-brand-meta-orange">{content}</div>
          <div className="flex justify-end gap-x-2 pt-[10%]">
            <button
              className={clsx(
                "bg-[linear-gradient(#494949,_#5E5D5C)] p-2 rounded-md  font-bold",
                "hover:bg-[linear-gradient(0deg,_#ffffff4d_0%,_#ffffff4d_100%),_linear-gradient(294deg,_#494949_25.98%,_#5E5D5C_69.8%)]"
              )}
              onClick={onCloseClicked}
              style={{
                boxShadow:
                  "-3.19999px 3.19999px 2.56px 0px rgba(0, 0, 0, 0.50)",
              }}
            >
              {negativeActionButtonLabel}
            </button>

            <button
              className={clsx(
                "rounded-md p-2 font-bold",
                "bg-[linear-gradient(294deg,_#EF8241_25.98%,_#FFA02E_69.8%)]",
                "hover:drop-shadow-[0px_0px_5px_#F90]"
              )}
              onClick={onPositiveButtonClicked}
              style={{
                boxShadow:
                  "-3.19999px 3.19999px 2.56px 0px rgba(0, 0, 0, 0.50)",
              }}
            >
              {positiveActionButtonLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
