import axios from "../publicApi";

export const loginWithSessionId = async (sessionId: string) => {
  const res = await axios.post("/auth/login-with-session-id", {
    sessionId,
  });
  localStorage.setItem(
    "access_token",
    JSON.stringify(res.data["access_token"])
  );
};
