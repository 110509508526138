import axios from "../../apiFc24";

export const updatePlayerArchetype = (
  archetypeId: string,
  formationId: string,
  positionIndex: number
) =>
  axios.post("/squad-builder/user-archetypes", {
    archetypeId,
    formationId,
    positionIndex,
  });
