import { FromBackendSquadBuilderPlayer } from "../../../redux/sbcPlayers/SearchedPlayer.type";
import { Manager, Metal } from "../../../redux/squadBuilder/squadBuilderSlice";
import axios from "../../apiFc24";

export type SuggestSquadsResponse = {
  chemistry: number;
  rating: number;
  metaRating: number;
  price: number;
  chemistryFormation: string;
  players: FromBackendSquadBuilderPlayer[];
  manager: Manager;
}[];

export const suggestSquads = (
  playersFrom: "market" | "both" | "club",
  players: { resourceId?: number; archetypeId: string }[],
  formationId: string,
  budget: number,
  metal: Metal,
  country: number[],
  league: number[],
  club: number[],
  playerFilters: undefined[] | any[]
) => {
  return axios.post<SuggestSquadsResponse>("/squad-builder", {
    playersFrom,
    players,
    formationId,
    budget,
    metal: metal !== "none" ? [metal] : [],
    country,
    league,
    club,
    playerFilters,
  });
};
