import { configureStore } from "@reduxjs/toolkit";

import reducer from "./rootReducer";

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    /* 
    Right now, I am putting a JSX element in the store when dispatching a message. 
    It gives a warning because it isn't serializible. It is a bit of an anti-pattern
    but it doesn't do anything bad except debugging this particular JSX component is a bit harder 
    in a Redux context.
    */
    getDefaultMiddleware({ serializableCheck: false }),
  preloadedState: {},
});

export type IRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
