import { User } from "../redux/user/userSlice";
import { CHROME_EXTENSION_VERSION } from "../settings";
import { fireGTMEvent } from "./fireGTMEvent";

type Props = {
  user?: User;
  doneRequest?: boolean;
};
export const sendMessageToExtension = async ({
  user,
  doneRequest = false,
}: Props): Promise<any> => {
  let data: any = {
    dev: process.env.REACT_APP_EXTENSION_ENV === "dev",
    staging: process.env.REACT_APP_EXTENSION_ENV === "staging",
    allowTracking: !!user?.allowTracking,
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`,
  };
  if (doneRequest) {
    data = { doneRequest: true, ...data };
  } else {
    data = { userRequest: true, ...data };
  }

  return new Promise((resolve) => {
    window.chrome?.runtime?.sendMessage(
      process.env.REACT_APP_EXTENSION_ID!,
      data,
      (res) => {
        const version = res?.version;
        localStorage.setItem(CHROME_EXTENSION_VERSION, version);
        fireGTMEvent("chrome_extension_version_" + version);
        resolve(res);
      }
    );
  });
};
