import axios from "../../privateApi";

export const createCustomerPortalSession = () => {
  return axios
    .post("/payments/create-customer-portal-session")
    .then((response) => {
      if (response.data.status === 303) {
        window.location.href = response.data.url;
      }
    });
};
