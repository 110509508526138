import axios from "../../apiFc24";
import { MetaRating } from "./get-meta-ratings";

type ResponseType = {
  manager: {
    country: number;
    league: number;
  };
  squadName: string;
  currentFormation: string;
  currentChemistry: number;
  rating: number;
  players: Array<{
    resourceId: number;
    untradeable: boolean;
    cardName: string;
    clubId: number;
    countryId: number;
    leagueId: number;
    rating: number;
    preferredPosition: string;
    possiblePositions: string[];
    price: number;
    metaRatings: MetaRating[];
    metalId: number;
    versionId: number;
    attributes: number[];
    hasDynamicImage: boolean;
    playerUrl: string;
  }>;
};

export const importClubFromEA = async (eaId: string) => {
  const res = await axios.post<Promise<ResponseType>>(
    "/players/import-with-ea-link",
    {
      eaId,
    }
  );
  return res.data;
};
