import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL_FC24,
});

export default instance;

export { solveSbc } from "./publicRequests/sbcs/solve-sbc";
export { login } from "./publicRequests/login";
export { sendMessage } from "./publicRequests/user/sendMessage";
export { createCheckoutSessionNewUser } from "./publicRequests/createCheckoutNewUser";
export { loginWithSessionId } from "./publicRequests/loginWithSessionId";
export { loginWithEmail } from "./publicRequests/login-with-email";
export { confirmLogin } from "./publicRequests/confirm-login";
export { createCheckoutSession } from "./publicRequests/stripe/createCheckoutSession";
export { loginWithSessionIdFailedAlert } from "./publicRequests/stripe/loginWithSessionIdFailedAlert";
export { unsubscribeEmail } from "./publicRequests/user/unsubscribe-email";
