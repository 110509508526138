import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { APIStatus } from "../../api/APIStatus.type";
import { getEvolutions } from "../../api/publicRequests/evolutions/getEvolutions";
import { IRootState } from "../store";

export type Requirement = {
  label: string;
  value: string;
};

export type Upgrade = {
  label: string;
  value: string;
};

export type Cost = {
  coins: number;
  points: number;
};

export type Evolution = {
  id: number;
  name: string;
  description: string;
  requirements: Requirement[];
  upgrades: Upgrade[];
  endTime: number;
  newEvolution: boolean;
  processingState: 1 | 2 | 3;
  cost?: Cost;
};

type EvolutionsState = {
  status: APIStatus;
  evolutions: Evolution[];
};

const initialState: EvolutionsState = {
  status: "idle",
  evolutions: [],
};

export const fetchEvolutions = createAsyncThunk("evolutions", async () => {
  return await getEvolutions();
});

const evolutionsSlice = createSlice({
  name: "evolutions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvolutions.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchEvolutions.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.evolutions = action.payload;
      })
      .addCase(fetchEvolutions.rejected, (state) => {
        state.status = "rejected";
      });
  },
});

export const evolutionsSelector = (state: IRootState) =>
  state.evolutionsReducer.evolutions;

export default evolutionsSlice.reducer;
