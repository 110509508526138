export const formatTime = (timeDiff: number) => {
  const daysRemaining = Math.floor(timeDiff / 1000 / 60 / 60 / 24);
  const hoursRemaining = Math.floor(timeDiff / 1000 / 60 / 60);
  const minutesRemaining = Math.floor(timeDiff / 1000 / 60);

  let timeRemaining = minutesRemaining;
  let unit = "Minute";

  if (daysRemaining >= 1) {
    timeRemaining = daysRemaining;
    unit = "Day";
  } else if (hoursRemaining >= 1) {
    timeRemaining = hoursRemaining;
    unit = "Hour";
  }
  if (timeRemaining > 1) unit += "s";
  return `${timeRemaining} ${unit}`;
};

export const formatRefreshTime = (timeDiff: number) => {
  const daysRemaining = Math.floor(timeDiff / 1000 / 60 / 60 / 24);
  const hoursRemaining = Math.floor(timeDiff / 1000 / 60 / 60);
  const minutesRemaining = Math.floor(timeDiff / 1000 / 60);

  let timeRemaining = minutesRemaining;
  let unit = "Minute";

  if (daysRemaining === 7) return "Weekly";
  else if (hoursRemaining === 24) return "Daily";
  else if (daysRemaining >= 1) {
    timeRemaining = daysRemaining;
    unit = "Days";
  } else if (hoursRemaining >= 1) {
    timeRemaining = hoursRemaining;
    unit = "Hours";
  }

  return `Every ${timeRemaining} ${unit}`;
};
