import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { updateShowModal } from "../../../../redux/message/messageSlice";
import { AppDispatch } from "../../../../redux/store";
import { PremiumModal } from "../PremiumModal";

export const EliteOrUltimateModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <PremiumModal
      title="This Feature requires an Elite or Ultimate Subscription"
      content="In order to use this feature you need to have an Elite or Ultimate subscription"
      onPositiveButtonClicked={() => {
        navigate("subscription");
        dispatch(updateShowModal({ modalType: "none" }));
      }}
      onCloseClicked={() => {
        dispatch(updateShowModal({ modalType: "none" }));
      }}
      positiveActionButtonLabel="Go to Subscriptions"
      negativeActionButtonLabel="Cancel"
    />
  );
};
