import { formatTime } from "../../../functions/formatTime";
import axios from "../../privateApi";

export type RewardType = "pack" | "item" | "playerpick" | "player";

export type SBCRewardType = {
  type: RewardType;
  resourceId?: number;
  shortName?: string;
  name?: string;
  description?: string;
  shortDescription?: string;
  packImageURL?: string;
  rewardImgURL?: string;
  untradeable: boolean;
  metaRating?: {
    metarating: number;
    archetypeId: string;
  };
};

export type RepeatabilityMode = "NON_REPEATABLE" | "UNLIMITED" | "REFRESH";
type BackendSbcSet = {
  active: boolean;
  categoryId: number;
  description: string;
  downVotes: number;
  endTime: number;
  id: number;
  imageURL: string;
  isNew: boolean;
  isFavorite: boolean;
  isHidden: boolean;
  hasRefreshed: boolean;
  name: string;
  pcPrice: number;
  psPrice: number;
  repeatable: boolean;
  rewards: SBCRewardType[];
  sbcsCount: number;
  solved: boolean;
  solvedCount: number;
  solvedsbcs: any[];
  startTime: number;
  upVotes: number;
  wasDownvoted: boolean;
  wasUpvoted: boolean;
  repeatabilityMode: RepeatabilityMode;
  remainingCost: number | null;
  refreshInterval?: number;
  repeats?: number;
  message?: string;
  unsolvable?: boolean;
};

export const getSbcSets = async () => {
  try {
    const res = await axios.get<BackendSbcSet[]>("/sbc-sets?page=1&limit=20");
    const sbcSets = res.data.map((sbcSet) => {
      const reward = sbcSet.rewards && sbcSet.rewards[0];

      let timeRemaining: string;
      if (sbcSet.endTime) {
        const endDay = new Date(sbcSet.endTime * 1000);
        const today = new Date();
        const timeDiff = endDay.getTime() - today.getTime();
        timeRemaining = formatTime(timeDiff);
      }

      return {
        ...sbcSet,
        timeRemaining,
        reward,
        message: sbcSet.message,
        repeats: sbcSet.repeats || 0,
        unsolvable: sbcSet.unsolvable,
      };
    });
    return sbcSets;
  } catch {
    return [];
  }
};
