import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ReactGA from "react-ga4";

import { APIStatus } from "../../api/APIStatus.type";
import * as apiFc24 from "../../api/apiFc24";
import { SuggestSquadsResponse } from "../../api/privateRequests/squad-builder/build-squad";
import { FormationArchetypesResponse } from "../../api/privateRequests/squad-builder/get-formation-archetypes";
import { MetaRating } from "../../api/privateRequests/squad-builder/get-meta-ratings";
import { RecommendedPlayersAtPosition } from "../../api/privateRequests/squad-builder/recommend-players";
import {
  PlayersChemistry,
  calculateChemistry,
} from "../../functions/calculateChemistry";
import { calculateRating } from "../../functions/calculateRating";
import getFormation from "../../functions/getFormation";
import { defaultPositions } from "../../types/defaultPositions";
import {
  LeagueCountryClubData,
  LeagueCountryData,
} from "../loadedContent/loadedContentSlice";
import { IRootState } from "../store";

export type SquadBuilderPlayer = {
  resourceId: number;
  eaPlayerId?: number;
  clubId: number;
  leagueId: number;
  countryId: number;
  rating: number;
  price: number;
  preferredPosition: string;
  possiblePositions: string[];
  owned: boolean;
  cardName: string;
  untradeable: boolean;
  versionId: number;
  metalId: number;
  attributes: number[];
  chemIndex?: number;
  playStylesPlus: number[];
  chemPosition?: string;
  userOwned?: boolean;
  isAIPlayer?: boolean;
  metaRatings: MetaRating[];
  hasDynamicImage: boolean;
  playerUrl: string;
  sbcPrice: number;
  isObjectivePlayer: boolean;
  isSbcPlayer: boolean;
  canEvolve: boolean;
  evolutionPath?: number[];
  evoPathId?: number;
};

export type Manager = {
  country?: number;
  league?: number;
};

export type MinMaxFilter = {
  min?: number;
  max?: number;
};

export type SidebarOption = {
  type:
    | "chemStyle"
    | "playerRecOrSearch"
    | "customFilter"
    | "moreSquads"
    | "playerRoles"
    | "evolve"
    | "closed";
  playerIndex?: number;
};

export interface ChoiceFilter<T extends String | Number> {
  values: T[];
}

export type OtherAttributes = {
  skillMoves?: ChoiceFilter<number>;
  weakFoot?: ChoiceFilter<number>;
  defensiveWorkRate?: ChoiceFilter<string>;
  attackingWorkRate?: ChoiceFilter<string>;
  preferredFoot?: ChoiceFilter<string>;
  height?: MinMaxFilter;
};

type InGameAttributes = {
  acceleration?: MinMaxFilter;
  sprintSpeed?: MinMaxFilter;
  agility?: MinMaxFilter;
  balance?: MinMaxFilter;
  jumping?: MinMaxFilter;
  stamina?: MinMaxFilter;
  strength?: MinMaxFilter;
  reactions?: MinMaxFilter;
  aggression?: MinMaxFilter;
  composure?: MinMaxFilter;
  interceptions?: MinMaxFilter;
  positioning?: MinMaxFilter;
  vision?: MinMaxFilter;
  ballControl?: MinMaxFilter;
  crossing?: MinMaxFilter;
  dribbling?: MinMaxFilter;
  finishing?: MinMaxFilter;
  freeKickAccuracy?: MinMaxFilter;
  headingAccuracy?: MinMaxFilter;
  longPassing?: MinMaxFilter;
  shortPassing?: MinMaxFilter;
  defensiveAwareness?: MinMaxFilter;
  shotPower?: MinMaxFilter;
  longShots?: MinMaxFilter;
  standingTackles?: MinMaxFilter;
  slidingTackles?: MinMaxFilter;
  volleys?: MinMaxFilter;
  curve?: MinMaxFilter;
  penalties?: MinMaxFilter;
  gkDiving?: MinMaxFilter;
  gkHandling?: MinMaxFilter;
  gkKicking?: MinMaxFilter;
  gkReflexes?: MinMaxFilter;
  gkPositioning?: MinMaxFilter;
  traits?: MinMaxFilter;
  specialties?: MinMaxFilter;
};

export type PlayerFilter = {
  inGameAttributes: InGameAttributes;
  otherAttributes: OtherAttributes;
};

export type SquadBuilderPlayers = [
  SquadBuilderPlayer | undefined,
  SquadBuilderPlayer | undefined,
  SquadBuilderPlayer | undefined,
  SquadBuilderPlayer | undefined,
  SquadBuilderPlayer | undefined,
  SquadBuilderPlayer | undefined,
  SquadBuilderPlayer | undefined,
  SquadBuilderPlayer | undefined,
  SquadBuilderPlayer | undefined,
  SquadBuilderPlayer | undefined,
  SquadBuilderPlayer | undefined
];

export type PlayersFrom = "market" | "both" | "club";
export type Metal = "none" | "bronze" | "silver" | "gold";

export type ShowDetailedPlayerView = {
  resourceId: number;
  initialChem?: 0 | 1 | 2 | 3;
  initialChemStyle?: number;
  initialMetaRating?: number;
  initialPlayerRole?: string;
  initialPlayerRoleLabel?: string;
  initialPositionIndex?: number;
  initialEaPlayerId?: number;
  isEvolutions?: boolean;
  initialEvoPath?: string;
  initialEvoPathId?: number;
};
export type Position = {
  id: number;
  category: string;
  label: string;
};

export type Playstyle = {
  id: number;
  label: string;
  category: string;
};

type SquadBuilder = {
  budget: number;
  formation: string;
  tempFormation: string;
  playersFrom: PlayersFrom;
  metal: Metal;
  inputCountries: LeagueCountryData[];
  inputLeagues: LeagueCountryData[];
  inputClubs: LeagueCountryClubData[];
  inputPositions: Position[];
  inputPlaystyles: Playstyle[];
  players: SquadBuilderPlayers;
  tempPlayers: SquadBuilderPlayers;
  sidebarOption: SidebarOption;
  formationArchetypes: FormationArchetypesResponse;
  status: {
    recommendPlayers?: APIStatus;
    suggestSquad?: APIStatus;
    importSquad?: APIStatus;
  };
  manager: Manager | undefined;
  tempManager: Manager | undefined;
  recommendedPlayers: RecommendedPlayersAtPosition[];
  suggestedSquads: SuggestSquadsResponse;
  importedPlayersFromEA?: any[];
  countries: LeagueCountryData[];
  leagues: LeagueCountryData[];
  clubs: LeagueCountryClubData[];
  playerFilters: PlayerFilter[];
  showDetailedPlayerView?: ShowDetailedPlayerView;
  activePlayerMobile?: number;
};

export const inGameAttributeCategories = [
  "PACE",
  "SHOOTING",
  "PASSING",
  "DRIBBLING",
  "DEFENDING",
  "PHYSICALITY",
  "GOALKEEPING",
] as const;

export type InGameAttributeCategory = typeof inGameAttributeCategories[number];

const setEmptyPlayerFilters = () => [
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
  setEmptyAttributes(),
];

type OtherAttributeSetting = {
  label: string;
  options?: (string | number)[];
  exclusive?: boolean;
};

export const otherAttributeSettings: { [key: string]: OtherAttributeSetting } =
  {
    skillMoves: {
      label: "Skill Moves",
      options: [1, 2, 3, 4, 5],
    },
    weakFoot: {
      label: "Weak Foot",
      options: [1, 2, 3, 4, 5],
    },
    defensiveWorkRate: {
      label: "Defensive Work Rate",
      options: ["Low", "Medium", "High"],
    },
    attackingWorkRate: {
      label: "Attacking Work Rate",
      options: ["Low", "Medium", "High"],
    },
    preferredFoot: {
      label: "Preferred Foot",
      options: ["Left", "Right"],
      exclusive: true,
    },
    height: { label: "Height" },
  };

export const inGameAttributes = {
  pace: { label: "PACE", category: "PACE" },
  dribblingTitle: { label: "DRIBBLING", category: "DRIBBLING" },
  physicality: { label: "PHYSICALITY", category: "PHYSICALITY" },
  defending: { label: "DEFENDING", category: "DEFENDING" },
  shooting: { label: "SHOOTING", category: "SHOOTING" },
  passing: { label: "PASSING", category: "PASSING" },
  acceleration: { label: "Acceleration", category: "PACE" },
  sprintSpeed: { label: "Sprint Speed", category: "PACE" },
  agility: { label: "Agility", category: "DRIBBLING" },
  balance: { label: "Balance", category: "DRIBBLING" },
  jumping: { label: "Jumping", category: "PHYSICALITY" },
  stamina: { label: "Stamina", category: "PHYSICALITY" },
  strength: { label: "Strength", category: "PHYSICALITY" },
  reactions: { label: "Reactions", category: "DRIBBLING" },
  aggression: { label: "Aggression", category: "PHYSICALITY" },
  composure: { label: "Composure", category: "DRIBBLING" },
  interceptions: { label: "Interceptions", category: "DEFENDING" },
  positioning: { label: "Positioning", category: "SHOOTING" },
  vision: { label: "Vision", category: "PASSING" },
  ballControl: { label: "Ball Control", category: "DRIBBLING" },
  crossing: { label: "Crossing", category: "PASSING" },
  dribbling: { label: "Dribbling", category: "DRIBBLING" },
  finishing: { label: "Finishing", category: "SHOOTING" },
  freeKickAccuracy: { label: "FK Accuracy", category: "PASSING" },
  headingAccuracy: { label: "Heading Accuracy", category: "DEFENDING" },
  longPassing: { label: "Long Passing", category: "PASSING" },
  shortPassing: { label: "Short Passing", category: "PASSING" },
  defensiveAwareness: { label: "Def. Awareness", category: "DEFENDING" },
  shotPower: { label: "Shot Power", category: "SHOOTING" },
  longShots: { label: "Long Shots", category: "SHOOTING" },
  standingTackles: { label: "Standing Tackles", category: "DEFENDING" },
  slidingTackles: { label: "Sliding Tackles", category: "DEFENDING" },
  volleys: { label: "Volleys", category: "SHOOTING" },
  curve: { label: "Curve", category: "PASSING" },
  penalties: { label: "Penalties", category: "SHOOTING" },
  gkDiving: { label: "GK Diving", category: "GOALKEEPING" },
  gkHandling: { label: "GK Handling", category: "GOALKEEPING" },
  gkKicking: { label: "GK Kicking", category: "GOALKEEPING" },
  gkReflexes: { label: "GK Reflexes", category: "GOALKEEPING" },
  gkPositioning: { label: "GK Positioning", category: "GOALKEEPING" },
  // traits: { label: "Traits", category: "MISC" },
  // specialties: { label: "Specialties", category: "MISC" },
};

// {acceleration: {}, sprintSpeed: {} ...}
export const setEmptyInGameAttributes = (): InGameAttributes =>
  Object.keys(inGameAttributes).reduce((a, v) => ({ ...a, [v]: {} }), {});

export const setEmptyOtherAttributes = (): OtherAttributes =>
  Object.keys(otherAttributeSettings).reduce((a, v) => ({ ...a, [v]: [] }), {});

const setEmptyAttributes = () => ({
  inGameAttributes: setEmptyInGameAttributes(),
  otherAttributes: setEmptyOtherAttributes(),
});

const setEmptyData = () => [
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
];

const getCachedOrEmptyPlayers = () =>
  localStorage.getItem("players")
    ? JSON.parse(localStorage.getItem("players"))
    : setEmptyData();

const initialState: SquadBuilder = {
  budget: Number.MAX_VALUE,
  formation: localStorage.getItem("formation") || "f442",
  tempFormation: "",
  playersFrom: "market",
  metal: "none",
  inputClubs: [],
  inputLeagues: [],
  inputCountries: [],
  inputPositions: [...defaultPositions],
  inputPlaystyles: [],
  players: getCachedOrEmptyPlayers() as SquadBuilderPlayers,
  tempPlayers: setEmptyData() as SquadBuilderPlayers,
  formationArchetypes: {},
  status: {
    recommendPlayers: "idle",
    suggestSquad: "idle",
  },
  manager: localStorage.getItem("manager")
    ? JSON.parse(localStorage.getItem("manager"))
    : undefined,
  tempManager: undefined,
  recommendedPlayers: [],
  sidebarOption: {
    type: "closed",
  },
  suggestedSquads: [],
  countries: [],
  leagues: [],
  clubs: [] as (LeagueCountryClubData & {
    league: number;
  })[],
  playerFilters: setEmptyPlayerFilters(),
  showDetailedPlayerView: undefined,
  activePlayerMobile: undefined,
};

export const getFormationArchetypes = createAsyncThunk(
  "archetype-info",
  async () => {
    return apiFc24.getFormationArchetypes();
  }
);

export const importSquad = createAsyncThunk(
  "import-squad",
  async ({ squadId }: { squadId?: string }) => {
    if (squadId) return apiFc24.importClubFromEA(squadId);
    return apiFc24.importSquad();
  }
);

export const suggestSquads = createAsyncThunk<SuggestSquadsResponse>(
  "suggest-squads",
  async (args, { getState }) => {
    const state = getState() as IRootState;
    const archetypes = formationArchetypesSelector(state);
    const allPlayersFilled = state.squadBuilderReducer.players.every(
      (p) => p?.resourceId
    );

    const players = state.squadBuilderReducer.players.map((p, i) =>
      allPlayersFilled
        ? { archetypeId: archetypes[i] }
        : {
            resourceId: p?.resourceId,
            archetypeId: archetypes[i],
            eaPlayerId: p?.eaPlayerId,
          }
    );

    let res = await apiFc24.suggestSquads(
      state.squadBuilderReducer.playersFrom,
      players,
      state.squadBuilderReducer.formation,
      state.squadBuilderReducer.budget,
      state.squadBuilderReducer.metal,
      state.squadBuilderReducer.inputCountries[0]?.id
        ? [state.squadBuilderReducer.inputCountries[0]?.id]
        : [],
      state.squadBuilderReducer.inputLeagues[0]?.id
        ? [state.squadBuilderReducer.inputLeagues[0]?.id]
        : [],
      state.squadBuilderReducer.inputClubs[0]?.id
        ? [state.squadBuilderReducer.inputClubs[0]?.id]
        : [],
      state.squadBuilderReducer.playerFilters
    );

    return res.data.map((s) => {
      return { ...s, players: s.players };
    });
  }
);

export const getMetaRatingsForPlayer = async (
  dispatch: any,
  resourceId: number,
  positionIndex: number,
  archetypeId?: string,
  allChemStyles?: number,
  evoPath?: string,
  evoPathId?: number
) => {
  const metaRatings = await apiFc24.getMetaRatings({
    resourceId,
    archetypeId,
    allChemStyles,
    evoPath,
    evoPathId,
  });

  dispatch(
    updatePlayerMetaRatings({
      index: positionIndex,
      metaRatings,
    })
  );
};
export const getMetaRatingsForPlayers = createAsyncThunk(
  "get-meta-ratings-for-players",
  async (_, { getState, dispatch }) => {
    const state = getState() as IRootState;
    const archetypes =
      state.squadBuilderReducer.formationArchetypes[
        state.squadBuilderReducer.formation
      ];

    const players = state.squadBuilderReducer.players.map((p, i) => {
      if (!p) return null;
      const archetypeId = archetypes[i];
      const { resourceId } = p;

      return { resourceId, archetypeId, evoPathId: p.evoPathId };
    });

    const playersMetaRatings = await apiFc24.getPlayersMetaRatings(players);
    for (let i = 0; i < playersMetaRatings.length; i++) {
      const metaRatings = playersMetaRatings[i];
      if (!metaRatings) continue;
      dispatch(
        updatePlayerMetaRatings({
          index: i,
          metaRatings,
        })
      );
    }
  }
);

export const updatePlayerArchetype = createAsyncThunk(
  "update-player-archetype",
  async (
    args: {
      positionIndex: number;
      archetypeId: string;
    },
    { getState, dispatch }
  ) => {
    const { positionIndex, archetypeId } = args;
    const state = getState() as IRootState;
    const isLoggedIn = !!state.userReducer.user.uuid;
    const formation = state.squadBuilderReducer.formation;
    if (isLoggedIn)
      apiFc24.updatePlayerArchetype(archetypeId, formation, positionIndex);

    const player = state.squadBuilderReducer.players[positionIndex];

    if (player?.resourceId) {
      getMetaRatingsForPlayer(
        dispatch,
        player?.resourceId,
        positionIndex,
        archetypeId,
        undefined,
        player?.evolutionPath?.join("-"),
        player.evoPathId
      );
    }
    return { positionIndex, archetypeId };
  }
);

export const getPlayerRecommendations = createAsyncThunk(
  "recommend-players",
  async (playerIndex: number, { getState }) => {
    const state = getState() as IRootState;
    const {
      budget,
      manager,
      metal,
      inputClubs,
      inputCountries,
      inputLeagues,
      playerFilters,
      players,
      formation,
      playersFrom,
    } = state.squadBuilderReducer;

    const playersReq = players.map(
      (p, i) =>
        p?.resourceId && {
          resourceId: p?.resourceId,
          chemistryIndex: p?.chemIndex,
          inGameIndex: i,
          eaPlayerId: p?.eaPlayerId,
        }
    );

    return apiFc24.recommendPlayers({
      playerIndex,
      budget,
      chemistryFormation: formation,
      players: playersReq,
      playersFrom: playersFrom,
      inGameFormation: formation,
      manager: {
        country: manager?.country || null,
        league: manager?.league || null,
      },
      metal: metal !== "none" ? [metal] : [],
      country: inputCountries[0]?.id ? [inputCountries[0]?.id] : [],
      league: inputLeagues[0]?.id ? [inputLeagues[0]?.id] : [],
      club: inputClubs[0]?.id ? [inputClubs[0]?.id] : [],
      playerFilters,
    });
  }
);

export const movePlayer = createAsyncThunk<
  void,
  {
    fromPlayer: {
      player: SquadBuilderPlayer;
      positionIndex: number;
    };
    toPlayer: {
      player?: SquadBuilderPlayer;
      positionIndex: number;
    };
  }
>("move-player", async ({ fromPlayer, toPlayer }, { getState, dispatch }) => {
  const { squadBuilderReducer } = getState() as IRootState;
  dispatch(
    setSidebarOption({
      type: "closed",
    })
  );
  dispatch(
    addPlayers([
      {
        player: fromPlayer.player,
        positionIndex: fromPlayer.positionIndex,
      },
      {
        player: toPlayer.player,
        positionIndex: toPlayer.positionIndex,
      },
    ])
  );
  const toArchetype =
    squadBuilderReducer.formationArchetypes[squadBuilderReducer.formation][
      toPlayer.positionIndex
    ];

  const fromArchetype =
    squadBuilderReducer.formationArchetypes[squadBuilderReducer.formation][
      fromPlayer.positionIndex
    ];

  const player1 = squadBuilderReducer.players[fromPlayer.positionIndex];
  if (player1?.resourceId) {
    getMetaRatingsForPlayer(
      dispatch,
      player1.resourceId,
      toPlayer.positionIndex,
      toArchetype,
      undefined,
      player1?.evolutionPath?.join("-"),
      player1.evoPathId
    );
  }
  const player2 = squadBuilderReducer.players[toPlayer.positionIndex];
  if (player2?.resourceId) {
    getMetaRatingsForPlayer(
      dispatch,
      player2.resourceId,
      fromPlayer.positionIndex,
      fromArchetype,
      undefined,
      player2.evolutionPath?.join("-"),
      player2.evoPathId
    );
  }
});

const squadBuilderSlice = createSlice({
  name: "squadBuilder",
  initialState,
  reducers: {
    clearSquad(state) {
      state.sidebarOption = {
        type: "closed",
      };
      state.suggestedSquads = [];
      state.recommendedPlayers = [];
      state.players = setEmptyData() as SquadBuilderPlayers;
      state.tempPlayers = setEmptyData() as SquadBuilderPlayers;
      state.manager = undefined;
      state.status.recommendPlayers = "idle";
      state.status.suggestSquad = "idle";
      localStorage.removeItem("players");
      localStorage.removeItem("manager");
    },
    setSidebarOption(state, action: PayloadAction<SidebarOption>) {
      state.sidebarOption = action.payload;
    },
    addPlayers(
      state,
      action: PayloadAction<
        { player: SquadBuilderPlayer; positionIndex: number }[]
      >
    ) {
      const newPlayers: SquadBuilderPlayers = [...state.players];

      action.payload.forEach((p) => {
        newPlayers[p.positionIndex] = p.player;
      });
      state.players = newPlayers;
      localStorage.setItem("players", JSON.stringify(newPlayers));
    },
    addTempPlayer(
      state,
      action: PayloadAction<{
        player: SquadBuilderPlayer;
        index: number;
      }>
    ) {
      const { player, index } = action.payload;
      const newPlayers: SquadBuilderPlayers = [...state.tempPlayers];
      newPlayers[index] = player;

      state.tempPlayers = newPlayers;
    },
    addTempPlayers(
      state,
      action: PayloadAction<
        { player: SquadBuilderPlayer; positionIndex: number }[]
      >
    ) {
      const newTempPlayers: SquadBuilderPlayers = [...state.tempPlayers];
      action.payload.forEach((p) => {
        newTempPlayers[p.positionIndex] = p.player;
      });
      state.tempPlayers = newTempPlayers;
    },
    clearTempPlayers(state) {
      state.tempPlayers = setEmptyData() as SquadBuilderPlayers;
    },
    lockInTempPlayers(state, action: PayloadAction<{ from: string }>) {
      if (action.payload?.from === "recommendPlayers") {
        ReactGA.event({
          category: "SquadBuilder",
          action: `chose recommended player`,
        });
      }
      if (state.sidebarOption.type !== "playerRecOrSearch") {
        ReactGA.event({
          category: "SquadBuilder",
          action: `chose suggested squad`,
        });
      }
      const newPlayers: SquadBuilderPlayers = [...state.players];
      state.tempPlayers.forEach((p, i) => {
        if (p) newPlayers[i] = p;
      });
      state.players = newPlayers;
      state.tempPlayers = setEmptyData() as SquadBuilderPlayers;
      localStorage.setItem("players", JSON.stringify(newPlayers));
    },
    removePlayer(state, action: PayloadAction<number>) {
      const newPlayers: SquadBuilderPlayers = [...state.players];
      newPlayers[action.payload] = undefined;
      state.players = newPlayers;
      localStorage.setItem("players", JSON.stringify(newPlayers));
    },
    removeManager(state, action: PayloadAction<"league" | "country">) {
      if (action.payload === "league") {
        state.manager.league = undefined;
      } else if (action.payload === "country") {
        state.manager.country = undefined;
      }
      localStorage.setItem("manager", JSON.stringify(state.manager));
    },
    updatePlayerMetaRatings(
      state,
      action: PayloadAction<{
        index: number;
        metaRatings: MetaRating[];
      }>
    ) {
      const { index, metaRatings } = action.payload;
      state.players[index].metaRatings = metaRatings;
      localStorage.setItem("players", JSON.stringify(state.players));
    },
    updateBudget(state, action: PayloadAction<number>) {
      state.budget = action.payload;
    },

    updateFormation(state, action: PayloadAction<string>) {
      state.formation = action.payload;
      ReactGA.event({
        category: "SquadBuilder",
        action: `added formation: ${action.payload}`,
      });
      localStorage.setItem("formation", action.payload);
      const newPlayers: SquadBuilderPlayers = [...state.players];
      state.tempPlayers.forEach((p, i) => {
        if (p) newPlayers[i] = p;
      });
      state.players = newPlayers;
      state.tempPlayers = setEmptyData() as SquadBuilderPlayers;
      state.tempFormation = "";
      state.suggestedSquads = [];
      state.status.suggestSquad = "idle";
    },
    updateTempFormation(state, action: PayloadAction<string>) {
      state.tempFormation = action.payload;
      if (action.payload === "") {
        state.tempPlayers = setEmptyData() as SquadBuilderPlayers;
      } else {
        const newTempPlayers: SquadBuilderPlayers = [...state.players];
        state.tempPlayers = newTempPlayers;
      }
    },
    updatePlayersFrom(state, action: PayloadAction<PlayersFrom>) {
      ReactGA.event({
        category: "SquadBuilder",
        action: `added filter: ${action.payload}`,
      });
      state.playersFrom = action.payload;
    },
    clearSuggestedSquads(state) {
      state.suggestedSquads = [];
      state.status.suggestSquad = "idle";
    },
    addTempManager(
      state,
      action: PayloadAction<{ country?: number; league?: number }>
    ) {
      if (!state.tempManager) state.tempManager = {};
      const { country, league } = action.payload;
      if (country) state.tempManager.country = country;
      if (league) state.tempManager.league = league;
    },
    lockInTempManager(state) {
      if (!state.tempManager) return;
      if (!state.manager) state.manager = {};

      if (state.tempManager?.country)
        state.manager.country = state.tempManager.country;
      if (state.tempManager?.league)
        state.manager.league = state.tempManager.league;

      localStorage.setItem("manager", JSON.stringify(state.manager));

      state.tempManager = undefined;
    },
    clearTempManager(state) {
      state.tempManager = undefined;
    },
    updateMetal(state, action: PayloadAction<Metal>) {
      ReactGA.event({
        category: "SquadBuilder",
        action: `added filter: ${action.payload}`,
      });
      state.metal = action.payload;
    },
    updateInputCountry(state, action: PayloadAction<LeagueCountryData>) {
      state.inputCountries = [action.payload];
    },
    removeInputCountry(state) {
      state.inputCountries = [];
    },
    updateInputLeague(state, action: PayloadAction<LeagueCountryData>) {
      state.inputLeagues = [action.payload];
    },
    removeInputLeague(state) {
      state.inputLeagues = [];
    },
    updateInputClub(state, action: PayloadAction<LeagueCountryClubData>) {
      state.inputClubs = [action.payload];
    },
    removeInputClub(state) {
      state.inputClubs = [];
    },
    updateSuggestedSquadState(state) {
      state.status.suggestSquad = "idle";
    },
    clearFilters(state) {
      state.playersFrom = "market";
      state.metal = "none";
      state.inputClubs = [];
      state.inputLeagues = [];
      state.inputCountries = [];
      state.budget = Number.MAX_VALUE;
      state.playerFilters = setEmptyPlayerFilters();
    },
    toggleOtherAttribute(
      state,
      action: PayloadAction<{
        playerIndex: number;
        optionIndex: number | null;
        key: string;
      }>
    ) {
      const { playerIndex, key, optionIndex } = action.payload;
      const exclusive = otherAttributeSettings[key].exclusive;

      if (optionIndex === null) {
        state.playerFilters[playerIndex]["otherAttributes"][key] = [];
        return;
      }

      const newOtherAttributes = exclusive
        ? []
        : [...state.playerFilters[playerIndex]["otherAttributes"][key]];

      if (
        state.playerFilters[playerIndex]["otherAttributes"][key].includes(
          otherAttributeSettings[key].options[optionIndex]
        )
      ) {
        newOtherAttributes.splice(
          state.playerFilters[playerIndex]["otherAttributes"][key].indexOf(
            otherAttributeSettings[key].options[optionIndex]
          ),
          1
        );
      } else {
        ReactGA.event({
          category: "SquadBuilder",
          action: `added filter: ${key} ${optionIndex + 1}`,
        });
        newOtherAttributes.push(
          otherAttributeSettings[key].options[optionIndex]
        );
      }

      state.playerFilters[playerIndex]["otherAttributes"][key] =
        newOtherAttributes;
    },
    updateCustomFilter(
      state,
      action: PayloadAction<{
        playerIndex: number;
        key: string;
        value: number;
        type: "min" | "max";
      }>
    ) {
      const { playerIndex, key, value, type } = action.payload;
      if (key === "height") {
        state.playerFilters[playerIndex]["otherAttributes"][key] = {
          ...state.playerFilters[playerIndex]["otherAttributes"][key],
          [type]: value,
        };
      } else {
        state.playerFilters[playerIndex]["inGameAttributes"][key] = {
          ...state.playerFilters[playerIndex]["inGameAttributes"][key],
          [type]: value,
        };
      }
    },
    clearCustomFilters(state, action: PayloadAction<number | undefined>) {
      if (action.payload !== undefined)
        state.playerFilters[action.payload] = {
          inGameAttributes: setEmptyInGameAttributes(),
          otherAttributes: setEmptyOtherAttributes(),
        };
      else state.playerFilters = setEmptyPlayerFilters();
    },
    updateShowDetailedPlayerView(
      state,
      action: PayloadAction<ShowDetailedPlayerView | undefined>
    ) {
      state.showDetailedPlayerView = action.payload;
    },
    updateActivePlayerMobile(state, action: PayloadAction<number | undefined>) {
      state.activePlayerMobile = action.payload;
      state.sidebarOption = {
        type: "closed",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(suggestSquads.pending, (state) => {
        state.status.suggestSquad = "pending";
      })
      .addCase(
        suggestSquads.fulfilled,
        (state, action: PayloadAction<SuggestSquadsResponse>) => {
          state.status.suggestSquad = "fulfilled";
          state.suggestedSquads = action.payload;
        }
      )
      .addCase(suggestSquads.rejected, (state) => {
        state.status.suggestSquad = "rejected";
      })
      .addCase(
        getFormationArchetypes.fulfilled,
        (state, action: PayloadAction<FormationArchetypesResponse>) => {
          state.formationArchetypes = action.payload;
        }
      )
      .addCase(importSquad.fulfilled, (state, action) => {
        const { manager, players, currentFormation } = action.payload;
        state.status.importSquad = "fulfilled";
        state.manager = {
          league: manager.league,
          country: manager.country,
        };
        state.formation = currentFormation;
        localStorage.setItem("formation", currentFormation);
        let newPlayers = setEmptyData();

        players.forEach((p, index) => {
          if (p?.resourceId && index < 11) {
            newPlayers[index] = {
              ...p,
              owned: true,
            };
          }
        });

        state.players = newPlayers as SquadBuilderPlayers;
        localStorage.setItem("players", JSON.stringify(newPlayers));
      })
      .addCase(importSquad.rejected, (state) => {
        state.status.importSquad = "rejected";
      })
      .addCase(importSquad.pending, (state) => {
        state.status.importSquad = "pending";
      })
      .addCase(
        updatePlayerArchetype.fulfilled,
        (
          state,
          action: PayloadAction<{ positionIndex: number; archetypeId: string }>
        ) => {
          if (state.formationArchetypes[state.formation])
            state.formationArchetypes[state.formation][
              action.payload.positionIndex
            ] = action.payload.archetypeId;
        }
      )
      .addCase(getPlayerRecommendations.pending, (state) => {
        state.status.recommendPlayers = "pending";
      })
      .addCase(
        getPlayerRecommendations.fulfilled,
        (state, action: PayloadAction<RecommendedPlayersAtPosition[]>) => {
          state.recommendedPlayers = action.payload;
          state.status.recommendPlayers = "fulfilled";
        }
      );
  },
});

export const squadBuilderSelector = (state: IRootState) =>
  state.squadBuilderReducer;

export const formationArchetypesSelector = (state: IRootState) =>
  state.squadBuilderReducer.formationArchetypes[
    state.squadBuilderReducer.formation
  ];

export const recommendedPlayersSelector = ({
  squadBuilderReducer,
}: IRootState) => {
  return squadBuilderReducer.recommendedPlayers[
    squadBuilderReducer.sidebarOption.playerIndex >= 0
      ? squadBuilderReducer.sidebarOption.playerIndex
      : squadBuilderReducer.activePlayerMobile
  ]?.players;
};

export const getFormationLabelsSelector = ({
  squadBuilderReducer,
}: IRootState) => getFormation(squadBuilderReducer.formation).labels;

export const teamMetaRatingSelector =
  ({
    playersChemistry,
    temp,
  }: {
    playersChemistry: PlayersChemistry;
    temp: boolean;
  }) =>
  ({ squadBuilderReducer }: IRootState) => {
    const { players, tempPlayers } = squadBuilderReducer;

    let metaRatingSum = 0;
    for (let i = 0; i < 11; i++) {
      const player = (temp && tempPlayers[i]) || players[i];

      metaRatingSum +=
        player?.metaRatings[playersChemistry[i]]?.metaRating || 0;
    }
    return metaRatingSum >= 0 ? metaRatingSum / 11 : 0;
  };

export const chemistrySelector =
  ({ temp }: { temp: boolean }) =>
  (state: IRootState) => {
    const {
      players,
      tempPlayers,
      formation,
      tempFormation,
      manager,
      tempManager,
    } = state.squadBuilderReducer;
    const { versionAssets } = state.loadedContentReducer;

    const chemPlayers = [];
    for (let i = 0; i < 11; i++) {
      const p = (temp && tempPlayers[i]) || players[i];
      if (p)
        chemPlayers.push({
          clubId: p.clubId,
          leagueId: p.leagueId,
          countryId: p.countryId,
          positionIndex: i,
          possiblePositions: p.possiblePositions,
          version: versionAssets[p.versionId],
          versionId: p.versionId,
        });
    }
    const chemManager = temp ? { ...manager, ...tempManager } : manager;

    const chemistryObject = calculateChemistry({
      players: chemPlayers,
      formation: getFormation(tempFormation && temp ? tempFormation : formation)
        .labels,
      manager: chemManager,
    });
    const teamChemistry = Object.values(
      chemistryObject.playersChemistry
    ).reduce((sum, chemistry) => sum + chemistry, 0);

    return {
      teamChemistry,
      playersOutOfPosition: chemistryObject.playersOutOfPosition,
      playersChemistry: chemistryObject.playersChemistry,
      summary: {
        countryCounter: chemistryObject.countryCounter,
        leagueCounter: chemistryObject.leagueCounter,
        clubCounter: chemistryObject.clubCounter,
      },
    };
  };

export const teamValueSelector =
  ({ temp, filterOwned }: { temp: boolean; filterOwned: boolean }) =>
  (state: IRootState) => {
    const { players, tempPlayers } = state.squadBuilderReducer;
    let sum = 0;
    for (let i = 0; i < 11; i++) {
      const player = (temp && tempPlayers[i]) || players[i];
      sum += filterOwned
        ? (player?.owned && (player?.price || player?.sbcPrice)) || 0
        : player?.price || player?.sbcPrice || 0;
    }
    return sum;
  };

export const teamRatingSelector =
  ({ temp }: { temp: boolean }) =>
  ({ squadBuilderReducer }: IRootState) => {
    const { players, tempPlayers } = squadBuilderReducer;
    const ratings = [];

    for (let i = 0; i < 11; i++) {
      const p = (temp && tempPlayers[i]) || players[i];
      ratings.push(p?.rating || 0);
    }

    return calculateRating(ratings);
  };
export const managerDataSelector = ({ squadBuilderReducer }: IRootState) => {
  return {
    countries: squadBuilderReducer.countries,
    leagues: squadBuilderReducer.leagues,
  };
};

export const playerRoleLabelSelector =
  ({ playerRoleId }: { playerRoleId: string }) =>
  ({ playersReducer }: IRootState) => {
    return playersReducer.archetypes.find((arch) => arch.id === playerRoleId)
      ?.name;
  };

export const anyFilterActiveSelector = (state: IRootState) => {
  const {
    playerFilters,
    playersFrom,
    budget,
    metal,
    inputClubs,
    inputCountries,
    inputLeagues,
  } = state.squadBuilderReducer;
  let playerFilterCount = 0;
  Object.values(playerFilters).forEach(
    ({ inGameAttributes, otherAttributes }) => {
      if (
        Object.values(inGameAttributes).some((attribute) => {
          return attribute.min || attribute.max;
        }) ||
        !!otherAttributes.height?.min ||
        !!otherAttributes.height?.max ||
        Object.values(otherAttributes).some(
          (v) => Array.isArray(v) && v.length > 0
        )
      )
        playerFilterCount++;
    }
  );

  return (
    playersFrom !== "market" ||
    budget < Number.MAX_VALUE ||
    playerFilterCount > 0 ||
    metal !== "none" ||
    inputCountries.length > 0 ||
    inputLeagues.length > 0 ||
    inputClubs.length > 0
  );
};

export const {
  updateBudget,
  updateFormation,
  updatePlayersFrom,
  addPlayers,
  removePlayer,
  clearSquad,
  updatePlayerMetaRatings,
  updateMetal,
  addTempPlayer,
  addTempPlayers,
  clearTempPlayers,
  lockInTempPlayers,
  clearSuggestedSquads,
  addTempManager,
  lockInTempManager,
  clearTempManager,
  updateTempFormation,
  updateInputCountry,
  removeInputCountry,
  updateInputLeague,
  removeInputLeague,
  updateInputClub,
  removeInputClub,
  updateSuggestedSquadState,
  clearFilters,
  updateCustomFilter,
  clearCustomFilters,
  setSidebarOption,
  updateShowDetailedPlayerView,
  toggleOtherAttribute,
  updateActivePlayerMobile,
  removeManager,
} = squadBuilderSlice.actions;

export default squadBuilderSlice.reducer;
