import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { updateShowModal } from "../../../../redux/message/messageSlice";
import { AppDispatch } from "../../../../redux/store";
import { PremiumModal } from "../PremiumModal";

export const ImportClubModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <PremiumModal
      title="You need to import your club"
      content="To use Club Import please import your club first"
      onPositiveButtonClicked={() => {
        navigate("import");
        dispatch(updateShowModal({ modalType: "none" }));
      }}
      onCloseClicked={() => {
        dispatch(updateShowModal({ modalType: "none" }));
      }}
      positiveActionButtonLabel="Go to Club Import"
      negativeActionButtonLabel="Cancel"
    />
  );
};
