import axios from "../publicApi";

export const login = async (
  credentials: string
): Promise<CredentialResponse> => {
  const res = await axios.post("/auth/login", {
    token: credentials,
  });
  localStorage.setItem(
    "access_token",
    JSON.stringify(res.data["access_token"])
  );
  return res.data;
};
