import { NavLink } from "react-router-dom";

type NavigationItemProps = {
  link: string;
  onClick: () => void;
  children: JSX.Element;
  from?: string;
};

const NavigationItem = ({
  link,
  onClick,
  children,
  from,
}: NavigationItemProps) => (
  <NavLink
    to={link}
    state={{ from }}
    className="px-1 py-2 flex items-center text-sm desktop:text-xs uppercase leading-snug text-white hover:opacity-75"
    onClick={onClick}
  >
    <span className="ml-2">{children}</span>
  </NavLink>
);

export default NavigationItem;
