import { FromBackendSquadBuilderPlayer } from "../../../redux/sbcPlayers/SearchedPlayer.type";
import { Metal } from "../../../redux/squadBuilder/squadBuilderSlice";
import axios from "../../apiFc24";

export type RecommendedPlayersAtPosition = {
  players: FromBackendSquadBuilderPlayer[];
  chemIndex: number;
};

type Props = {
  budget: number;
  chemistryFormation: string;
  players: {
    resourceId: number;
    chemistryIndex: number;
  }[];
  manager?: {
    country?: number;
    league?: number;
  };
  playersFrom: "club" | "both" | "market";
  inGameFormation: string;
  metal: Metal[];
  country: number[];
  league: number[];
  club: number[];
  playerFilters: undefined[] | any[];
  playerIndex: number;
};
export const recommendPlayers = async (props: Props) => {
  const res = await axios.post<RecommendedPlayersAtPosition[]>(
    "/squad-builder/recommend-players",
    props
  );
  return res.data;
};
