import { useEffect } from "react";
import ReactGA from "react-ga4";

type Props = {
  onLogin: (credential: string) => Promise<CredentialResponse>;
  onSuccess: (res: CredentialResponse) => void;
  onFailure: (message: string) => void;
  googleButtonRef: React.MutableRefObject<HTMLDivElement>;
};
export const useGoogleLogin = ({
  onLogin,
  onSuccess,
  onFailure,
  googleButtonRef,
}: Props) => {
  useEffect(() => {
    const handleGoogleSignIn = async (res: CredentialResponse) => {
      if ((!res.clientId && !res["client_id"]) || !res.credential) {
        ReactGA.event({
          category: "Login",
          action: "google_login_error",
        });
        onFailure("Couldn't login to your Google account");
      } else
        onLogin(res.credential)
          .then(onSuccess)
          .catch(() => {
            onFailure(
              "Couldn't find user. Are you sure that you have signed up?"
            );
            ReactGA.event({
              category: "Login",
              action: "google_login_error",
            });
          });
    };

    const initializeGoogle = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
        callback: handleGoogleSignIn,
      });
      renderButton();
    };
    const renderButton = () => {
      if (!window.google) return;
      window.google.accounts.id.renderButton(googleButtonRef.current!, {
        theme: "outline",
        type: "standard",
        text: "continue_with",
        width: 300,
      });
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGoogle;
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body")?.appendChild(script);
    return () => {
      window.google?.accounts?.id?.cancel();
      document.getElementById("google-client-script")?.remove();
    };
  });
};
