import {
  EXCLUDE_ACTIVE_SQUAD,
  PLAYERS_FROM,
  UNTRADEABLE_ONLY,
} from "../../../settings";
import axios from "../../privateApi";

export const logout = () => {
  return axios
    .post("/auth/logout")
    .then((_) => {
      removeUserFromLocalStorage();
    })
    .catch((_) => {
      removeUserFromLocalStorage();
    });
};

const removeUserFromLocalStorage = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("name");
  localStorage.removeItem("email");
  localStorage.removeItem("paid");
  localStorage.removeItem("canUseClubImport");
  localStorage.removeItem(UNTRADEABLE_ONLY);
  localStorage.removeItem(PLAYERS_FROM);
  localStorage.removeItem(EXCLUDE_ACTIVE_SQUAD);
};
