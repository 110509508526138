import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { APIStatus } from "../../api/APIStatus.type";
import * as apiFc24 from "../../api/apiFc24";
import { fetchMyEvolutionPlayers } from "../../api/privateRequests/players/getMyEvolutionPlayers";
import { Status } from "../../api/privateRequests/players/getMyEvolutionPlayersStatus";
import {
  RangeFilterType,
  SelectorFilterType,
  SelectorOption,
  UpdateFilterType,
} from "../../components/organisms/Filters/PlayerFilters/PlayerFilters";
import { PlayersSortByType } from "../../components/organisms/PlayersToolbar/PlayersToolbar";
import { QueryParams } from "../../pages/Players/PlayersPage";
import { defaultPositions } from "../../types/defaultPositions";
import { inGameAttributes } from "../squadBuilder/squadBuilderSlice";
import { IRootState } from "../store";

export type MetaRatingsPlayer = {
  resourceId: number;
  assetId: number;
  eaPlayerId: number | null;
  name: string;
  rating: number;
  positions: string[];
  metaRating: number;
  metaRatingZeroChem: number;
  chemStyleId?: number;
  playerRole?: string;
  metaRatings?: {
    metaRating: number;
    playerRole: string;
    chemStyleId: number;
  }[];
  newPlayer: boolean;
  owned: boolean;
  attributes: number[];
  country: number;
  league: number;
  club: number;
  price: number;
  sbcPrice: number;
  skillMoves: number;
  weakFoot: number;
  untradeable: boolean;
  versionId: number;
  preferredPosition: string;
  metalId: number;
  hasDynamicImage: boolean;
  playerUrl: string;
  playStylesPlus: number[];
  isSbcPlayer?: boolean;
  isObjectivePlayer?: boolean;
  cardName?: string;
  evolutionPath?: number[];
  playStyles?: number[];
  canEvolve: boolean;
  availableEvos?: number;
  evoPathId?: number;
  status?: Status;
};

export type PlayerRole = {
  id: string;
  name: string;
  eligiblePositions: string[];
  description: string;
  metaTiers: [
    {
      tierId: 0 | 1 | 2 | 3 | 4;
      minMetaRating: number;
    }
  ];
};

export type Tier = {
  tierName: "S" | "A+" | "A" | "B+" | "B";
  tierColor: string;
};

export const tierIdToTier = (tierId: 0 | 1 | 2 | 3 | 4): Tier => {
  switch (tierId) {
    case 0:
      return {
        tierName: "S",
        tierColor: "bg-primary-light-blue",
      };
    case 1:
      return {
        tierName: "A+",
        tierColor: "bg-ea-dark-green",
      };
    case 2:
      return {
        tierName: "A",
        tierColor: "bg-ea-light-green",
      };
    case 3:
      return {
        tierName: "B+",
        tierColor: "bg-tier-yellow",
      };
    case 4:
      return {
        tierName: "B",
        tierColor: "bg-ea-orange",
      };
  }
};

type PlayStyle = {
  name: string;
  category: string;
};

export type PlayStylesDict = {
  [id: number]: PlayStyle;
};

export type SelectedPlayStyle = {
  id: number;
  plus: boolean;
};

type PlayersState = {
  players: MetaRatingsPlayer[];
  myEvolvedPlayers: MetaRatingsPlayer[];
  totalPages: number;
  archetypes: PlayerRole[];
  playStylesDict: PlayStylesDict;
  playersStatus: APIStatus;
  myEvolvedPlayersStatus: APIStatus;
  requestNo: number;
  changedToMetaRatingSorting: boolean;
  playerFilters: (RangeFilterType | SelectorFilterType)[];
  filterBarOpen: boolean;
};

const initialState: PlayersState = {
  players: [],
  myEvolvedPlayers: [],
  totalPages: 1,
  archetypes: [],
  playStylesDict: {},
  playersStatus: "idle",
  myEvolvedPlayersStatus: "idle",
  requestNo: 0,
  filterBarOpen: false,
  changedToMetaRatingSorting: false,
  playerFilters: [
    {
      label: "Owned Players",
      key: "ownedOrEvolved",
      type: "SELECTOR_FILTER",
      options: [
        { id: 0, label: "Only Owned Players", category: "Owned" },
        { id: 1, label: "Only Evolved Players", category: "Evolved" },
      ],
      selectedOptions: [],
    },
    {
      label: "Price",
      key: "price",
      type: "RANGE_FILTER",
      range: [0, 9999999],
      minValue: null,
      maxValue: null,
    },
    {
      label: "Meta Rating",
      key: "metaRating",
      type: "RANGE_FILTER",
      range: [0, 99],
      minValue: null,
      maxValue: null,
    },
    {
      label: "Rating",
      key: "rating",
      type: "RANGE_FILTER",
      range: [0, 99],
      minValue: null,
      maxValue: null,
    },
    {
      label: "Country",
      key: "country",
      type: "SELECTOR_FILTER",
      options: [],
      selectedOptions: [],
    },
    {
      label: "Club",
      key: "club",
      type: "SELECTOR_FILTER",
      options: [],
      selectedOptions: [],
    },
    {
      label: "League",
      key: "league",
      type: "SELECTOR_FILTER",
      options: [],
      selectedOptions: [],
    },
    {
      label: "Position",
      key: "position",
      type: "SELECTOR_FILTER",
      useLabelAsId: true,
      options: [...defaultPositions].map((position, i) => ({
        id: i,
        label: position.label,
        category: position.category,
      })),
      selectedOptions: [],
    },
    {
      label: "Rarity",
      key: "rarity",
      type: "SELECTOR_FILTER",
      options: [],
      selectedOptions: [],
    },
    {
      label: "Playstyle",
      key: "playStyle",
      type: "SELECTOR_FILTER",
      options: [],
      selectedOptions: [],
    },
    {
      label: "Playstyle+",
      key: "playStylePlus",
      type: "SELECTOR_FILTER",
      options: [],
      selectedOptions: [],
    },
    {
      label: "Skill Moves",
      shortLabel: "SM",
      key: "skillMoves",
      type: "SELECTOR_FILTER",
      options: [
        { id: 1, label: "1", category: "Skill Moves" },
        { id: 2, label: "2", category: "Skill Moves" },
        { id: 3, label: "3", category: "Skill Moves" },
        { id: 4, label: "4", category: "Skill Moves" },
        { id: 5, label: "5", category: "Skill Moves" },
      ],
      selectedOptions: [],
    },
    {
      label: "Weak Foot",
      shortLabel: "WF",
      key: "weakFoot",
      type: "SELECTOR_FILTER",
      options: [
        { id: 1, label: "1", category: "Weak Foot" },
        { id: 2, label: "2", category: "Weak Foot" },
        { id: 3, label: "3", category: "Weak Foot" },
        { id: 4, label: "4", category: "Weak Foot" },
        { id: 5, label: "5", category: "Weak Foot" },
      ],
      selectedOptions: [],
    },
    {
      label: "Defensive Work Rate",
      shortLabel: "Def. WR",
      key: "defensiveWorkRate",
      type: "SELECTOR_FILTER",
      useLabelAsId: true,
      options: [
        { id: 0, label: "Low", category: "Defensive Work Rate" },
        { id: 1, label: "Medium", category: "Defensive Work Rate" },
        { id: 2, label: "High", category: "Defensive Work Rate" },
      ],
      selectedOptions: [],
    },
    {
      label: "Attacking Work Rate",
      shortLabel: "Att. WR",
      key: "attackingWorkRate",
      type: "SELECTOR_FILTER",
      useLabelAsId: true,
      options: [
        { id: 0, label: "Low", category: "Attacking Work Rate" },
        { id: 1, label: "Medium", category: "Attacking Work Rate" },
        { id: 2, label: "High", category: "Attacking Work Rate" },
      ],
      selectedOptions: [],
    },
    {
      label: "Preferred Foot",
      shortLabel: "PF",
      key: "preferredFoot",
      type: "SELECTOR_FILTER",
      useLabelAsId: true,
      options: [
        { id: 0, label: "Left", category: "Preferred Foot" },
        { id: 1, label: "Right", category: "Preferred Foot" },
      ],
      selectedOptions: [],
    },
    ...Object.entries(inGameAttributes).map(
      ([key, attribute]) =>
        ({
          label: attribute.label,
          key,
          type: "RANGE_FILTER",
          range: [1, 99],
          minValue: null,
          maxValue: null,
        } as RangeFilterType)
    ),
  ],
};

export const getPlayers = createAsyncThunk(
  "get-players",
  async (
    {
      page,
      sort,
      myClub,
      myEvolvedPlayers,
      chainedEvo,
      excludePaidEvo,
      playerRole,
      queryParams,
      evolutions,
      evoIds,
    }: {
      page: number;
      sort: PlayersSortByType;
      myClub?: boolean;
      myEvolvedPlayers?: boolean;
      chainedEvo?: boolean;
      excludePaidEvo?: boolean;
      playerRole?: string;
      queryParams?: QueryParams[];
      evolutions?: boolean;
      evoIds?: number[];
      requestNo?: number;
    },
    { dispatch, getState }
  ) => {
    const { playersReducer } = getState() as IRootState;

    let { requestNo } = playersReducer;
    requestNo += 1;
    dispatch(incrementRequestNo(requestNo));

    const res = await apiFc24.getPlayers(
      page,
      sort,
      myClub,
      myEvolvedPlayers,
      chainedEvo,
      excludePaidEvo,
      playerRole,
      queryParams,
      evolutions,
      evoIds
    );
    return {
      ...res,
      requestNo,
    };
  }
);

export const getMyEvolutionPlayers = createAsyncThunk(
  "get-evolution-players",
  async ({
    sort,
    playerRole,
  }: {
    sort: PlayersSortByType;
    playerRole: string;
  }) => {
    const res = await fetchMyEvolutionPlayers({
      sort,
      playerRole,
    });
    return res;
  }
);

type InitialPlayerData = {
  playerRoles: PlayerRole[];
  playStyles: PlayStylesDict;
};

export const bodyTypeIdToName = {
  1: ["Avg & Lean"],
  2: ["Avg & Normal"],
  3: ["Avg & Stocky"],
  4: ["Tall & Lean"],
  5: ["Tall & Normal"],
  6: ["Tall & Stocky"],
  7: ["Short & Lean"],
  8: ["Short & Normal"],
  9: ["Short & Stocky"],
  11: ["Very Tall & Lean"],
  12: ["Unique", "Lean"],
  13: ["Unique", "Normal"],
  14: ["Unique", "Stocky"],
  15: ["-", "-"],
};

export const getInitialPlayerData = createAsyncThunk<InitialPlayerData>(
  "get-initial-player-data",
  async () => {
    const [playerRoles, playStyles] = await Promise.all([
      apiFc24.getArchetypes(),
      apiFc24.getPlayStyles(),
    ]);

    return {
      playerRoles,
      playStyles,
    };
  }
);

const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    incrementRequestNo: (state, action: PayloadAction<number>) => {
      state.requestNo = action.payload;
    },
    toggleFilterBar: (state) => {
      state.filterBarOpen = !state.filterBarOpen;
    },
    updateFilterOptions: (
      state,
      action: PayloadAction<{ key: string; options: SelectorOption[] }>
    ) => {
      const filter = state.playerFilters.find(
        (f) => f.key === action.payload.key
      );
      if (filter?.type === "SELECTOR_FILTER") {
        filter.options = action.payload.options;
      }
    },
    updateFilter: (state, action: PayloadAction<UpdateFilterType>) => {
      const { key } = action.payload;

      const filter = state.playerFilters.find((f) => f.key === key);

      if (filter?.type === "RANGE_FILTER") {
        const { min, max } = action.payload;
        if (min !== undefined) filter.minValue = min;
        if (max !== undefined) filter.maxValue = max;
      } else if (filter?.type === "SELECTOR_FILTER") {
        const { value } = action.payload;
        const isSelected = filter.selectedOptions.includes(value);

        if (filter.key === "ownedOrEvolved") {
          filter.selectedOptions = isSelected ? [] : [value];
        } else {
          if (isSelected)
            filter.selectedOptions = filter.selectedOptions.filter(
              (so) => so !== value
            );
          else filter.selectedOptions.push(value);
        }
      }
    },
    clearAllFilters: (state) => {
      state.playerFilters.forEach((filter) => {
        if (filter.type === "RANGE_FILTER") {
          filter.minValue = null;
          filter.maxValue = null;
        } else if (filter.type === "SELECTOR_FILTER") {
          filter.selectedOptions = [];
        }
      });
    },
    clearFilter: (state, action: PayloadAction<string>) => {
      const filter = state.playerFilters.find((f) => f.key === action.payload);

      if (filter?.type === "RANGE_FILTER") {
        filter.minValue = null;
        filter.maxValue = null;
      } else if (filter?.type === "SELECTOR_FILTER") {
        filter.selectedOptions = [];
      }
    },
    clearFilterOption: (
      state,
      action: PayloadAction<{ filterKey: string; option: number }>
    ) => {
      const { filterKey, option } = action.payload;

      const filter = state.playerFilters.find((f) => f.key === filterKey);

      if (filter?.type === "SELECTOR_FILTER") {
        filter.selectedOptions = filter.selectedOptions.filter(
          (so) => so !== option
        );
      }
    },
    changeToMetaRatingSorting: (state) => {
      state.changedToMetaRatingSorting = true;
    },
    // removeMyEvolvedPlayers: (state) => {
    //   state.myEvolvedPlayers = [];
    // },
    updateMyEvolvedPlayersStatus: (
      state,
      action: PayloadAction<
        {
          evoPathId: number;
          availableEvos: number;
          status: Status;
        }[]
      >
    ) => {
      const updatedPlayers = state.myEvolvedPlayers.map((player) => {
        const updatedPlayer = action.payload.find(
          (p) => p.evoPathId === player.evoPathId
        );
        if (updatedPlayer) {
          player.availableEvos = updatedPlayer.availableEvos;
          player.status = updatedPlayer.status;
        }
        return player;
      });
      state.myEvolvedPlayers = updatedPlayers;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlayers.fulfilled, (state, action) => {
        if (action.payload.requestNo !== state.requestNo) {
          return;
        }
        state.players = action.payload.players;
        state.totalPages = action.payload.totalPages;
        state.playersStatus = "fulfilled";
      })
      .addCase(getPlayers.pending, (state) => {
        state.playersStatus = "pending";
      })
      .addCase(getPlayers.rejected, (state) => {
        state.playersStatus = "rejected";
      })
      .addCase(
        getInitialPlayerData.fulfilled,
        (state, action: PayloadAction<InitialPlayerData>) => {
          state.archetypes = action.payload.playerRoles;
          state.playStylesDict = action.payload.playStyles;
          const playStyleFilter = state.playerFilters.find(
            (f) => f.key === "playStyle"
          ) as SelectorFilterType;
          const playStylePlusFilter = state.playerFilters.find(
            (f) => f.key === "playStylePlus"
          ) as SelectorFilterType;

          const { playStyles } = action.payload;
          playStyleFilter.options = Object.entries(playStyles).map(
            ([id, playStyle]) => ({
              id: parseInt(id),
              label: playStyle.name,
              value: id,
              category: playStyle.category,
            })
          );

          playStylePlusFilter.options = Object.entries(playStyles).map(
            ([id, playStyle]) => ({
              id: parseInt(id),
              label: playStyle.name,
              value: id,
              category: playStyle.category,
            })
          );
        }
      )
      .addCase(getMyEvolutionPlayers.pending, (state) => {
        state.myEvolvedPlayersStatus = "pending";
      })
      .addCase(getMyEvolutionPlayers.fulfilled, (state, action) => {
        state.myEvolvedPlayersStatus = "fulfilled";
        state.myEvolvedPlayers = action.payload;
      })
      .addCase(getMyEvolutionPlayers.rejected, (state) => {
        state.myEvolvedPlayersStatus = "rejected";
      });
  },
});

export const {
  incrementRequestNo,
  updateFilter,
  updateFilterOptions,
  clearAllFilters,
  clearFilter,
  clearFilterOption,
  changeToMetaRatingSorting,
  toggleFilterBar,
  // removeMyEvolvedPlayers,
  updateMyEvolvedPlayersStatus,
} = playersSlice.actions;

export const playersSelector = (state: IRootState) => state.playersReducer;

export const activeFiltersSelector = (state: IRootState) => {
  const { playerFilters } = state.playersReducer;
  return playerFilters.filter((filter) => {
    if (filter.type === "RANGE_FILTER")
      return filter.minValue !== null || filter.maxValue !== null;
    else return filter.selectedOptions.length > 0;
  });
};

export const ownedOrEvolvedSelector = (state: IRootState) => {
  const { playerFilters } = state.playersReducer;
  const filter = playerFilters.find(
    (f) => f.key === "ownedOrEvolved"
  ) as SelectorFilterType;
  const selectedOption = filter.options.find(
    (o) => o.id === filter?.selectedOptions[0]
  );
  return selectedOption;
};

export const playerFiltersAsQueryParamsSelector = (state: IRootState) => {
  let queryParams = [];
  const { playerFilters } = state.playersReducer;
  playerFilters.forEach((filter) => {
    if (filter.type === "RANGE_FILTER") {
      if (filter.minValue > 0)
        queryParams.push({
          [`${filter.key}Min`]: [filter.minValue],
        });

      if (filter.maxValue > 0)
        queryParams.push({
          [`${filter.key}Max`]: [filter.maxValue],
        });
    } else if (
      filter.type === "SELECTOR_FILTER" &&
      filter.selectedOptions.length > 0
    ) {
      queryParams.push({
        [filter.key]: filter.useLabelAsId
          ? filter.selectedOptions.map(
              (sp) => filter.options.find((p) => p.id === sp).label
            )
          : filter.selectedOptions,
      });
    }
  });
  return queryParams;
};

export const tierSelector =
  (metaRating: number, playerRoleName: string) => (state: IRootState) => {
    const { archetypes } = state.playersReducer;
    const archetype = archetypes.find((a) => a.name === playerRoleName);
    if (!archetype) return null;

    const tier = archetype.metaTiers.find((t) => metaRating >= t.minMetaRating);

    if (!tier) return null;

    return tierIdToTier(tier.tierId);
  };

export default playersSlice.reducer;
