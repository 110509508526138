import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IRootState } from "../store";
import { SearchedPlayer } from "./SearchedPlayer.type";

export type ManuallyAddPlayerProperties = {
  untradeable: boolean;
  transferList: boolean;
  activeSquad: boolean;
};

type PlayersState = {
  manuallyAddedPlayers: SearchedPlayer[];
  manuallyAddPlayerProperties: ManuallyAddPlayerProperties;
  didRemovePlayer: boolean;
};

const initialState: PlayersState = {
  manuallyAddedPlayers: [],
  manuallyAddPlayerProperties: {
    untradeable: true,
    transferList: false,
    activeSquad: false,
  },
  didRemovePlayer: false,
};

const sbcPlayersSlice = createSlice({
  name: "sbcPlayers",
  initialState,
  reducers: {
    updateManuallyAddPlayerProperties(
      state,
      action: PayloadAction<ManuallyAddPlayerProperties>
    ) {
      state.manuallyAddPlayerProperties = {
        ...action.payload,
      };
    },
    addManuallyAddedPlayers(state, action: PayloadAction<SearchedPlayer>) {
      state.manuallyAddedPlayers = [
        ...state.manuallyAddedPlayers,
        action.payload,
      ];
    },
    removeManuallyAddedPlayer(state, action: PayloadAction<string>) {
      state.manuallyAddedPlayers = state.manuallyAddedPlayers.filter(
        (p) => p.id !== action.payload
      );
      state.didRemovePlayer = true;
    },
    clearManuallyAddedPlayers(state) {
      state.manuallyAddedPlayers = [];
    },
  },
});

export const getPlayersSelector = (state: IRootState) => state.sbcPlayesReducer;
export const manuallyAddPlayerPropertiesSelector = (state: IRootState) =>
  state.sbcPlayesReducer.manuallyAddPlayerProperties;

export const {
  updateManuallyAddPlayerProperties,
  addManuallyAddedPlayers,
  clearManuallyAddedPlayers,
} = sbcPlayersSlice.actions;
export default sbcPlayersSlice.reducer;
