import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import * as publicApi from "../../../../api/publicApi";
import { useGoogleLogin } from "../../../../hooks/useGoogleLogin";
import { useEnter, useEscape } from "../../../../hooks/useKeyboardShortcut";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { thickCross } from "../../../../icons";
import { updateShowModal } from "../../../../redux/message/messageSlice";
import { fetchSbcsSets } from "../../../../redux/sbcs/sbcSetsSlice";
import { AppDispatch } from "../../../../redux/store";
import { fetchUser } from "../../../../redux/user/userSlice";
import { InputField } from "../../../atoms/InputField/InputField";

type Props = {
  modalData?: string;
};
export const LoginRequiredModal = ({ modalData }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [showEmailInvalid, setShowEmailInvalid] = useState(false);
  const [continueClicked, setContinueClicked] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [fadeIn, setFadeIn] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const onClose = () => dispatch(updateShowModal({ modalType: "none" }));
  const ref = useOutsideClick(onClose);
  useEscape(onClose);
  useEnter(() => {
    if (isValidEmail) {
      setContinueClicked(true);
      publicApi.loginWithEmail(email);
    } else setShowEmailInvalid(true);
  });

  const onLoginSuccess = useCallback(async () => {
    await dispatch(fetchUser({ didLogin: true }));
    dispatch(fetchSbcsSets());
    dispatch(updateShowModal({ modalType: "none" }));
  }, [dispatch]);

  const handleEmailChange = (event: any) => {
    const { value } = event.target;
    setIsValidEmail(emailRegex.test(value));
    setShowEmailInvalid(false);
    setEmail(value);
  };

  useGoogleLogin({
    onLogin: publicApi.login,
    onSuccess: onLoginSuccess,
    onFailure: setError,
    googleButtonRef: divRef,
  });

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, 50);
  }, []);

  return (
    <div
      className={clsx(
        "backdrop-blur-sm backdrop-brightness-50 fixed inset-0 flex z-[100] px-4 transition ease-in-out",
        fadeIn ? "opacity-100" : "opacity-0"
      )}
    >
      <div
        ref={ref}
        className={clsx(
          "text-white m-auto mt-[20vh] flex flex-col rounded-lg  transition ease-in-out",
          fadeIn ? "translate-y-4" : "translate-y-0",
          "bg-[linear-gradient(134.9deg,_#2B2A2A_31.71%,_#0D0D0D_80.71%)]",
          "shadow-[1px_2px_1px_0px_#ffffff40_inset,_-1px_-2px_1px_0px_#00000040_inset]"
        )}
        style={{
          filter: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.5))",
        }}
      >
        <div className="relative h-32">
          <div
            className={clsx(
              "bg-cover rounded-t-lg h-32 w-full",
              "bg-[url(https://cdn.easysbc.io/fut23/misc/popup-bg.png)]",
              "shadow-[1px_2px_1px_0px_#ffffff40_inset]"
            )}
            style={{
              filter: "drop-shadow(0px 2px 0px rgba(101, 209, 255, 0.25))",
            }}
          />
          <div className="absolute flex justify-between w-full px-7 top-4 gap-x-2">
            <div className="flex flex-col mb-auto gap-y-2">
              <div className="text-2xl font-bold tracking-wider text-white">
                Login is required
              </div>
              <div className="text-sm tracking-widest text-brand-meta-orange">
                {modalData ||
                  "In order to use this feature you need to have an account"}
              </div>
            </div>
            <button className="w-6 h-6 mb-auto" onClick={onClose}>
              {thickCross}
            </button>
          </div>
        </div>
        <div className="px-7 pt-[5%] pb-8 flex flex-col gap-y-4">
          {continueClicked ? (
            <>
              <p className="tracking-wider pl-[2px] text-[#009AFF] text-xl">
                Check your email for a link
              </p>
              <div className="flex flex-col gap-y-2 w-[300px] text-sm text-white/75">
                <p className="text-gray-300">We've sent a login link to</p>
                <span className="font-semibold text-white">{email}</span>

                <p>The link expires in 30 minutes.</p>
              </div>
              <p className="my-auto mt-12">
                <span className="my-auto mt-12 text-xs text-gray-300">
                  Didn't receive an email? Check your spam folder!
                </span>
              </p>
            </>
          ) : (
            <>
              <p className="tracking-widest pl-[2px]">
                Log in with your email:
              </p>
              <InputField
                placeholder="your@email.com"
                type="email"
                onChange={handleEmailChange}
                autoComplete="on"
                name="email"
              />
              <button
                className={clsx(
                  "rounded-md p-2 font-semibold text-[15px] tracking-widest",
                  "bg-[linear-gradient(294deg,_#EF8241_25.98%,_#FFA02E_69.8%)]",
                  "hover:drop-shadow-[0px_0px_5px_#F90]"
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isValidEmail) {
                    setContinueClicked(true);
                    publicApi.loginWithEmail(email);
                  } else {
                    setShowEmailInvalid(true);
                  }
                }}
                style={{
                  filter: "drop-shadow(0px 3px 2px rgba(0, 0,0, 0.25))",
                }}
              >
                Continue
              </button>
              {showEmailInvalid && (
                <div className="flex flex-col w-80 gap-y-4">
                  <div className="mx-auto text-sm text-error-500">
                    please provide a valid email
                  </div>
                </div>
              )}
              <div className="flex flex-row items-center w-full gap-x-2">
                <div className="h-[1px] w-full bg-gray-200" />
                <div className="text-sm tracking-widest text-gray-200">OR</div>
                <div className="h-[1px] w-full bg-gray-200" />
              </div>

              <div className="flex flex-col">
                <div ref={divRef} className="w-[300px] h-[40px]" />
                <div className="mx-auto text-sm text-error-500 w-[300px] mt-1">
                  {error}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
