import { useCallback, useEffect, useRef, useState } from "react";

export const useSticky = () => {
  const ref = useRef(null);
  const [hasReachedRef, setHasReachedRef] = useState(false);

  const fixateBar = useCallback(() => {
    const bottom = Math.round(ref?.current?.getBoundingClientRect()?.bottom);
    if (isNaN(bottom) || bottom === 0) return;
    setHasReachedRef(bottom <= 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", fixateBar);
    return () => {
      window.removeEventListener("scroll", fixateBar);
    };
  }, [fixateBar]);

  return [hasReachedRef, ref];
};
