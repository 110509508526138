import { useCallback, useEffect } from "react";

// This hook will execute the provided callback function when the "Escape" key is pressed
export const useEscape = (callback: () => void) => {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Escape") {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
};

export const useEnter = (callback: () => void) => {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        callback();
      }
    },
    [callback]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
};
