import axios from "../../apiFc24";
import { MetaRating } from "./get-meta-ratings";

type ResponseType = {
  manager: {
    country: number;
    league: number;
  };
  squadName: string;
  currentFormation: string;
  currentChemistry: number;
  rating: number;
  players: Array<{
    resourceId: number;
    untradeable: boolean;
    cardName: string;
    clubId: number;
    countryId: number;
    leagueId: number;
    rating: number;
    preferredPosition: string;
    possiblePositions: string[];
    price: number;
    metaRatings: MetaRating[];
    metalId: number;
    versionId: number;
    attributes: number[];
    hasDynamicImage: boolean;
    playerUrl: string;
  }>;
};

export const importSquad = async (): Promise<ResponseType> => {
  const res = await axios.get<Promise<ResponseType>>("/user-squads/active");
  return res.data;
};
