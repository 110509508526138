import clsx from "clsx";
import { useEffect, useState } from "react";

import { crossWithDynamicColor as crossIcon } from "../../../../icons";
import { UserNotification } from "../../../../redux/user/userSlice";

type Props = {
  notification: UserNotification;
  onHandleClose: (id: string) => void;
};

export const UserNotifcation = ({
  notification: { context, message, title, id },
  onHandleClose,
}: Props) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    let automaticCloseTimeoutId: NodeJS.Timeout;
    if (fadeOut) {
      setFadeIn(false);
      timeoutId = setTimeout(() => onHandleClose(id), 400); // Ensure this matches your CSS transition duration
    } else {
      setFadeIn(true);
    }
    automaticCloseTimeoutId = setTimeout(() => setFadeOut(true), 4600);

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(automaticCloseTimeoutId);
    }; // Cleanup timeout
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fadeOut, id]);

  const onClose = () => {
    setFadeOut(true);
  };

  return (
    <article
      className={clsx(
        "flex flex-col ml-auto p-2 bg-gray-100-80 w-full desktop:w-[360px] rounded-lg gap-y-2 relative transition-opacity duration-400 backdrop-blur-sm shadow-alert",
        fadeIn ? "opacity-100" : "opacity-0"
      )}
    >
      <section className="flex flex-row items-center gap-x-2">
        <div className="p-1 bg-black rounded-lg w-11 h-11">
          <img src={process.env.PUBLIC_URL + "/logo.png"} alt={"easysbc"} />
        </div>
        <div className="flex flex-col">
          <p className="text-xs text-light-secondary">{context}</p>
          <p className="text-xl font-semibold">{title}</p>
        </div>
      </section>
      <section className="px-3 py-2 rounded-lg bg-gray-800-50 shadow-inner-box">
        <p className="text-white whitespace-pre-line">{message}</p>
      </section>
      <button
        className="absolute top-0 right-0 p-3 text-gray-600"
        onClick={onClose}
      >
        <div className="w-3 h-3">{crossIcon}</div>
      </button>
    </article>
  );
};
