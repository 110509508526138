import axios from "../../privateApi";

type Props = {
  notificationId: string;
};

export const deleteNotification = async ({ notificationId }: Props) => {
  const res = await axios.delete(`/notifications/${notificationId}`);
  return res.data;
};
