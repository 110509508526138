import axios from "axios";

import { setupInterceptorsTo } from "./interceptors";

const instance = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL_FC24,
  })
);

export default instance;

export { getPlayers } from "./publicRequests/players/getPlayers";
export { getArchetypes } from "./publicRequests/players/getArchetypes";
export { getPlayStyles } from "./publicRequests/players/getPlayStyles";
export { playerSearchWithMetaRatings } from "./privateRequests/squad-builder/player-search-with-metaRatings";
export { importSquad } from "./privateRequests/squad-builder/import-squad";
export { suggestSquads } from "./privateRequests/squad-builder/build-squad";
export { getFormationArchetypes } from "./privateRequests/squad-builder/get-formation-archetypes";
export { updatePlayerArchetype } from "./privateRequests/squad-builder/update-player-archetype";
export { recommendPlayers } from "./privateRequests/squad-builder/recommend-players";
export { getMetaRatings } from "./privateRequests/squad-builder/get-meta-ratings";
export { getPlayersMetaRatings } from "./privateRequests/squad-builder/get-players-meta-ratings";
export { importClubFromEA } from "./privateRequests/squad-builder/import-club-from-ea";
export { getManagerData } from "./privateRequests/squad-builder/getManagerData";
export { getVersionAssets } from "./privateRequests/players/get-version-assets";
