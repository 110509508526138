export const closingCross = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 2.73085L9.26927 1L6.00009 4.26931L2.73076 1L1 2.73077L4.26934 6.00012L1 9.26958L2.73081 11.0005L6.00017 7.73096L9.2692 11L11 9.26919L7.73092 6.00013L11 2.73085Z"
      fill="white"
    />
  </svg>
);

export const success = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM10.1417 14.5958L8 16.6969L13.6758 22.3748L23.921 12.1103L21.8109 10L13.6772 18.1314L10.1417 14.5958Z"
      fill="white"
    />
  </svg>
);

export const warning = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM18.1538 7.38462L17.6154 18.5012C17.6154 18.5012 17.6154 19.0571 16 19.0571C14.3846 19.0571 14.3846 18.5012 14.3846 18.5012L13.8462 7.38462H18.1538ZM17.6154 24.0596V21.8362C17.6154 21.8362 17.6154 21.2804 16 21.2804C14.3846 21.2804 14.3846 21.8362 14.3846 21.8362V24.0596C14.3846 24.0596 14.3846 24.6154 16 24.6154C17.6154 24.6154 17.6154 24.0596 17.6154 24.0596Z"
      fill="white"
    />
  </svg>
);

export const error = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM22.4616 11.7753L20.2249 9.53846L16.0001 13.7634L11.7751 9.53846L9.53846 11.7751L13.7634 16.0001L9.53846 20.2253L11.7752 22.4621L16.0002 18.2369L20.2248 22.4615L22.4615 20.2248L18.2369 16.0002L22.4616 11.7753Z"
      fill="white"
    />
  </svg>
);

export const info = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM14.3846 24.6154V13.4988C14.3846 13.4988 14.3846 12.9429 16 12.9429C17.6154 12.9429 17.6154 13.4988 17.6154 13.4988V24.6154C17.6154 24.6154 17.6154 25.1538 16 25.1538C14.3846 25.1538 14.3846 24.6154 14.3846 24.6154ZM14.3846 7.94045V10.1638C14.3846 10.1638 14.3846 10.7196 16 10.7196C17.6154 10.7196 17.6154 10.1638 17.6154 10.1638V7.94045C17.6154 7.94045 17.6154 7.38462 16 7.38462C14.3846 7.38462 14.3846 7.94045 14.3846 7.94045Z"
      fill="white"
    />
  </svg>
);

export const smallInfo = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2010_12280)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 6C11.25 8.8995 8.8995 11.25 6 11.25C3.10051 11.25 0.75 8.8995 0.75 6C0.75 3.10051 3.10051 0.75 6 0.75C8.8995 0.75 11.25 3.10051 11.25 6ZM5.39423 9.23077V5.06203C5.39423 5.06203 5.39423 4.8536 6 4.8536C6.60577 4.8536 6.60577 5.06203 6.60577 5.06203V9.23077C6.60577 9.23077 6.60577 9.43269 6 9.43269C5.39423 9.43269 5.39423 9.23077 5.39423 9.23077ZM5.39423 2.97767V3.81141C5.39423 3.81141 5.39423 4.01985 6 4.01985C6.60577 4.01985 6.60577 3.81141 6.60577 3.81141V2.97767C6.60577 2.97767 6.60577 2.76923 6 2.76923C5.39423 2.76923 5.39423 2.97767 5.39423 2.97767Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2010_12280">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const playstation = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.74693 15.2411V17.7908C9.74693 17.7908 5.54377 19.3237 5.14817 19.472C4.75257 19.6204 4.45588 19.8182 4.50534 20.016C4.5893 20.3518 5.17741 20.4612 5.93936 20.461C6.70131 20.4609 7.00145 20.3872 7.67007 20.1643C8.4118 19.9171 9.74693 19.4001 9.74693 19.4001V21.6973C9.74693 21.6973 8.03515 21.9963 6.92834 21.9939C5.5246 21.991 4.72641 21.8533 3.36801 21.4995C2.85966 21.367 2.08233 21.1039 2.08233 21.1039C2.08233 21.1039 0.5 20.6105 0.5 19.5709C0.5 18.6809 1.73615 18.1234 3.46687 17.4941C6.18653 16.5051 9.74693 15.2411 9.74693 15.2411Z"
      fill="#0097D8"
    />
    <path
      d="M15.8291 19.8676V17.1974C15.8291 17.1974 18.4005 16.3569 20.774 16.3568C21.9789 16.3567 22.6649 16.4362 23.8399 16.7029C24.5073 16.8544 25.5211 17.1974 25.5211 17.1974C25.5211 17.1974 27.5486 17.8402 27.4991 18.8292C27.4497 19.8182 25.5211 20.5599 25.5211 20.5599L15.8291 24.0708V21.4995L22.6531 19.0765C22.6531 19.0765 23.5926 18.7303 23.5926 18.4336C23.5926 18.1369 22.9498 17.9391 22.1586 17.9391C21.3674 17.9391 20.774 18.1369 20.774 18.1369L15.8291 19.8676Z"
      fill="#0097D8"
    />
    <path
      d="M14.9885 24.3675L10.5875 22.9335V3.5C18.1038 5.42851 21.6641 6.1208 21.6641 11.4613C21.6641 14.181 20.5762 15.4426 18.895 15.615C18.0989 15.6967 17.5059 15.4433 16.7192 15.1205V8.19766C16.7192 7.50537 16.2247 6.96143 15.6808 6.91198C15.2357 6.87153 14.9885 7.40648 14.9885 7.90097V24.3675Z"
      fill="#0097D8"
    />
  </svg>
);

export const xbox = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.91606 4.77199C9.00256 4.56336 11.7446 3.5 14.182 3.5C17.0626 3.5 19.2594 4.89863 19.5479 5.091C19.5567 5.09686 19.5507 5.10955 19.5402 5.10845C19.308 5.08396 19.1276 5.08449 18.8628 5.12302C17.6573 5.29835 15.8151 6.01664 15.4997 6.18132C15.1843 6.346 14.3549 6.8987 14.0751 6.8987C13.5867 6.8987 13.0881 6.37971 12.0756 5.95233C10.5898 5.32515 8.78887 4.90428 8.91606 4.77199Z"
      fill="#76BA1F"
    />
    <path
      d="M5.78705 20.2186C5.89527 20.3091 5.65239 19.6744 5.68021 19.3689C6.02618 15.5683 11.1038 9.63083 11.5923 9.08643C11.5923 9.08643 8.43272 6.04726 7.37954 6.01338C6.97252 6.00029 6.31619 6.60357 5.64968 7.48376C3.82187 10.1653 3.53067 11.4547 3.50771 13.6095C3.37543 16.4689 4.97809 19.5419 5.78705 20.2186Z"
      fill="#76BA1F"
    />
    <path
      d="M13.9938 11.381C14.8994 11.9661 15.6727 12.6072 17.2805 14.22C18.8882 15.8329 21.6764 18.7686 21.7883 20.1524C21.8718 21.1853 20.7469 21.7787 20.2976 22.1469C19.4529 22.8388 16.736 24.154 13.7953 24.1057C10.9869 24.0596 8.75834 22.7857 7.81715 22.1418C7.23708 21.7449 6.45074 21.1497 6.33653 20.5137C6.15715 19.5148 9.44011 15.4712 10.7884 14.2353C11.7653 13.3399 13.6071 11.6558 13.9938 11.381Z"
      fill="#76BA1F"
    />
    <path
      d="M22.3782 20.2593C22.3782 20.2593 22.3992 19.549 22.3782 19.2468C22.1037 15.2987 17.0668 9.73261 16.573 9.09155C18.3155 7.41113 19.2586 6.65882 20.8417 5.87096C21.6305 6.62905 22.1277 7.06928 22.48 7.58556C23.8997 9.66648 24.4874 10.5818 24.4998 13.6808C24.5153 17.5373 23.5486 18.5447 22.3782 20.2593Z"
      fill="#76BA1F"
    />
  </svg>
);

export const pc = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 22.9825C9.02944 22.9825 5 18.953 5 13.9825C5 12.4964 5.36015 11.0945 5.99792 9.85928C7.43917 6.53484 11.5001 2.85555 13.6498 1.92712C13.9201 1.81038 14.0997 2.09234 13.9313 2.33388C13.3246 3.20428 12.9499 3.92365 12.7727 4.88147C12.7554 4.97499 12.8357 5.05658 12.9301 5.04539C13.281 5.00383 13.638 4.98246 14 4.98246C18.9706 4.98246 23 9.0119 23 13.9825C23 15.4992 22.6248 16.9284 21.9621 18.1821C20.3735 21.2442 18.0949 23.9591 14.4062 26.0412C14.1729 26.1729 13.9323 25.8895 14.0808 25.6666C14.5498 24.9624 14.9537 24.1465 15.1271 23.0785C15.1409 22.9935 15.0683 22.9201 14.9826 22.9294C14.6599 22.9645 14.332 22.9825 14 22.9825ZM14 17.5104C15.9484 17.5104 17.5279 15.9309 17.5279 13.9825C17.5279 12.034 15.9484 10.4545 14 10.4545C12.0516 10.4545 10.4721 12.034 10.4721 13.9825C10.4721 15.9309 12.0516 17.5104 14 17.5104Z"
      fill="#F15A23"
    />
  </svg>
);

export const dropdownArrow = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 6L5.73258 9.73258C5.88027 9.88027 6.11973 9.88027 6.26742 9.73258L10 6"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
