import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { updateShowModal } from "../../../../redux/message/messageSlice";
import { AppDispatch } from "../../../../redux/store";
import { PremiumModal } from "../PremiumModal";

export const ClubImportSbcsModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <PremiumModal
      title="This is a Premium Feature"
      content="To solve SBCs with your own club you need to have an Elite subscription"
      onPositiveButtonClicked={() => {
        navigate("subscription");
        dispatch(updateShowModal({ modalType: "none" }));
      }}
      onCloseClicked={() => {
        dispatch(updateShowModal({ modalType: "none" }));
      }}
      positiveActionButtonLabel="Go to Subscriptions"
      negativeActionButtonLabel="Cancel"
    />
  );
};
