import {
  LeagueCountryClubData,
  LeagueCountryData,
} from "../../../redux/loadedContent/loadedContentSlice";
import axios from "../../apiFc24";

export type ManagerDataResponse = {
  countries: LeagueCountryData[];
  leagues: LeagueCountryData[];
  clubs: LeagueCountryClubData[];
};

export const getManagerData = async () => {
  const res = await axios.get<ManagerDataResponse>(
    "/squad-builder/manager-data"
  );
  return res.data;
};
