import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { APIStatus } from "../../api/APIStatus.type";
import * as privateApi from "../../api/privateApi";
import { SBCRewardType } from "../../api/privateRequests/sbcs/getSbcSets";
import * as publicApi from "../../api/publicApi";
import { Player } from "../../types/Player";
import { IRootState } from "../store";
import { SbcSet, markSbcSolved as updateSbcSolveInSets } from "./sbcSetsSlice";

export type Sbc = {
  name: string;
  imageURL: string;
  formation: string;
  id: number;
  setId: number;
  requirements: string[];
  solvable: boolean;
  reward: SBCRewardType;
  isCompleted: boolean;
  price: number;
  sbcsCount: number;
  message?: string;
  description?: string;
};

export type Solution = {
  cost: number;
  chem: number;
  rating: number;
  formation: string;
  players: Player[];
  solution_message: string;
  untradeablesCount: number;
  ownedPlayersCount: number;
  transferMarketCost: number;
  duplicatesCount: number;
  error?: {
    message?: string;
  };
};

type SbcsState = {
  sbcs: Sbc[];
  sbcSet: SbcSet;
  currentSbc: Sbc;
  currentSbcSet: SbcSet;
  solution: null | Solution;
  sbcsStatus: APIStatus;
  solutionStatus: APIStatus;
  solutionError?: string;
  sbcSetStatus: APIStatus;
};

const initialState: SbcsState = {
  sbcs: [],
  sbcSet: null,
  currentSbc: null,
  currentSbcSet: null,
  sbcsStatus: "idle",
  solutionStatus: "idle",
  sbcSetStatus: "idle",
  solution: null,
};

export const markSbcSolved = createAsyncThunk(
  "mark-sbc-solved",
  async (
    {
      sbcId,
      setId,
      solved,
    }: {
      sbcId: number;
      setId: number;
      solved: boolean;
    },
    { dispatch }
  ) => {
    dispatch(updateSbcSolved(sbcId));
    // Notify sbcSetsSlice that one more/less sbc has been solved
    dispatch(updateSbcSolveInSets({ setId, solved, sbcId }));
    await privateApi.markSbcSolved(sbcId, setId, solved);
    return { sbcId, setId, solved };
  }
);

export const fetchSbcs = createAsyncThunk(
  "fetch-sbcs",
  async ({ setId }: { setId: number }, { getState }) => {
    const state = getState() as IRootState;
    const platform = state.userReducer.user.platform || "Playstation";
    const res = await privateApi.getSbcs(setId, platform);
    return res;
  }
);

export const fetchSbc = createAsyncThunk(
  "fetch-sbc",
  async ({ sbcId, setId }: { sbcId: number; setId: number }, { getState }) => {
    const state = getState() as IRootState;
    const platform = state.userReducer.user.platform || "Playstation";
    return privateApi.getSbc(sbcId, setId, platform);
  }
);

export const fetchSbcSet = createAsyncThunk(
  "fetch-sbc-set",
  async ({ setId }: { setId: number }) => {
    return privateApi.getSbcSet(setId);
  }
);

export const solveSbc = createAsyncThunk(
  "solve-sbc",
  async (sbcId: number, { getState }) => {
    const state = getState() as IRootState;
    const {
      playersFrom,
      useUntradeablesOnly,
      excludeGoldPlayers,
      excludeSilverPlayers,
      excludeBronzePlayers,
      excludeActiveSquad,
      excludeObjectivePlayers,
      excludeSbcPlayers,
      excludeSpecialPlayers,
      includeTransferlist,
      prioritizeDuplicates,
    } = state.sbcFiltersReducer;

    const { includedPlayers, excludedPlayers } = state.sbcFiltersReducer;
    const includedPlayersMapped = includedPlayers.flatMap((p) =>
      p ? p.resourceId : []
    );
    const excludedPlayersMapped = excludedPlayers.flatMap((p) =>
      p ? p.resourceId : []
    );

    const transformedPlayersFrom =
      playersFrom === "club" ? 0 : playersFrom === "both" ? 2 : 4;
    try {
      const res = await publicApi.solveSbc(
        sbcId,
        transformedPlayersFrom,
        useUntradeablesOnly,
        excludeGoldPlayers,
        excludeSilverPlayers,
        excludeBronzePlayers,
        excludeActiveSquad,
        excludeSpecialPlayers,
        excludeObjectivePlayers,
        excludeSbcPlayers,
        includeTransferlist,
        prioritizeDuplicates,
        includedPlayersMapped,
        excludedPlayersMapped
      );
      if (res.players?.length <= 0) {
        throw new Error(res.error?.message || "No solution found");
      }
      return res;
    } catch (error) {
      if (error.response?.status === 404) {
        throw new Error(
          error.response?.data?.error?.message || "No solution found"
        );
      } else
        throw new Error(
          "Hello from the AI 👋🏼 I had some problems with this one. But I'm on it!"
        );
    }
  }
);

const sbcsSlice = createSlice({
  name: "sbcs",
  initialState,
  reducers: {
    updateSbcsSolved(
      state,
      action: PayloadAction<{ setId: number; solved: boolean }>
    ) {
      const { setId, solved } = action.payload;
      state.sbcs.forEach((sbc) => {
        if (sbc.setId === setId) {
          sbc.isCompleted = solved;
        }
      });
    },
    updateSbcSolved(state, action: PayloadAction<number>) {
      const sbcId = action.payload;
      const sbc = state.sbcs.find((sbc) => sbc.id === sbcId);
      if (sbc) {
        sbc.isCompleted = !sbc.isCompleted;
      }
    },
    updateSolution(state, action: PayloadAction<Solution>) {
      state.solution = action.payload;
    },
    updateSolutionError(state, action: PayloadAction<string>) {
      state.solutionError = action.payload;
    },
    clearSbcs(state) {
      state.sbcs = [];
      state.sbcSetStatus = "idle";
      state.sbcsStatus = "idle";
    },
    clearSolution(state) {
      state.solution = null;
      state.solutionError = null;
    },
    setCurrrentSbc(state, action: PayloadAction<Sbc>) {
      state.currentSbc = action.payload;
    },
    setCurrrentSbcSet(state, action: PayloadAction<SbcSet>) {
      state.currentSbcSet = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSbcs.fulfilled, (state, action) => {
        state.sbcs = action.payload;
        state.sbcsStatus = "fulfilled";
      })
      .addCase(fetchSbc.fulfilled, (state, action) => {
        state.sbcs = [action.payload];
        state.sbcsStatus = "fulfilled";
      })
      .addCase(solveSbc.fulfilled, (state, action) => {
        state.solution = action.payload;
        state.solutionStatus = "fulfilled";
        state.solutionError = null;
      })
      .addCase(solveSbc.pending, (state) => {
        state.solutionStatus = "pending";
      })
      .addCase(solveSbc.rejected, (state, action) => {
        state.solutionError =
          action.error?.message ||
          "Hello from the AI 👋🏼 I had some problems with this one. But I'm on it!";
        state.solutionStatus = "rejected";
      })
      .addCase(
        fetchSbcSet.fulfilled,
        (state, action: PayloadAction<SbcSet>) => {
          state.sbcSet = action.payload;
          state.sbcSetStatus = "fulfilled";
        }
      )
      .addCase(fetchSbcSet.pending, (state) => {
        state.sbcSetStatus = "pending";
      })
      .addCase(fetchSbcSet.rejected, (state) => {
        state.sbcSetStatus = "rejected";
      });
  },
});

export const getSbcsSelector = (state: IRootState) => state.sbcsReducer;
export const getSbcSetSelector = (setId: number) => (state: IRootState) => {
  let sbcSet = state.sbcsSetsReducer.sbcsSets.data.find(
    (sbcSet) => sbcSet.id === setId
  );
  return sbcSet || state.sbcsReducer.sbcSet;
};
export const getSbcSelector =
  (sbcId: number, setId: number) => (state: IRootState) => {
    let sbc = state.sbcsReducer.sbcs.find(
      (sbc) => sbc.id === sbcId && sbc.setId === setId
    );
    return sbc;
  };

export const {
  updateSbcSolved,
  clearSbcs,
  updateSolution,
  clearSolution,
  updateSbcsSolved,
  setCurrrentSbc,
  setCurrrentSbcSet,
} = sbcsSlice.actions;
export default sbcsSlice.reducer;
