import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  EXCLUDED_PLAYERS,
  EXCLUDE_ACTIVE_SQUAD,
  EXCLUDE_BRONZE_PLAYERS,
  EXCLUDE_GOLD_PLAYERS,
  EXCLUDE_SILVER_PLAYERS,
  INCLUDED_PLAYERS,
  INCLUDE_DUPLICATES,
  PLAYERS_FROM,
  UNTRADEABLE_ONLY,
} from "../../settings";
import { SearchedPlayer } from "../sbcPlayers/SearchedPlayer.type";
import { PlayersFrom } from "../squadBuilder/squadBuilderSlice";
import { IRootState } from "../store";

type State = {
  playersFrom: PlayersFrom;
  prioritizeDuplicates: boolean;
  includeTransferlist: boolean;
  useUntradeablesOnly: boolean;
  excludeSpecialPlayers: boolean;
  excludeGoldPlayers: boolean;
  excludeSilverPlayers: boolean;
  excludeBronzePlayers: boolean;
  excludeActiveSquad: boolean;
  excludeSbcPlayers: boolean;
  excludeObjectivePlayers: boolean;
  includedPlayers: SearchedPlayer[];
  excludedPlayers: SearchedPlayer[];
};

const initialState: State = {
  playersFrom: "market",
  prioritizeDuplicates: false,
  includeTransferlist: false,
  useUntradeablesOnly: false,
  excludeActiveSquad: false,
  excludeSpecialPlayers: false,
  excludeGoldPlayers: false,
  excludeSilverPlayers: false,
  excludeBronzePlayers: false,
  excludeSbcPlayers: false,
  excludeObjectivePlayers: false,
  includedPlayers: [],
  excludedPlayers: [],
};

const sbcFiltersSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    addIncludedPlayer(state, action: PayloadAction<SearchedPlayer>) {
      const newIncludedPlayers = [...state.includedPlayers];
      newIncludedPlayers.push(action.payload);
      state.includedPlayers = newIncludedPlayers;
      localStorage.setItem(
        INCLUDED_PLAYERS,
        JSON.stringify(newIncludedPlayers)
      );
    },
    removeIncludedPlayer(state, action: PayloadAction<number>) {
      const newIncludedPlayers = [...state.includedPlayers].filter(
        (p) => p.resourceId !== action.payload
      );
      state.includedPlayers = newIncludedPlayers;
      localStorage.setItem(
        INCLUDED_PLAYERS,
        JSON.stringify(newIncludedPlayers)
      );
    },
    addExcludedPlayer(state, action: PayloadAction<SearchedPlayer>) {
      const newExcludedPlayers = [...state.excludedPlayers];
      newExcludedPlayers.push(action.payload);
      state.excludedPlayers = newExcludedPlayers;
      localStorage.setItem(
        EXCLUDED_PLAYERS,
        JSON.stringify(newExcludedPlayers)
      );
    },
    removeExcludedPlayer(state, action: PayloadAction<number>) {
      const newExcludedPlayers = [...state.excludedPlayers].filter(
        (p) => p.resourceId !== action.payload
      );
      state.excludedPlayers = newExcludedPlayers;
      localStorage.setItem(
        EXCLUDED_PLAYERS,
        JSON.stringify(newExcludedPlayers)
      );
    },

    removeAllIncludedPlayers(state) {
      state.includedPlayers = [];
      localStorage.setItem(INCLUDED_PLAYERS, "[]");
    },
    removeAllExcludedPlayers(state) {
      state.excludedPlayers = [];
      localStorage.setItem(EXCLUDED_PLAYERS, "[]");
    },
    updateIncludePlayersFrom(state, action: PayloadAction<PlayersFrom>) {
      state.playersFrom = action.payload;
      localStorage.setItem(PLAYERS_FROM, action.payload);
    },
    updateUseUntradeablesOnly(state, action: PayloadAction<boolean>) {
      state.useUntradeablesOnly = action.payload;
    },
    updateExcludeActiveSquad(state, action: PayloadAction<boolean>) {
      state.excludeActiveSquad = action.payload;
    },
    updatePrioritizeDuplicates(state, action: PayloadAction<boolean>) {
      state.prioritizeDuplicates = action.payload;
    },
    updateIncludeTransferlist(state, action: PayloadAction<boolean>) {
      state.includeTransferlist = action.payload;
    },
    updateExcludeSpecialPlayers(state, action: PayloadAction<boolean>) {
      state.excludeSpecialPlayers = action.payload;
    },
    updateExcludeGoldPlayers(state, action: PayloadAction<boolean>) {
      state.excludeGoldPlayers = action.payload;
    },
    updateExcludeSilverPlayers(state, action: PayloadAction<boolean>) {
      state.excludeSilverPlayers = action.payload;
    },
    updateExcludeBronzePlayers(state, action: PayloadAction<boolean>) {
      state.excludeBronzePlayers = action.payload;
    },
    updateExcludeSbcPlayers(state, action: PayloadAction<boolean>) {
      state.excludeSbcPlayers = action.payload;
    },
    updateExcludeObjectivePlayers(state, action: PayloadAction<boolean>) {
      state.excludeObjectivePlayers = action.payload;
    },
    initSbcParams(state) {
      state.playersFrom = (localStorage.getItem(PLAYERS_FROM) ||
        "market") as PlayersFrom;

      state.includedPlayers =
        JSON.parse(localStorage.getItem(INCLUDED_PLAYERS)) ||
        ([] as SearchedPlayer[]);
      state.excludedPlayers =
        JSON.parse(localStorage.getItem(EXCLUDED_PLAYERS)) ||
        ([] as SearchedPlayer[]);
    },

    clearSbcParams(state) {
      localStorage.setItem(EXCLUDE_GOLD_PLAYERS, "false");
      localStorage.setItem(EXCLUDE_SILVER_PLAYERS, "false");
      localStorage.setItem(EXCLUDE_BRONZE_PLAYERS, "false");
      localStorage.setItem(INCLUDE_DUPLICATES, "false");
      localStorage.setItem(UNTRADEABLE_ONLY, "false");
      localStorage.setItem(PLAYERS_FROM, "market");
      localStorage.setItem(EXCLUDE_ACTIVE_SQUAD, "false");
      localStorage.setItem(INCLUDED_PLAYERS, "[]");
      localStorage.setItem(EXCLUDED_PLAYERS, "[]");

      state.playersFrom = "market";
      state.prioritizeDuplicates = false;
      state.useUntradeablesOnly = false;
      state.excludeActiveSquad = false;
      state.excludeBronzePlayers = false;
      state.excludeSilverPlayers = false;
      state.excludeGoldPlayers = false;
      state.excludeSpecialPlayers = false;
      state.excludeSbcPlayers = false;
      state.excludeSpecialPlayers = false;
      state.excludeObjectivePlayers = false;
      state.includeTransferlist = false;
      state.includedPlayers = [];
      state.excludedPlayers = [];
    },
  },
});
export const filtersActiveSelector = (state: IRootState) =>
  state.sbcFiltersReducer.playersFrom !== "market" ||
  state.sbcFiltersReducer.prioritizeDuplicates ||
  state.sbcFiltersReducer.useUntradeablesOnly ||
  state.sbcFiltersReducer.excludeActiveSquad ||
  state.sbcFiltersReducer.excludeBronzePlayers ||
  state.sbcFiltersReducer.excludeSilverPlayers ||
  state.sbcFiltersReducer.excludeGoldPlayers ||
  state.sbcFiltersReducer.excludeSpecialPlayers ||
  state.sbcFiltersReducer.excludeSbcPlayers ||
  state.sbcFiltersReducer.excludeObjectivePlayers ||
  state.sbcFiltersReducer.includeTransferlist ||
  state.sbcFiltersReducer.includedPlayers.length > 0 ||
  state.sbcFiltersReducer.excludedPlayers.length > 0;

export const sbcFiltersSelector = (state: IRootState) =>
  state.sbcFiltersReducer;
export const {
  updateIncludePlayersFrom,
  updateUseUntradeablesOnly,
  updateExcludeActiveSquad,
  updatePrioritizeDuplicates,
  updateIncludeTransferlist,
  updateExcludeSpecialPlayers,
  updateExcludeGoldPlayers,
  updateExcludeSilverPlayers,
  updateExcludeBronzePlayers,
  updateExcludeObjectivePlayers,
  updateExcludeSbcPlayers,
  removeIncludedPlayer,
  addIncludedPlayer,
  addExcludedPlayer,
  removeExcludedPlayer,
  clearSbcParams,
  initSbcParams,
  removeAllExcludedPlayers,
  removeAllIncludedPlayers,
} = sbcFiltersSlice.actions;
export default sbcFiltersSlice.reducer;
