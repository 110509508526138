import axios from "../publicApi";

export const loginWithEmail = async (
  email: string,
  priceId?: string,
  discountId?: string
) => {
  const betaEnv = process.env.REACT_APP_BETA_ENV === "true";
  await axios.post("/auth/login-with-email", {
    email,
    betaEnv,
    priceId,
    discountId,
  });
};
