import axios from "../../apiFc24";
import { MetaRating } from "./get-meta-ratings";

type GetPlayersMetaRatingsPlayer = {
  resourceId: number;
  archetypeId: string;
  evoPathId: number;
};

export const getPlayersMetaRatings = async (
  players: GetPlayersMetaRatingsPlayer[]
) => {
  const url = "/squad-builder/players/meta-ratings";
  const res = await axios.post<MetaRating[][]>(url, { players });
  return res.data;
};
